<div class="runner-container" >
  <div *ngIf="getQuestionAudioUrl() && isAudioPlayerShowing()" class="dont-print is-not-high-contrast-exception" style="margin-bottom:1.5em">
    <render-audio
      [url]="getQuestionAudioUrl()"
      [mode]="DisplayMode.NORMAL"
      [hasControls]="true"
      [isQuestionAudio]="true"
      [containingItemId]="currentQuestion.id"
      (play) = "emitPlay($event)"
      (end) = "emitEndPlay($event)"
      [isEmbedded]="false"
    ></render-audio>
  </div>
  <div id="ghost" style="visibility: hidden; width: 10em; height:0em"></div>
  <div class="question-container" [class.is-print-mode]="isPrintMode">
    <div class="question-panels" [class.is-print-mode]="isPrintMode">
      <div 
        class="question-panel" 
        [class.is-print-mode]="isPrintMode"
        [ngStyle]="{'font-size.em':questionTextSize.value}"  
        *ngIf="currentQuestion?.content"
      >
        <div 
          *ngFor="let contentElement of currentQuestion.content; let elementIndex = index;" 
          class="content-element"
          [class.is-not-high-contrast-exception]="!isHighContrastException(contentElement)"
          [class.fade-in]="isFadeInEntrance()"
          [ngStyle]="getElementStyle(contentElement, elementIndex)"
          [class.element-width-constrainer]="checkForElementWidthConstraint(contentElement.elementType)"
          [class.is-element-hidden]="!isElementVisible(contentElement)"
        >
          <!-- <div [ngStyle]="getScaleToFitStyle(contentElement)"> -->
          <element-render 
            *ngIf="showElement(contentElement)"
            [contentElement]="contentElement"
            [isLocked]="isInputLocked()"  
            [questionState]="questionState"
            [questionPubSub]="questionPubSub"
            [allowSubtitles]="allowSubtitles"
            [allowTranscripts]="allowTranscripts"
            [allowAudioPlaybackSpeed]="allowAudioPlaybackSpeed"
            [allowVideoPlaybackSpeed]="allowVideoPlaybackSpeed"
            [frameWorkTags]="frameWorkTags"
            [opinionEssayEditable]="opinionEssayEditable"
            [textInputChange]="textInputChange"
            [fromIssueReviewer]="fromIssueReviewer"
            [cancelOverrideResponse]="cancelOverrideResponse"
            [currentQuestionMeta]="currentQuestionMeta || currentQuestion.meta"
          ></element-render>
          <!-- </div> -->
        </div>
      </div>
   </div>
  </div>
  <!-- <button (click)="prevQuestion.emit(questionState)" [disabled]="isFirstQuestion" class="button">Back</button> -->
  <!-- <button (click)="nextQuestion.emit(questionState)" class="button is-success">Continue</button> -->
</div>
