<table class="date-table">
  <tr [class.is-invalid]="!isValid">
    <th><tra slug="lbl_years"></tra></th>
    <th>MM</th>
    <th><tra slug="lbl_days"></tra></th>
  </tr>
  <tr>
    <!-- [disabled]="disabled" -->
    <td> <input [formControl]="year" type="text" class="input is-small is-maxlen-4" maxlength="4"> </td>
    <td> <input [formControl]="month" type="text" class="input is-small is-maxlen-2" maxlength="2"> </td>
    <td> <input [formControl]="day" type="text" class="input is-small is-maxlen-2" maxlength="2"> </td>
  </tr>
</table>