import * as moment from 'moment';
import * as _ from 'lodash';

import { downloadStr } from '../../../core/download-string';

// app services
import { IQuestionConfig } from '../../../ui-testrunner/models/index';
import { ITestDesignPayload, IPanelModuleDef } from '../../../ui-testtaker/view-tt-test-runner/view-tt-test-runner.component';
import { randArrEntry, generateEntries, shuffle } from '../../../ui-testadmin/demo-data.service';
import { sortBy } from '../../../time/sort.pipe';
import { IPanelModulesConfig, IPanelAssemblyConfig, IAssessmentFrameworkDimensionDetail, IQuadrantConstraint, ITestletConstraint, TestletConstraintFunction, ITestletConstraintCommon, IQuadrant, IQuadrantTestlet, TestFormConstructionMethod, IAssessmentPartition, DimensionType, IRoutingRule, IAssessmentFrameworkDetail } from '../models/assessment-framework';
import { TAB, NEWLINE, stripTabs, LangId, possibleLanguages } from '../models/constants'
import { ItemBankCtrl } from './item-bank';
import { ItemFilterCtrl } from './item-filter';
import { ItemSetFrameworkCtrl } from './framework';
import { loadTestFormMetas, storeQuestionById } from './testform-gen';
import { Destroyable } from './destroyable';

export class PanelCtrl implements Destroyable {
  activePanel: {id: string | number, moduleId?:number, questions: IQuestionConfig[]};
  filterToUsed: boolean;

  constructor(
    public frameworkCtrl: ItemSetFrameworkCtrl,
    public itemBankCtrl: ItemBankCtrl,
    public itemFilterCtrl: ItemFilterCtrl,
  ){

  }

  destroy() {

  }
  
  computePerItemsUsedQuad(quadrant: any) {
    const deno = quadrant.questions.length;
    const nume = quadrant.numItemsUsed;
    let perc = 0;
    if (deno) {
      perc = Math.round(100 * 100 * nume / deno) / 100;
    }
    return perc + '%';
  }

  getNumPanels(asmtFmrk:IAssessmentFrameworkDetail){
    if (asmtFmrk.panels){
      return asmtFmrk.panels.length
    }
    return 0
  }

  onToggleEasyStartSettings(asmtFmrk:IAssessmentFrameworkDetail){
    const panelAssembly = asmtFmrk.panelAssembly;
    if (!panelAssembly.isEasyStartDisabled && !panelAssembly.easyStartSettings){
      // panelAssembly.easyStartSettings = null;
      panelAssembly.easyStartSettings = {
        numberOfItems: 3,
        prop: 'diff',
        isInverse: true,
      }
    }
  }

  activatePanelQuestions(panelId:string | number, moduleId?:number) {
    this.activePanel = {
      id: panelId,
      moduleId,
      questions: this.getPanelQuestions(<number>panelId, moduleId),
    };
    this.itemBankCtrl.currentItemListPage = 1;
    this.itemFilterCtrl.updateItemFilter();
    this.frameworkCtrl.scrollToQuestionListing();
  }

  activateAllPanelQuestions() {
    if(this.filterToUsed == true){
      this.activePanel = null;
      this.filterToUsed = false;
      this.itemFilterCtrl.updateItemFilter();
      return;
    }
    this.activePanel = null;
    this.filterToUsed = true;
    const questions = this.getAllMsCatQuestions();
    this.activePanel = {
      id: null,
      questions: questions,
    };
    this.itemBankCtrl.currentItemListPage = 1;
    this.itemFilterCtrl.updateItemFilter();
    this.frameworkCtrl.scrollToQuestionListing();
    return this.activePanel.questions;
  }

  activateAssembledFormItems(assembledFormId:number) {
    this.activePanel = {
      id: assembledFormId,
      questions: [], // not yet implemented
    };
    this.itemBankCtrl.currentItemListPage = 1;
    this.itemFilterCtrl.updateItemFilter();
    this.frameworkCtrl.scrollToQuestionListing();
  }

  activateAllAssembledFormItems() {
    if(this.filterToUsed == true){
      this.activePanel = null;
      this.filterToUsed = false;
      this.itemFilterCtrl.updateItemFilter();
      return;
    }
    this.activePanel = null;
    this.filterToUsed = true;
    const questions = this.getAllTloftQuestions();
    this.activePanel = {
      id: null,
      questions: questions
    };
    this.itemBankCtrl.currentItemListPage = 1;
    this.itemFilterCtrl.updateItemFilter();
    this.frameworkCtrl.scrollToQuestionListing();
    return this.activePanel.questions;
  }

  getAllMsCatQuestions(includeTestlets = false, excludeDisabled:boolean = true){
    let questions: IQuestionConfig[] = [];
    if(this.frameworkCtrl.asmtFmrk.panels){
      this.frameworkCtrl.asmtFmrk.panels.forEach(panel =>{
        if(panel.isDisabled != true || !excludeDisabled){
          questions.push(...this.getPanelQuestions(<number>panel.id));
        }
      })
    }
    if(includeTestlets == true || !excludeDisabled){
      this.frameworkCtrl.asmtFmrk.testlets.forEach(testlet =>{
        if(testlet.isDisabled != true){
          questions.push(...this.frameworkCtrl.testletCtrl.getTestletQuestions(testlet).questions);
        }
      })
    }
    questions = questions.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
    return questions;
  }

  getAllTloftQuestions(){
    let questions: IQuestionConfig[] = [];
    this.frameworkCtrl.asmtFmrk.assembledPanels.forEach(assembledPanel => {
      if(assembledPanel.isExcluded != true){
        questions.push(...this.getAssembledPanelQuestions(<number>assembledPanel.id));
      }
    })
    questions = questions.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
    return questions;
  }


  
  exportQuadrantsTable() {
    const rows = [];
    const header = [
      'id',
      'quadrant_id',
      'quadrant_description',
      'quadrant_num_questions',
      'quadrant_num_testlets',
      'questions_mapped',
      'questions_used',
      'utilization',
      'est_q_exp_min',
      'est_q_exp_max',
      'cross_duplication',
    ];
    rows.push(header);
    this.frameworkCtrl.asmtFmrk.quadrantItems.forEach(quadrant => {
      const row = [
        quadrant.id, // 'quadrant_id',
        quadrant.id, // 'quadrant_id',
        stripTabs(quadrant.description), // 'quadrant_description',
        quadrant.minQRequired, // 'quadrant_num_questions',
        quadrant.numTestlets, // 'quadrant_num_testlets',
        quadrant.questions.length, // 'questions_mapped',
        quadrant.numItemsUsed, // 'questions_used',
        this.computePerItemsUsedQuad(quadrant), // 'utilization',
        quadrant.exposureMin, // 'est_q_exp_min',
        quadrant.exposureMax, // 'est_q_exp_max',
        quadrant.crossLinkedItems.join(','), // 'cross_duplication',
      ];
      rows.push(row);
    });
    const filename = `quad-${this.itemBankCtrl.currentSetName.value}-${moment().format('YYYY-MM-DD[T]HH_mm_ss')}.tsv`;
    downloadStr(rows.map(row => row.join(TAB)).join(NEWLINE), filename);
  }


  createNewPanels = (asmtFmrk:IAssessmentFrameworkDetail) => {
    if (!asmtFmrk.panels){
      asmtFmrk.panels = [];
    }
    let nextPanelId = 1;
    asmtFmrk.panels.forEach(panel => {
      let panelIterationValue = panel.id;
      if (Number.isNaN(+panelIterationValue)){
        panelIterationValue = asmtFmrk.panels.length
      }
      nextPanelId = Math.max(nextPanelId, (+panelIterationValue)+1)
    })
    const newPanelConfigsJson = prompt('Paste panel JSON from Vretta process here');
    const zeroFill = ( number ) => { /// temporary
      number = (''+number).split(`'`).join('')
      let width = 6
      width -= number.length;
      if ( width > 0 ) {
          return new Array( width + (/\./.test( number ) ? 2 : 1) ).join( '0' ) + number;
      }
      return `${number}`; // always return a string
    }  
    if (newPanelConfigsJson){
      const newPanelConfigs = JSON.parse(newPanelConfigsJson);
      newPanelConfigs.forEach(panelConfig => {
        const modules: {moduleId:number, itemLabels:string[], itemIds:number[]}[] = [];
        Object.keys(panelConfig.panel).forEach( moduleId => {
          const panelDetail = panelConfig.panel[moduleId];
          const itemLabels:string[] = panelDetail.items.map(zeroFill);
          const itemIds:number[] = itemLabels.map(label => {
            const question = this.itemBankCtrl.getQuestionByLabel(label)
            if (question){
              return question.id
            }
            return null;
          });
          modules.push({
            moduleId: +moduleId,
            itemLabels,
            itemIds,
          });
        });
        asmtFmrk.panels.push({
          id: nextPanelId,
          dateCreated: new Date().toISOString(),
          modules,
        })
        nextPanelId ++;
      })
    }
  }

  importPanelAssemblyConfig = (asmtFmrk:IAssessmentFrameworkDetail) => {
    const panelAssemblyJson = prompt();
    if (panelAssemblyJson){
      asmtFmrk.panelAssembly = JSON.parse(panelAssemblyJson);
      const panelAssembly = asmtFmrk.panelAssembly;
      // summarize the difficultyy ranges
      let maxDifficultyLevel = 0;
      panelAssembly.allModules.forEach(module => {
        if (module.difficultyLevel){
          maxDifficultyLevel = Math.max(maxDifficultyLevel, module.difficultyLevel)
        }
      });
      panelAssembly.maxDifficultyLevel = maxDifficultyLevel;
    }
  }

  renderDifficultyLevel = (asmtFmrk:IAssessmentFrameworkDetail, difficultyLevel:number) => {
    if (asmtFmrk.panelAssembly.maxDifficultyLevel === 2){
      switch(+difficultyLevel){
        case 0: return 'Low Difficulty';
        case 1: return 'Medium Difficulty';
        case 2: return 'High Difficulty';
      }
    }
    return 'Difficulty '+(difficultyLevel+1)
  }

  getPanelQuestions(panelId: number, moduleId?:number){
    const questions: IQuestionConfig[] = [];
    let questionIdList = [];
    this.frameworkCtrl.asmtFmrk.panels.forEach(panelConfig => {
      if (panelConfig.id === panelId) {
        // console.log('getPanelQuestions', questions, this.asmtFmrk.panels)
        panelConfig.modules.forEach(module => {
          if (!moduleId || moduleId === module.moduleId){
            questionIdList = questionIdList.concat(module.__cached_itemIds);
          }
        })
      }
    });
    this.itemBankCtrl.getItems().forEach(question => {
      if (questionIdList.indexOf(question.id) !== -1) {
        questions.push(question);
      }
    });
    // console.log('getPanelQuestions', questions, this.asmtFmrk.panels)
    return questions;
  }

  getAssembledPanelQuestions(assembledPanelId: number){
    const questions: IQuestionConfig[] = [];
    this.frameworkCtrl.asmtFmrk.assembledPanels.forEach(assembledConfig => {
      if (assembledConfig.id === assembledPanelId) {
        assembledConfig.testletIds.forEach(testLetId => {
          this.frameworkCtrl.asmtFmrk.testlets.forEach(testLetInFmrk =>{
            if(testLetId == testLetInFmrk.id && testLetInFmrk.isDisabled != true){
              questions.push(...this.frameworkCtrl.testletCtrl.getTestletQuestions(testLetInFmrk).questions);
            }
          })
        })
      }
    });
    return questions;
  }

  replacePanelModuleItem(oldLabel){
    if (!oldLabel) { return; }
    const newLabel = prompt('New question:');
    if (!newLabel) { return; }
    const replacementQuestion = this.itemBankCtrl.getQuestionByLabel(newLabel);
    if (!replacementQuestion){
      alert('This question does not exist in this item bank');
      return;
    }
    let isDuplicate = false;
    console.log('replacePanelModuleItem', oldLabel, this.activePanel.questions);
    this.activePanel.questions.forEach(question => {
      if (question.label === newLabel) {
        isDuplicate = true;
      }
    });
    if (isDuplicate) {
      alert('This question is already used in this testlet');
      return;
    }
    this.frameworkCtrl.asmtFmrk.panels.forEach(panel => {
      if (this.activePanel.id === panel.id){
        panel.modules.forEach(module => {
          if (this.activePanel.moduleId === module.moduleId){
            for (let i=0; i<module.itemLabels.length; i++){
              if (module.itemLabels[i] === oldLabel){
                module.itemLabels[i] = newLabel;
              }
            }
          }
        })
      }
    })
    this.activatePanelQuestions(this.activePanel.id, this.activePanel.moduleId);
  }

  refreshItemCount(){
    const testletToItemCountMap = new Map<number, {fieldTestCount: number, operationalCount: number}>();
    this.frameworkCtrl.asmtFmrk.testlets.forEach((testlet) =>{
      const questionCount = {fieldTestCount: 0, operationalCount: 0};
      testletToItemCountMap.set(+testlet.id, questionCount);
      const { questions }= this.frameworkCtrl.testletCtrl.getTestletQuestions(testlet);
      questions?.forEach(q =>{
        if(!q.isReadingSelectionPage){
          const isFieldTest = q.meta?.["FT"];
          if(isFieldTest){
            questionCount.fieldTestCount++;
          } else {
            questionCount.operationalCount++;
          }
        }
      })
    })
    this.frameworkCtrl.asmtFmrk.assembledPanels.forEach(panel =>{
      // ensure the values exist
      panel.numFieldTestItems = 0;
      panel.numOperationalItems = 0;
      panel.testletIds.forEach((id) =>{
        const {fieldTestCount, operationalCount} = testletToItemCountMap.get(+id) ?? {fieldTestCount: 0, operationalCount: 0};
        panel.numFieldTestItems += fieldTestCount;
        panel.numOperationalItems += operationalCount;
      })
    });
  }

}


export interface IMsCatGeneratorConfig {
  targetPanel?:IPanelModulesConfig,
  getQuestionByLabel:(label:string) => IQuestionConfig,
  getQuestionById:(id:number) => IQuestionConfig,
  getVisibleReadingSelections: (q :IQuestionConfig) => string[],
  langCode:string,
  isEnglish: boolean,
  asmtFmrk: IAssessmentFrameworkDetail,
  panelId?:string, // determine from API when possible
  moduleQuestionOrders?: {[panelId:string]:Array<string | number>}, // determine from API when possible
}

export const generateMsCatTestForm = (config:IMsCatGeneratorConfig) => {
  
  const {
    getQuestionByLabel,
    getQuestionById,
    getVisibleReadingSelections,
    langCode,
    isEnglish,
    asmtFmrk
  } = config;

  const panelAssembly = asmtFmrk.panelAssembly;
  const panelsWithQuestions = asmtFmrk.panels;

  const testForm: ITestDesignPayload = { sections: [], };
  const questionSrcDb = new Map();
  
  const getQuestionIdByLabelOrSavedID = (label, storedID) => {
    const question = getQuestionByLabel(label) ?? getQuestionById(storedID);
    label = question.label;
    if (!question){
      console.error('question is undefined', label);
      return null;
    }
    if (question?.id){
      return question.id
    }
  }

  const storeQById = (id: number) => {
    return storeQuestionById(questionSrcDb, asmtFmrk, id, getQuestionById, getVisibleReadingSelections, isEnglish)
  };

  let panel = config.targetPanel;
  if (!panel){
    panel = randArrEntry(panelsWithQuestions);
  }

  testForm.isPanelRoutingByNumCorrect = panelAssembly.allowNumCorrect;
  testForm.panelRouting = panelAssembly.routingRules;

  const stageItemCountMap = new Map();
  let numStages = 0;
  panelAssembly.allModules.forEach(module => {
    if (!stageItemCountMap.has(module.stageNumber)){
      stageItemCountMap.set(module.stageNumber, module.item_count)
      numStages ++;
    }
  });
  testForm.sections = [];
  stageItemCountMap.forEach( (itemCount, sectionId) => {
    testForm.sections.push({
      sectionId,
      questions: generateEntries(itemCount, () => 0),
      hasCalculator: true,
      hasFormulas: true,
    });
  });
  testForm.sections = sortBy(testForm.sections, 'sectionId');

  testForm.panelModules = [];
  let breakingError = false;
  panel.modules.forEach(module => {
    let preamble: number; // id
    let preambleList: number[] = [];
    let postambleList: number[] = [];

    const moduleInfoIndex = panelAssembly.allModules.findIndex( (m) => +m.id === module.moduleId );
    let moduleInfo;
    if(moduleInfoIndex !== -1) {
      moduleInfo = panelAssembly.allModules[moduleInfoIndex];
      if (moduleInfo.preambleQuestionLabel) {
        const preambleQuestion = getQuestionByLabel(moduleInfo.preambleQuestionLabel);
        if (preambleQuestion) {
          preamble = preambleQuestion.id;
          storeQById(preamble);
        }
      }
      
      if(moduleInfo.preambleLabelList) {
        for( const label of moduleInfo.preambleLabelList) {
          const preambleQuestion = getQuestionByLabel(label);
          if (preambleQuestion) {
            preambleList.push(preambleQuestion.id);
            storeQById(preambleQuestion.id);
          }
        }
      }
  
      if(moduleInfo.postambleLabelList) {
        for(const label of moduleInfo.postambleLabelList) {
          const postambleQuestion = getQuestionByLabel(label);
          if (postambleQuestion) {
            postambleList.push(postambleQuestion.id);
            storeQById(postambleQuestion.id);
          }        
        }
      }
    }


    const questions = [];
    const sectionQuestions = module.itemLabels.map((label, idx)=> getQuestionIdByLabelOrSavedID(label, module?.__cached_itemIds[idx]));
    sectionQuestions.forEach((qid, idx) => {
      const question = storeQById(qid);
      if (question) {
        questions.push(qid);
      } 
      else {
        breakingError = true
        console.error(qid + ' has been removed from the associated item banks since this test form was created');
        throw new Error("INCLUDING MISSING QUESTIONS")
      }
    });


    let orderedBuckets = [];
    if (module.posOverrides){
      const orderedBucketsRefs = new Map();
      const isItemPlacedRef = new Map();
      Object.keys(module.posOverrides).forEach(itemId => {
        const position = module.posOverrides[+itemId];
        if (position){
          let bucket = orderedBucketsRefs.get(position);
          if (!bucket){
            bucket = {position, itemIds:[]};
            orderedBuckets.push(bucket);
            orderedBucketsRefs.set(position, bucket);
          }
          bucket.itemIds.push(+itemId)
          isItemPlacedRef.set(+itemId, true);
        }
      });
      orderedBuckets = _.sortBy(orderedBuckets, 'position');
      const leftOverBucket = {isLeftOver: true, itemIds:[]};
      orderedBuckets.push(leftOverBucket)
      questions.forEach(itemId => {
        if (!isItemPlacedRef.get(+itemId)){
          leftOverBucket.itemIds.push(+itemId)
        }
      })
    }
    
    const section: IPanelModuleDef = {
      moduleId: +module.moduleId,
      preamble,
      preambleList,
      postambleList,
      sidebarThumbnailEn: moduleInfo.sidebarThumbnailEn,
      sidebarThumbnailFr: moduleInfo.sidebarThumbnailFr,
      orderedBuckets,
      routingExclusions: module.routingExclusions,
      hasCalculator: true,
      hasFormulas: true,
      questions,
    };

    testForm.panelModules.push(section);
    if (testForm.panelModules.length === 1){
      testForm.sections[0] = section;
      if (panelAssembly.easyStartSettings){ section.questions = applyEasyStart(section.questions, panelAssembly, getQuestionById); }
    }
  });
  if(breakingError){
    alert('Breaking error, see logs');
    throw new Error("ERROR_GENERATING_MSCAT_FORM");
  }
  loadTestFormMetas(testForm, asmtFmrk);
  
  return {
    currentTestDesign: testForm,
    questionSrcDb,
    questionStates: {},
    testLang: langCode,
  };

}

const applyEasyStart = (questionIds:number[], panelAssembly:IPanelAssemblyConfig, getQuestionById:(id:number) => IQuestionConfig) => {
  if (panelAssembly.easyStartSettings){
    const num = panelAssembly.easyStartSettings.numberOfItems;
    const metaProp = panelAssembly.easyStartSettings.prop;
    const isInverted = panelAssembly.easyStartSettings.isInverse;
    const questionConfigs = questionIds.map( id =>{
      return {
        id,
        config: getQuestionById(id)
      }
    });
    const pprop = (q:{id:number, config:IQuestionConfig}) => {
      if (q.config && q.config.meta){
        // console.log('---', q.id, +q.config.meta[metaProp])
        return +q.config.meta[metaProp]
      }
      return 0;
    }
    questionConfigs.sort( (q1, q2) => ((pprop(q1) > pprop(q2)) === isInverted) ? -1 : 1 );
    // questionConfigs.sort((q1, q2) => ((q1.config.meta.diff > q2.config.meta.diff) === true) ? -1 : 1 )
    // questionConfigs.sort((q1, q2) => ((q1.config.meta.diff > q2.config.meta.diff) === isInverted) ? -1 : 1 )
    // console.log('questionConfigsSorted', metaProp, questionConfigs )
    // console.log('questionConfigsSorted', questionConfigs.map(q => pprop(q)) )
    let remainingQuestionIds = questionIds.concat([]);
    let sortedQuestionIds = [];
    for (let i=0; i<num && i<questionConfigs.length; i++){
      const questionId = questionConfigs[i].id;
      sortedQuestionIds.push(questionId);
      const index = remainingQuestionIds.indexOf(questionId);
      remainingQuestionIds.splice(index, 1);
    }
    if (panelAssembly.allowShuffle){
      sortedQuestionIds = shuffle(sortedQuestionIds);
      remainingQuestionIds = shuffle(remainingQuestionIds);
    }
    sortedQuestionIds = sortedQuestionIds.concat(remainingQuestionIds);
    console.log('sortedQuestionIds', sortedQuestionIds)
    return sortedQuestionIds;
  }
  else if (panelAssembly.allowShuffle){
    return shuffle(questionIds);
  }
  return questionIds;
}
