import moment from 'moment';
import DeepDiff from 'deep-diff';
import { ItemBankCtrl } from "../../item-set-editor/controllers/item-bank";
import { IContentElement, IQuestionConfig } from 'src/app/ui-testrunner/models';

interface IContext {
    itemBankCtrl: ItemBankCtrl, // todo: highly stateful
}

type IQuestionConfigJSON = string;

interface IData {
    [test_design_id:number]: {
        [qId:number]: IQuestionConfigJSON
    }
}

export interface IItemContentDiff {
  questionId: number;
  questionLabel: string;
  description: string;
  content: {
      type: string;
      label: string;
      data: any;
  }[];
  hasDiff: boolean;
  previousContent: string;
  currentContent: string;
  style: any,
  lang: 'en' | 'fr'
}

export interface IItemSignOff {
  id: number,
  test_question_id: number,
  tdso_id: number, 
  is_approved: number
}

export interface ITestDesignSignOff {
  id: number,
  test_design_id: number,
  compared_to_td_id: number,
  audit_config: string,
  is_approved: number
}
// N - Newly added
// D - Deleted
// E - edited
// A - array change

export const itemContentDiff = (data:IData, inculdeCurrent: boolean, ctx:IContext, currentVersionId?: string): IItemContentDiff[] => {
    
    const formatTime = (time) => {
      return moment(time).format('MMMM Do YYYY, h:mm:ss a')
    }

    const parsedData:any[] = [];
    const questions:any = {}

    const globalIgnores = [
      'isShowAdvancedOptions',
      '_isCollapsed',
      '_changeCounter',
      '__changeCounter'
    ]

    const ignoreProps = (obj:any, ignoreParams=true) => {
      delete obj['quadrantFreq'];
      delete obj['versionId']
      delete obj['updated_on']
      delete obj['question_set_id'] // normally wouldnt be needed because it would never change, but we just started tracking this prop
      delete obj['estimatedExposure']
      if (!obj['notes']){
        delete obj['notes']
      }
      if (ignoreParams){
        delete obj['meta'];
        delete obj.langLink['meta'];
      }
      return obj
    }

    const ignoreLangLinkProps = (obj:any) => {
      const temp = JSON.parse(JSON.stringify(obj));
      delete temp['langLink'];
      return temp
    }

    const sanitizeDiff = (diffs:any[]) => {
      if (!diffs){
        return null;
      }
      const diffsSanitized:any[] = [];
      for (let i=0; i<diffs.length; i++){
        let isInclude = true;
        const diff = diffs[i]
        const path = diff?.path;
        if (path && path.length){
          const lastProp = path[path.length-1]
          if (globalIgnores.includes(lastProp)){
            isInclude = false
          }
        }
        if (isInclude){
          diffsSanitized.push(diff)
        }
      }
      if (diffsSanitized.length){
        return diffsSanitized
      }
      return null;
    }

    const langs = ['en', 'fr']
    langs.forEach((lang) => {
      questions[lang] = {};
      Object.keys(data).forEach(test_design_id => {
        const question_records = data[test_design_id]
        
        Object.keys(question_records).forEach(qId => {
          let record = question_records[qId];
          if(qId in questions[lang]){

            const config = ignoreProps(JSON.parse(record.config));
            // compare configs
            
            let current_config: IQuestionConfig =  JSON.parse(questions[lang][qId]["question_configs"][0]["config"] || {});
            current_config = ignoreProps(current_config);
            
            let contentLevelDiffs;
            // Compare content level configs
            if(lang == 'en') {
              const current_content_level_config = ignoreLangLinkProps(current_config);
              const compared_content_level_config = ignoreLangLinkProps(config);
              contentLevelDiffs = DeepDiff(current_content_level_config || {}, compared_content_level_config)
            } else {
              const current_content_level_config: {langLink: IQuestionConfig} = {langLink: current_config.langLink};
              const compared_content_level_config: {langLink: IQuestionConfig} = {langLink: config.langLink};
              contentLevelDiffs = DeepDiff(current_content_level_config || {}, compared_content_level_config)
            }

            // compare configs
            questions[lang][qId]['diff'] = sanitizeDiff(contentLevelDiffs)

            questions[lang][qId]["question_configs"].push({
              tqv_id : record.tqv_id,
                last_updated_on: formatTime(record.date),
                config: JSON.stringify(config),
                name: test_design_id,
            })

          } 
          else {

            const config = ignoreProps(JSON.parse(record.config));

            questions[lang][qId] = {
              test_question_id : qId,
              question_label: record.question_label,
              question_configs : [{
                tqv_id : record.tqv_id,
                last_updated_on: formatTime(record.date),
                config: JSON.stringify(config),
                name: test_design_id,
              }]
            }

            if (inculdeCurrent) {
              const item = ctx.itemBankCtrl.getQuestionById(+qId);

              // Handle item deletion
              if(!item) {
                questions[lang][qId]['diff'] = [{
                  kind: "DI",
                  path: [""]
                }]
                return;
              }

              // deep_clone 
              let current_config: IQuestionConfig =  JSON.parse(JSON.stringify(ctx.itemBankCtrl.getQuestionById(+qId)));
              let updated_on = current_config['updated_on'];
              current_config = ignoreProps(current_config);
              questions[lang][qId]["question_configs"].push({
                config: JSON.stringify(current_config),
                name: "current",
                last_updated_on: formatTime(updated_on)
              });

              const compared_config: IQuestionConfig = JSON.parse(questions[lang][qId]["question_configs"][0]["config"] || {});
              let contentLevelDiffs;
              // Compare content level configs
              if(lang == 'en') {
                const current_content_level_config = ignoreLangLinkProps(current_config);
                const compared_content_level_config = ignoreLangLinkProps(compared_config);
                contentLevelDiffs = DeepDiff(compared_content_level_config, current_content_level_config || {})
              } else {
                const current_content_level_config: {langLink: IQuestionConfig} = {langLink: current_config.langLink};
                const compared_content_level_config: {langLink: IQuestionConfig} = {langLink: compared_config.langLink};
                contentLevelDiffs = DeepDiff(compared_content_level_config, current_content_level_config || {})
              }

              // compare configs
              questions[lang][qId]['diff'] = sanitizeDiff(contentLevelDiffs)
            }
          }
          questions[lang][qId]['lang'] = lang;
        })
      })
    });

    Object.keys(questions).forEach((lang) => {
      parsedData.push(...Object.values(questions[lang]))
    })

    // remodel data for expansion panel
    const reModeledData = parsedData.map((qDiff) => {
      const content = [{type:'JsonView', label: 'Json-Diff', data: qDiff.diff}]
      let description = ''
      let currentContent = '';
      let previousContent = '';
      qDiff.question_configs.forEach(qc => {
        if(qc.name == 'current' || qc.name == currentVersionId){
          currentContent = qc;
        } else if(qc.name != 'current'){
          previousContent = qc;
        }
      })

      const hasDiff = qDiff.diff ? true : false  
      
      return {
        questionId: qDiff.test_question_id,
        questionLabel: qDiff.question_label,
        description,
        content,
        hasDiff,
        previousContent,
        currentContent,
        lang: qDiff.lang,
        style: { 'border': `solid 0.1em ${hasDiff ? "orange" : "green"}`, 'width': '78em'}
      }
    });

    return reModeledData;
}

export const legacyItemContentDiff = (data:IData, inculdeCurrent: boolean, ctx:IContext) => {
    
  const formatTime = (time) => {
    return moment(time).format('MMMM Do YYYY, h:mm:ss a')
  }

  const parsedData:any[] = [];
  const questions:any = {}

  const globalIgnores = [
    'isShowAdvancedOptions',
    '_isCollapsed',
    '_changeCounter',
    '__changeCounter'
  ]

  const ignoreProps = (obj:any, ignoreParams=true) => {
    delete obj['quadrantFreq'];
    delete obj['versionId']
    delete obj['updated_on']
    delete obj['question_set_id'] // normally wouldnt be needed because it would never change, but we just started tracking this prop
    if (!obj['notes']){
      delete obj['notes']
    }
    if (ignoreParams){
      delete obj['meta'];
      delete obj.langLink['meta'];
    }
    return obj
  }

  const sanitizeDiff = (diffs:any[]) => {
    if (!diffs){
      return null;
    }
    const diffsSanitized:any[] = [];
    for (let i=0; i<diffs.length; i++){
      let isInclude = true;
      const diff = diffs[i]
      const path = diff?.path;
      if (path && path.length){
        const lastProp = path[path.length-1]
        if (globalIgnores.includes(lastProp)){
          isInclude = false
        }
      }
      if (isInclude){
        diffsSanitized.push(diff)
      }
    }
    if (diffsSanitized.length){
      return diffsSanitized
    }
    return null;
  }

  Object.keys(data).forEach(test_design_id => {
    const question_records = data[test_design_id]
    Object.keys(question_records).forEach(qId => {
      let record = question_records[qId];

      if(qId in questions){

        const config = ignoreProps(JSON.parse(record.config));
        // compare configs
        const diffs = DeepDiff(JSON.parse(questions[qId]["question_configs"][0]["config"] || {}), config)
        
        questions[qId]['diff'] = sanitizeDiff(diffs)
        
        questions[qId]["question_configs"].push({
          tqv_id : record.tqv_id,
            last_updated_on: formatTime(record.date),
            config: JSON.stringify(config),
            name: test_design_id,
        })

      } 
      else {

        const config = ignoreProps(JSON.parse(record.config));

        questions[qId] = {
          test_question_id : qId,
          question_label: record.question_label,
          question_configs : [{
            tqv_id : record.tqv_id,
            last_updated_on: formatTime(record.date),
            config: JSON.stringify(config),
            name: test_design_id,
          }]
        }

        if (inculdeCurrent) {
          // deep_clone 
          let current_config =  JSON.parse(JSON.stringify(ctx.itemBankCtrl.getQuestionById(+qId)));
          current_config = ignoreProps(current_config);
          questions[qId]["question_configs"].push({
            config: JSON.stringify(current_config),
            name: "current"
          });

          // compare configs
          const diffs = DeepDiff(JSON.parse(questions[qId]["question_configs"][0]["config"] || {}), current_config)
          questions[qId]['diff'] = sanitizeDiff(diffs)
          // questions[qId]['hasDiff'] = diff ? true : false
        }
      }
        
    })
  })

  parsedData.push(...Object.values(questions))

  // remodel data for expansion panel
  const reModeledData = parsedData.map(qDiff => {
    const content = [{type:'JsonView', label: 'Json-Diff', data: qDiff.diff}]
    let description = ''
    qDiff.question_configs.forEach(qc => {
      content.push({type:'TextArea', label: qc.name, data: qc.config});
      if(qc.name != 'current'){
        description += `${qc.name} : ${qc.last_updated_on}  `;
      }
    })

    const hasDiff = (qDiff.diff || content.length < 3) ? true : false  
    
    return {
      title: qDiff.test_question_id,
      subTitle: qDiff.question_label,
      description,
      content,
      hasDiff,
      style: { 'border': `solid 0.1em ${hasDiff ? "orange" : "green"}`}
    }
  });

  return reModeledData;
}