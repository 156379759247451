import { IPanelLink } from "./type";

export const testAuthPanels:IPanelLink[] = [
    {
        caption: 'lbl_designs_and_forms',
        routerLink: '/en/test-auth/auth/designs',
        iconUrl: 'https://eqao.vretta.com/authoring/user_uploads/2635903/authoring/designs_and_forms/1699235012172/designs_and_forms.png',
    },
    {
        caption: 'lbl_item_sets', 
        routerLink: '/en/test-auth/auth/sets',
        iconUrl: '../../../../assets/icons/icons-assessment.png',
    },
    {
        caption: 'ie_comments', // Use lbl_comments_notif outside EQAO
        routerLink: '/en/test-auth/issues',
        iconUrl: 'https://eqao.vretta.com/authoring/user_uploads/2635903/authoring/comments/1699234856422/comments.png',
    },
    {
      caption: 'lbl_graphic_requests',
      routerLink: '/en/test-auth/graphic-requests',
      iconUrl: 'https://eqao.vretta.com/authoring/user_uploads/2635903/authoring/graphic_requests/1699235413571/graphic_requests.png',
    },
    {
        caption: 'Parameters', 
        routerLink: '/en/test-auth/parameters',
        iconUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Ficons%2Ficons-students.png?alt=media&token=1bb26aa0-e615-434e-aa7e-a97ebc52976a',
    },
    {
        caption: 'lbl_assets',
        routerLink: '/en/test-auth/asset-library',
        iconUrl: 'https://eqao.vretta.com/authoring/user_uploads/2635903/authoring/assets/1699234881805/assets.png',
    },
    {
        caption: 'lbl_group_roles',
        routerLink: '/en/test-auth/auth/groups',
        iconUrl: 'https://eqao.vretta.com/authoring/user_uploads/2635903/authoring/group_roles/1699235518814/group_roles.png',
    },
]