import * as _ from 'lodash';
import { ElementType, IContentElement, IQuestionConfig } from "src/app/ui-testrunner/models";
import { ItemSetFrameworkCtrl } from "../../item-set-editor/controllers/framework";
import { ItemBankCtrl } from "../../item-set-editor/controllers/item-bank";
import { EAuditElementType, IAuditCheck, IAuditConfig, ICheckByBlockType, ICheckProp, ICheckPropVal, InformativeCheckType, SubAuditExcludedItems } from '../data/audits'
import { checkPropCondVal, applyPropTransform, getQuestionDeepElements, isNullOrEmptyOrUndefined } from './content-analysis';
import { IContentElementOrder } from 'src/app/ui-testrunner/element-render-order/model';
import { IContentElementInsertion } from 'src/app/ui-testrunner/element-render-insertion/model';
import { IContentElementMoveableDragDrop } from 'src/app/ui-testrunner/element-render-moveable-dnd/model';
import { IContentElementGroup } from 'src/app/ui-testrunner/element-render-grouping/model';
import { IPanelModulesConfig } from '../../item-set-editor/models/assessment-framework';

interface IContext {
    itemBankCtrl: ItemBankCtrl, // todo: highly stateful
    frameworkCtrl: ItemSetFrameworkCtrl, // todo: highly stateful
}

export interface IAuditResult { 
  id: string, 
  caption: string, 
  typeCaption?: string, 
  items?: any[], 
  list?:any[], // might be specific to 'VOICEOVER'
  type?: EAuditElementType,
  excludedItems?: SubAuditExcludedItems[], // specific item types we wan't to exclude from a subaudit but not the entire audit
  informativeOnly?: boolean,
  autoFixContext?: any
};

export interface AuditResultCore {
  audit: IAuditConfig,
  auditSlug: string,
  checkIds: string[],
  auditResults:IAuditResult[],
  auditResultsMap: Map<string, IAuditResult>,
  questions: IQuestionConfig[],
  mscatPanels?: IPanelModulesConfig[] // If were auditing mscat panels collect them in this property
}

export interface AuditResultMeta {
  // auditResultsModel?:AuditResultCore,
  isCustomResults?:boolean,
}

export const processCheck = async (check:IAuditCheck, auditResultsMap:Map<string, IAuditResult>, questions: IQuestionConfig[], ctx:IContext) => {
  const flaggedQuestions = auditResultsMap.get(check.id).items; // to store
  if(check.checkConfig){
    switch (check.checkType){
      case 'PROP_VAL': return processCheckPropVal( check, questions, flaggedQuestions, ctx);
      case 'PROPS_CONFIG': return processCheckPropsConfig( check, questions, flaggedQuestions, ctx);
      case 'PROPS_CONFIG_OR': return processCheckPropsConfig( check, questions, flaggedQuestions, ctx, true);
      case 'BY_BLOCK_TYPE': return processCheckByBlockType( check, questions, flaggedQuestions, ctx);
      case 'ITEM_SETTINGS': return processCheckByItemSettings( check, questions, flaggedQuestions, ctx);
    }
  }
}

export const processCheckByBlockType = async ( check:IAuditCheck, questionsToSearch:IQuestionConfig[], flaggedQuestions:IQuestionConfig[], ctx:IContext) => {
  const checkConfig:ICheckByBlockType = <ICheckByBlockType>check.checkConfig;
  // flaggedQuestions.push(qConfig);
  const {targetBlockType, relativePropPath, transforms, filterMethod, filterValue} = checkConfig;
  for(const qConfig of questionsToSearch){
    const blockElements = getQuestionDeepElements(qConfig);
    let isAnyFailed = false;
    for (let el of blockElements){

      if (targetBlockType == el.elementType){
        if (relativePropPath){
          let propVal = _.get(el, relativePropPath);
          if (transforms){
            for (let transform of transforms){
              propVal = applyPropTransform(propVal, transform.type, transform.config);
            }
          }
          if (filterMethod){
            if (checkPropCondVal(propVal, filterMethod, filterValue)){
              isAnyFailed = true;
            }
          }
          if (propVal){
            if (checkConfig.setHas){
              for (let valToCheck of checkConfig.setHas){
                if (propVal?.has(valToCheck)){
                  isAnyFailed = true;
                }
              }
            }
            if (checkConfig.setMissing){
              for (let valToCheck of checkConfig.setMissing){
                if (!propVal?.has(valToCheck)){
                  isAnyFailed = true;
                }
              }
            }
          }
        }
      }

      // todo: sub-optimal exception, but not refactoring now
      if (checkConfig.dndKeys){
        const checkResults = await checkElDndMissingDupe(el);
        for (const checkKey of checkConfig.dndKeys){
          if (checkResults[checkKey]){
            isAnyFailed = false;
          }
        }
      }

    }
    if (isAnyFailed){
      flaggedQuestions.push(qConfig);
    }
  }
}

const checkElDndMissingDupe = async (el:IContentElement) => {
  const keyIdSet = new Set();
  const checks = {
    hasDuplicateKeyIds: false,
    hasMissingKeyIds: false,
    hasMissingHomeTargetIds: false,
    hasDuplicateHomeTargetIds: false,
  }
  switch (el.elementType) {
    case ElementType.MOVEABLE_DND:
    case ElementType.GROUPING:

      const dndEl = <IContentElementMoveableDragDrop | IContentElementGroup>el;

      const {draggables, targets} = dndEl;

      draggables?.forEach(obj => checkForMissingOrDuplicateKeyIds(obj, keyIdSet, checks));
      targets?.forEach(obj => checkForMissingOrDuplicateKeyIds(obj, keyIdSet, checks));

      // if(checks.hasMissingKeyIds) auditResultsMap.get(CheckId.MISSING_KEY_ID).items.push(qConfig);
      // if(checks.hasDuplicateKeyIds) auditResultsMap.get(CheckId.ITEMS_WITH_DUPLICATE_KEY_ID).items.push(qConfig);

      if(el.elementType === ElementType.MOVEABLE_DND) {
        const homeTargetIds = new Set();
        draggables?.forEach(draggable => {
          if(!draggable.id){
            checks.hasMissingHomeTargetIds = true;
            // auditResultsMap.get(CheckId.MISSING_HOME_TARGET_ID).items.push(qConfig);
          }
          else if(homeTargetIds.has(draggable.id)){
            checks.hasDuplicateHomeTargetIds = true;
            // auditResultsMap.get(CheckId.ITEMS_WITH_DUPLICATE_HOME_TARGET_ID).items.push(qConfig);
          }
          else { 
            homeTargetIds.add(draggable.id) 
          }
        })
      }

      break;

    case ElementType.ORDER:
      const orderEl = <IContentElementOrder>el;
      orderEl.options?.forEach(order => checkForMissingOrDuplicateKeyIds(order, keyIdSet, checks));
      // if(checks.hasMissingKeyIds) auditResultsMap.get(CheckId.MISSING_KEY_ID).items.push(qConfig);
      // if(checks.hasDuplicateKeyIds) auditResultsMap.get(CheckId.ITEMS_WITH_DUPLICATE_KEY_ID).items.push(qConfig);
      break;

    case ElementType.INSERTION:
      const insertEl = <IContentElementInsertion>el;
      const insertTargets = insertEl.textBlocks?.filter(block => block.element.elementType === ElementType.BLANK || block.element.elementType === ElementType.BLANK_DEPRECIATED) ;  
      
      insertEl.draggables?.forEach(draggable => checkForMissingOrDuplicateKeyIds(draggable, keyIdSet, checks));
      insertTargets.forEach(target => checkForMissingOrDuplicateKeyIds(target, keyIdSet, checks))
      // if(checks.hasMissingKeyIds) auditResultsMap.get(CheckId.MISSING_KEY_ID).items.push(qConfig);
      // if(checks.hasDuplicateKeyIds) auditResultsMap.get(CheckId.ITEMS_WITH_DUPLICATE_KEY_ID).items.push(qConfig);                  
      break;
      
    default:
      break;
  }
  return checks;
}

const checkForMissingOrDuplicateKeyIds = (obj, keyIdSet: Set<any>, checks: {hasDuplicateKeyIds: boolean, hasMissingKeyIds:boolean} ) => {
  const key_id = obj.key_id?.trim();
  if(!key_id){
    checks.hasMissingKeyIds = true;
  }
  else if(keyIdSet.has(key_id)){
    checks.hasDuplicateKeyIds = true;
  }
  else { 
    keyIdSet.add(key_id) 
  }
  // console.log(key_id, keyIdSet, keyIdSet.has(key_id), checks)
}

export const processCheckPropVal = async ( check:IAuditCheck, questionsToSearch:IQuestionConfig[], flaggedQuestions:IQuestionConfig[], ctx:IContext) => {
  const checkConfig:ICheckPropVal = <ICheckPropVal>check.checkConfig;
  const {targetProp, filterMethod, filterValue, isScoringConfig} = checkConfig;
  if (targetProp){
    for(const qConfig of questionsToSearch){
      let valContainer;
      if (isScoringConfig){
        valContainer = ctx.itemBankCtrl.getQuestionScoringInfo(qConfig.id)
      }
      else {
        const {meta} = qConfig;
        valContainer = meta;
      }
      const propVal = _.get(valContainer || {}, targetProp);
      if (checkPropCondVal(propVal, filterMethod, filterValue)){
        flaggedQuestions.push(qConfig);
      }
    }
  }
}

/**
 * Function used to check if certain prop configs are met (combination of question metas)
 * @param check 
 * @param questionsToSearch 
 * @param flaggedQuestions 
 * @param ctx 
 * @param singleConditionRequired // if only one of the configs is required for question to be flagged
 */
export const processCheckPropsConfig = async ( check:IAuditCheck, questionsToSearch:IQuestionConfig[], flaggedQuestions:IQuestionConfig[], ctx:IContext, singleConditionRequired: boolean = false) => {
  const checkConfig: ICheckProp[] = <ICheckProp[]>check.checkConfig;

  for (const qConfig of questionsToSearch) {
    const { meta } = qConfig;
    const valContainer = meta;
    let meetsAllConfigs = true;
    let numOfConfigsMet = 0;
    for (const config of checkConfig) {
      const { targetProp, filterMethod, filterValue } = config;
      if (targetProp) {
        const propVal = _.get(valContainer || {}, targetProp);
        if (!checkPropCondVal(propVal, filterMethod, filterValue)) {
          meetsAllConfigs = false;
          if(!singleConditionRequired){ break;}
        } else  {
          numOfConfigsMet++;
        }
      }
    }

    if (meetsAllConfigs || (singleConditionRequired && numOfConfigsMet !== 0)) {
      flaggedQuestions.push(qConfig);
    }
  }
}

function processCheckByItemSettings(check: IAuditCheck, questionsToSearch: IQuestionConfig[], flaggedQuestions: IQuestionConfig[], ctx: IContext) {
  const checkConfig:ICheckPropVal = <ICheckPropVal>check.checkConfig;
  const {targetProp, filterMethod, filterValue, isScoringConfig} = checkConfig;
  if (targetProp){
    for(const qConfig of questionsToSearch){
      const propVal = _.get( qConfig, targetProp);
      if (checkPropCondVal(propVal, filterMethod, filterValue)){
        flaggedQuestions.push(qConfig);
      }
    }
  }
}

/**
 * Function that determines if an audit is included in the issue count
 * @param informativeOnlyCheck the value for informative of the subaudit (takes priority)
 * @param globalInformativeOnlyCheck the parent audits value of informative only (defaults to false)
 * @param ctx context for more complex checks to determine if it is informative only
 * @returns a boolean determining if the audit is informative only
 */
export const proccessInformativeOnly = (informativeOnlyCheck: InformativeCheckType, globalInformativeOnlyCheck: InformativeCheckType = false, ctx: IContext) : boolean =>{
  let activeInformativeCheck = informativeOnlyCheck;
  if(isNullOrEmptyOrUndefined(informativeOnlyCheck)){
      activeInformativeCheck = !!globalInformativeOnlyCheck
  }
  switch(activeInformativeCheck){
      case 'NOT_SAMPLE_TEST':
        return !ctx.frameworkCtrl.asmtFmrk.isSampleTest;
      case 'SAMPLE_TEST':
        return !!ctx.frameworkCtrl.asmtFmrk.isSampleTest;
      default:
        return activeInformativeCheck;
  }
}