import { Component, OnInit, Input, Output,EventEmitter} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LangService } from '../../core/lang.service';
import { isInArr } from '../data/util';
import { ICheckMarkMap, ICheckMarkMapping } from '../data/mappings/eqao-g9';

export interface ISaFormEntry {
  label:string,
  type:SaFormType,
  formControlRef?:FormControl,
  options?:{id:number | string, label:string, tag?:string, lang?:string, greyOut?: boolean}[],
  header?:string,
  sublabel?:string,
  isVertical?:boolean,
  isCustomWidth?:boolean,
  isLocked?:boolean,
  required?:boolean,
  // isDisabled?:boolean,
  valProp?: string,
  checkMarkMap?: ICheckMarkMap,
  nullDbPlaceholder?: string,
  isHidden?: boolean,
  singleSelect?: boolean,
  siblings?: ISaFormEntry[]
  indentation?:boolean,
  greyOut?:boolean,
  space?:boolean,
  isWordWrapping?:boolean,
  pattern?:string, // In SaFormType.TEXT type only, mark as invalid if doesn't match the pattern
}

export interface ISaFormEntryConfig extends Partial<ISaFormEntry> {
}

export const getSubValProp = (valProp: string, tag: string): string => {
  let subValProp = `${valProp}`;
  if (tag) {
    subValProp += `_${tag}`
  }
  return subValProp;
};

export const initFormEntries = (source:any, configs:ISaFormEntryConfig[], checkMarkMapping?: ICheckMarkMapping, lang?:string) => {
  const formEntries:ISaFormEntry[] = [] 
  configs.forEach(entry => {
    const fc = new FormControl();
    if (fc){
      const checkMarkMap = checkMarkMapping ? checkMarkMapping[entry.valProp] : undefined;
      setEntryValue(<ISaFormEntry> entry, source, entry.valProp, {fc, checkMarkMap})
      if (entry.isLocked){
        fc.disable();
      }
    }

    let filteredOptions = entry.options;
    if(filteredOptions != undefined)
      filteredOptions = entry.options.filter(option => {
        if(option.lang === undefined || option.lang === lang){
            return option;
        }
    })

    formEntries.push({
      label         : entry.label,
      type          : entry.type,
      //options       : entry.options,
      options       : filteredOptions,
      required      : entry.required,
      header        : entry.header,
      sublabel      : entry.sublabel,
      isVertical    : entry.isVertical,
      isCustomWidth : entry.isCustomWidth,
      isLocked      : entry.isLocked,
      isHidden      : entry.isHidden,
      valProp       : entry.valProp,
      indentation   : entry.indentation,
      greyOut       : entry.greyOut,
      space         : entry.space, 
      isWordWrapping: entry.isWordWrapping,
      nullDbPlaceholder  : entry.nullDbPlaceholder,
      pattern       : entry.pattern,
      formControlRef: fc,
      checkMarkMap: checkMarkMapping ? checkMarkMapping[entry.valProp] : undefined
    })
    // console.log(entry.valProp, fc ? fc.value : 'N/A', source[entry.valProp])

    if (entry.options){
      const siblings: ISaFormEntry[] = [];
      entry.options.forEach(option => {
        if(option.lang !== undefined && option.lang !== lang){
            return;
        }
        const tag = option.tag;
        const subValProp = getSubValProp(entry.valProp, tag); 
        // console.log('subValProp', subValProp)
        if (tag && isInArr([SaFormType.RADIOC, SaFormType.CHECK], entry.type)){
          const checkMarkMap = checkMarkMapping ? checkMarkMapping[subValProp] : undefined;
          const subfc = new FormControl();
          if (source){
            setEntryValue(<ISaFormEntry> entry, source, subValProp, {fc: subfc, checkMarkMap})
          }
          const optionFormEntry = {
            label         : option.label,
            type          : null,
            valProp       : subValProp,
            singleSelect  : entry.singleSelect,
            siblings,
            formControlRef: subfc,
            checkMarkMap,
            nullDbPlaceholder  : entry.nullDbPlaceholder,
            isHidden: true,
            greyOut      : option.greyOut
          };
          siblings.push(optionFormEntry);
          formEntries.push(optionFormEntry);
        }
      })
    }

  })
  return formEntries;
}

interface IDbSource {
  [key:string]:string
}

export const setEntryValue = (entry:ISaFormEntry, source:IDbSource, prop:string, options?:{fc?:FormControl, checkMarkMap?:ICheckMarkMap}) => {
  options = options || {};
  const dbVal = source[prop];
  let v:boolean | string = dbVal;
  if(options.checkMarkMap) {
    v = options.checkMarkMap[dbVal];
  }
  let fc = options.fc || entry.formControlRef;
  // console.log(' >>>>', prop, fc, v)
  if (fc){
    fc.setValue(v);

    if(entry.greyOut){
      fc.disable({ emitEvent: false });
    } 
  }
}

export const applyDefaultRules = (entry:ISaFormEntry) => {
  const fc: FormControl = entry.formControlRef; 
  // unselect other options if singleSelect 
  if (entry.singleSelect && fc.value === true && entry.siblings) {
    entry.siblings
      .forEach(sibling => {
        if (sibling.formControlRef !== fc) {
          sibling.formControlRef.setValue(false)
        }
      });
  }
  if(entry.valProp == 'AccOther'){
    console.log('')
  }
  if(entry.greyOut){
    entry.formControlRef.disable({ emitEvent: false });
  }  
}

export enum SaFormType {
  TEXT   = 'TEXT',
  SELECT = 'SELECT',
  RADIO  = 'RADIO',
  RADIOC = 'RADIOC',
  CHECK  = 'CHECK',
  DATE   = 'DATE',
  LABEL  = 'LABEL',
  TEXTAREA = 'TEXTAREA'
}

@Component({
  selector: 'sa-widget-form-entry',
  templateUrl: './sa-widget-form-entry.component.html',
  styleUrls: ['./sa-widget-form-entry.component.scss']
})
export class SaWidgetFormEntryComponent implements OnInit {

  @Input() formEntries:ISaFormEntry[];
  @Input() formEntry:ISaFormEntry;
  @Input() disabled:boolean;
  @Output() selected = new EventEmitter();
  constructor(
    private lang: LangService
  ) { }

  @Input() formEntryLabelContainerStyle: object = null;

  SaFormType = SaFormType;

  ngOnInit(): void {
  }

  isLabel(){
    return (this.formEntry.type === SaFormType.LABEL);
  }
  isVertical(){
    return (this.formEntry.isVertical || this.isLabel());
  }
  isHorizontal(){
    return !this.isVertical();
  }
  showSelected($event){
   this.selected.emit($event)
  }
  getFormEntryById(id:string):ISaFormEntry{
    let match;
    if (this.formEntries){
      this.formEntries.forEach(entry => {
        if (entry.valProp === id){
          match = entry;
        }
      })
    }
    return match;
  }

  getVal(){
    return this.formEntry.formControlRef.value;
  }
  setVal(val:boolean | number | string){
    this.formEntry.formControlRef.setValue(val);
  }

  setValById(id:string, val:boolean | number | string){
    const entry = this.getFormEntryById(id);
    if (entry){
      entry.formControlRef.setValue(val);
    }
  }
  getValById(id:string){
    const entry = this.getFormEntryById(id);
    if (entry){
      return entry.formControlRef.value;
    }
  }
  
  getSubValProp(valProp: string, tag: string): string{
    return getSubValProp(valProp, tag);
  }
  
  getSubFormControl(valProp: string, tag: string): FormControl {
    if (valProp && tag){
      const subValProp = getSubValProp(valProp, tag)
      // console.log('subValProp', subValProp)
      return this.getFormEntryById(subValProp).formControlRef;    
    }
    else{
      return this.formEntry.formControlRef
    }
  }
}
