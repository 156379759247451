import { IAssessmentFrameworkDetail, DimensionType } from "./assessment-framework";

export const FRAMEWORK_CONFIG:IAssessmentFrameworkDetail = {
  "caption": "sample",
  "projectId": "sample",
  "notes": "",
  "partitions": [
    {
      "description": "Section 1",
      "id": 1
    }
  ],
  "primaryDimensions": [
  ],
  "secondaryDimensions": [
  ],
  "subcaption": "v2",
  "__id": "JwdVaPLZkRy1wBgKszeV",
  "quadrantIdAI": 0,
  "parititionIdAI": 0,
  "quadrants": [],
  useLangSpecificSectionProps: undefined // brough over from abed rest is the same
}