<div class="page-body">
  <div>
    <header
    [breadcrumbPath]="breadcrumb"
    ></header>
    <div class="page-content is-fullpage" style="background-color: #f5f5f5;">
      <div style="display: flex; gap: 1.5em; flex-direction: column;">
        <div class="flex-row">
          <div class="card flex-column" style="width: 45em;">
            <h2>Parameters</h2>
            <div style="display: flex; justify-content: space-between;">
              <div style="display: flex;flex-direction: column; gap: 1em;">
                <div>
                  Type Slug
                </div>
                <div class="select" style="width: 12em;">
                  <select style="width: 100%;" [(ngModel)]="currentGrade" (ngModelChange)="resetSelection()">
                    <option *ngFor="let grade of getGrades()" [value]="grade">{{grade}}</option>
                  </select>
                </div>
                <div *ngIf="getAssessmentParameterList() && getAssessmentParameterList().length">
                  Assessment Type
                </div>
                <div class="buttons">
                  <button *ngFor="let assessment of getAssessmentParameterList()"
                    class="button is-small"
                    [class.is-info]="isAssessmentParameterToggled(assessment.id)"
                    (click)="toggleAssessmentParameter(assessment.id)"
                  >
                    {{assessment.assessment_slug}}
                  </button>
                </div>
                <div>
                  <button 
                    class="button" 
                    [class.is-info]="showParamCreation" 
                    (click)="showParamCreation=!showParamCreation"
                    [disabled]="isReadOnly()"
                  >
                    Create new assessment parameter
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card flex-column" *ngIf="showParamCreation">
            <h2>Create New Assessment Slug</h2>
            <div style="width: 30em;">
              <div class="flex-column" style="justify-content: space-between; gap: 1em; height: 100%">
                <div class="flex-column" style="gap: 1em;">
                  <div class="flex-column">
                    <b>Type Slug</b>
                    <input
                      type="text"
                      [(ngModel)]="newParamTypeSlug"
                      class="input"
                      [disabled]="isReadOnly()"
                    />
                  </div>
                  <div class="flex-column">
                    <b>Assessment Type Slug</b>
                    <input
                      type="text"
                      [(ngModel)]="newParamAsmtSlug"
                      class="input"
                      [disabled]="isReadOnly()"
                    />
                  </div>
                  <div class="flex-column">
                    <b>Language</b>
                    <div class="select" style="width: 12em;">
                      <select 
                        style="width: 100%;" 
                        [(ngModel)]="newParamLangType"
                        [disabled]="isReadOnly()"
                      >
                        <option *ngFor="let lang of LANGUAGE_TYPES" [value]="lang.id">{{lang.slug}}</option>
                      </select> 
                    </div>
                  </div>
                  <div class="flex-column">
                    <b>Extends</b>
                    <div class="select" style="width: 12em;">
                      <select 
                        style="width: 100%;" 
                        [(ngModel)]="newParamExtends"
                        [disabled]="isReadOnly()"
                      >
                        <option *ngFor="let assessment of assessmentParameters" [value]="assessment.id">{{assessment.assessment_slug}}</option>
                      </select> 
                    </div>
                  </div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                  <button class="button is-success" [disabled]="!newParamAsmtSlug || !newParamLangType || isNewParamSaving" (click)="createNewAsmtParam(newParamAsmtSlug, newParamTypeSlug, newParamExtends, newParamLangType)">Create</button>
                </div>
              </div>
            </div>
          </div>
          <div class="card flex-column" *ngIf="activeAssessmentParameter" style="flex-grow: 2;">
            <h2>Parameter Information</h2>
            <div style="width: 30em;">
              <div class="flex-column" style="justify-content: space-between; gap: 1em; height: 100%">
                <div class="flex-column" style="gap: 1em;">
                  <div class="flex-column">
                    <b>Type Slug</b>
                    <input
                      type="text"
                      [(ngModel)]="activeAssessmentParameter.type_slug"
                      (change)="onParameterInfoChange()"
                      class="input"
                      [disabled]="isReadOnly()"
                    />
                  </div>
                  <div class="flex-column">
                    <b>Assessment Type Slug</b>
                    <input
                      type="text"
                      [(ngModel)]="activeAssessmentParameter.assessment_slug"
                      (change)="onParameterInfoChange()"
                      class="input"
                      [disabled]="isReadOnly()"
                    />
                  </div>
                  <div class="flex-column">
                    <b>Language</b>
                    <div class="select" style="width: 12em;">
                      <select 
                        style="width: 100%;" 
                        [(ngModel)]="activeAssessmentParameter.lang_type" 
                        (change)="onParameterInfoChange()"
                        [disabled]="isReadOnly()"
                      >
                        <option *ngFor="let lang of LANGUAGE_TYPES" [value]="lang.id">{{lang.slug}}</option>
                      </select> 
                    </div>
                  </div>
                  <div class="flex-column">
                    <b>Extends</b>
                    <div class="select" style="width: 12em;">
                      <select 
                        style="width: 100%;" 
                        [(ngModel)]="activeAssessmentParameter.extend_id" 
                        (change)="onParameterInfoChange()"
                        [disabled]="isReadOnly()"
                      >
                        <option *ngFor="let assessment of assessmentParameters" [value]="assessment.id">{{assessment.assessment_slug}}</option>
                      </select> 
                    </div>
                  </div>
                </div>
                <div style="display: flex; gap: 1em;">
                  <button 
                    [disabled]="paramSaveStateMap.get('paramInfo').saving || !paramSaveStateMap.get('paramInfo').changes || isReadOnly()"
                    class="button has-icon"
                    [class.is-success]="!paramSaveStateMap.get('paramInfo').saving" 
                    (click)="saveParameterInfo()"
                  >
                    <span><tra slug="ie_save_changes"></tra></span>
                  </button>
                  <button [disabled]="paramSaveStateMap.get('common').removing || isReadOnly()" class="button has-icon"  (click)="revokeAsmtParam(activeAssessmentParameter.id)">
                    <span><tra slug="ie_remove_item_bank"></tra></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-row">
          <div class="card flex-column" style="flex-grow: 2; justify-content: space-between; width: 200%; gap: 1em;" *ngIf="activeAssessmentParameterId != undefined && activeAssessmentParameter">
            <div class="flex-column" style="gap: 1em;">
              <div class="flex-column">
                <div style="display: flex; justify-content: space-between;">
                  <div>
                    <div *ngIf="getAssessmentParameter(activeAssessmentParameter.extend_id)">
                      Extends: <a (click)="toggleAssessmentParameter(activeAssessmentParameter.extend_id)">
                        <b>
                          {{getAssessmentParameter(activeAssessmentParameter.extend_id).assessment_slug}}
                        </b>
                      </a>
                    </div>
                  </div>
                  <div style="display: flex; gap: 0.5em;">
                    Show as JSON
                    <mat-slide-toggle [(ngModel)]="showJSONView"  (change)="syncParamViews($event)"></mat-slide-toggle>
                  </div>
                </div>
                <div *ngIf="!isBilingual()">
                  <div style="width: 5em;" class="select">
                    <select style="width: 100%;" [ngModel]="currentLang" (ngModelChange)="onCurrentLangChange($event)">
                      <option *ngFor="let lang of getLanguages()" [value]="lang">{{lang}}</option>
                    </select>
                  </div>
                </div>
                <div *ngIf="showJSONView">
                  <textarea
                    type="text"
                    [(ngModel)]="activeAssessmentParameterJSON"
                    class="input"
                    class="textarea"
                    [disabled]="isReadOnly()"
                  ></textarea>
                </div>
                <table *ngIf="!showJSONView" class="table" style=" width: auto;">
                  <tr>
                    <th style="width:4em">Code</th>
                    <th style="width:12em">Parameter</th>
                    <th style="width:12em">Parameter Category</th>
                    <th style="width:8em">Data Type</th>
                    <th style="width: 12em">Configurations</th>
                    <th style="width: 12em">Parameter Type</th>
                    <th style="width:4em">Edit</th>
                    <th style="width:4em">Remove?</th>
                  </tr>
                  <tr *ngFor="let param of activeAssessmentParameter.parameters[currentLang]; let i_param = index;"> 
                    <td [ngStyle]="{'background-color':param.color}">{{param.code}}</td>
                    <td>{{param.name}} <span *ngIf="param.isHidden">(hidden)</span> </td>
                    <td>{{param.category}}</td>
                    <td>{{param.type}}</td>
                    <td>
                      <div *ngIf="param && param.config && param.config.tags">
                        <a *ngIf="!isConfigExpanded(param.code)" (click)="expandConfig(param.code, true)">Show</a>
                        <a *ngIf="isConfigExpanded(param.code)" (click)="expandConfig(param.code, false)">Hide</a>
                        <div class="flex-column" style="gap: 0.4em;" *ngIf="isConfigExpanded(param.code)">
                          <div *ngFor="let tag of param.config.tags" class="tag-input-row">
                            <div class="flex-row" style="gap: 0.25em">
                              <a class="tag-info is-bold" [class.is-error]="!tag.code">{{tag.code || 'Code'}}</a>
                              <a class="tag-info" [class.is-error]="!tag.name">{{tag.name || 'Name/Desc.'}}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      {{getStorageLocationSlug(param.storageLocation)}}
                    </td>
                    <td style="width: 30em;">
                      <button [disabled]="util.isPsychoParam(param) || isReadOnly()" *ngIf="!isParamExpanded(param)" (click)="expandParam(param, true)">Edit</button>
                      <framework-dimension-editor *ngIf="isParamExpanded(param)" [isEnhancedView]="true" [param]="param" (remove)="expandParam(param, false)" (onDimensionChange)="onChanges()"></framework-dimension-editor>
                    </td>
                    <td> <button (click)="removeParameter(activeAssessmentParameter.parameters[currentLang], param)" [disabled]="isReadOnly()"><tra slug="ie_delete_param"></tra></button> </td>
                  </tr>
                </table>
                <div *ngIf="!showJSONView" style="display:flex; justify-content: space-between;">
                  <div>
                    <button 
                      (click)="defineNewParameter(activeAssessmentParameter.parameters[currentLang])" 
                      class="button is-small has-icon" 
                      [style.margin-top.em]="1"
                      [disabled]="isReadOnly()"
                    >
                      <span class="icon"><i class="fa fa-plus" aria-hidden="true"></i></span>
                      <span>New Parameter</span>
                    </button>
                  </div>
                  <div>
                    <button 
                      (click)="isOrdering = !isOrdering" 
                      class="button is-small" 
                      [class.is-info]="isOrdering" 
                      [style.margin-top.em]="1"
                      [disabled]="isReadOnly()"
                    >
                      <span>Change Order</span>
                    </button>
                  </div>
                </div>
              </div>
              <ol *ngIf="isOrdering && !showJSONView" cdkDropList (cdkDropListDropped)="util.drop(activeAssessmentParameter.parameters[currentLang], $event)" style="margin-left: 1em;">
                <li *ngFor="let param of activeAssessmentParameter.parameters[currentLang]; let i_param = index;" cdkDrag>
                  <span class="tag is-large">{{param.code}} {{param.name}}</span>
                </li>
              </ol>
            </div>
            <div>
              <button 
                [disabled]="paramSaveStateMap.get('common').saving || !hasAssessmentParameterChanges() || hasDuplicates() || isReadOnly()"
                class="button has-icon"  
                [class.is-success]="!paramSaveStateMap.get('common').saving" 
                (click)="saveCommonParamChanges()"
              >
                <span><tra slug="ie_save_changes"></tra></span>
              </button>
              <div *ngIf="hasDuplicates()" class="is-error">
                A duplicate parameter code has been detected in the parameter group: "{{hasDuplicates()}}".
              </div>
              <div *ngIf="paramSaveStateMap.get('common').success" class="is-success">
                Changes saved
              </div>
              <div *ngIf="paramSaveStateMap.get('common').error" class="is-error">
                Error saving changes
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer [hasLinks]="true"></footer>
</div>