export const generateEntries = <T> (len:number, method:(i?:number)=>T) : T[] => {
    const arr = [];
    for (let i=0; i<len; i++){
      arr.push( method(i) );
    }
    return arr;
  }

/**
 * @param nameStr 
 * @returns {string} Generated prefix from camelCase, snake_case and kebab-case
 */
export const generatePrefixFromString = (nameStr:string) : string => {
    const joiningChar = /[_-]/;
    let firstLetters = [];

    if (joiningChar.test(nameStr)) {
      // Handle snake_case or kebab-case
      firstLetters = nameStr.split(joiningChar).map(word => word[0]);
    } else {
      // Handle camelCase
      firstLetters = nameStr.match(/[A-Z]?[a-z]+/g)?.map(word => word[0]) || [];
    }

    return firstLetters.join('').toUpperCase();
  };