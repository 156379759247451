import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { downloadFile, downloadStr } from '../../../core/download-string';
// app services
import { AuthService } from '../../../api/auth.service';
import { LangService } from '../../../core/lang.service';
import { IQuestionConfig, IQuestionScoringInfo, IScoringCodes } from '../../../ui-testrunner/models';
import { IItemAuthNote } from '../../element-notes/element-notes.component';
import { PARAM_SPECIAL_FLAGS } from '../../framework-dimension-editor/model';
import { ItemMakerService } from '../../item-maker.service';
import { ItemType } from '../../models';
import { getPartitionPropValue, IPanelModulesConfig, IProgressBarConfig } from '../models/assessment-framework';
import { DimensionCategory, DimensionType, ENextBtnOpt, IAccessibilitySetting, IAssessmentFrameworkDef, IAssessmentFrameworkDetail, IAssessmentFrameworkDimensionDetail, IAssessmentPartition, NEXT_BTN_OPTS, QUESTION_WORDING_OPTS, TestFormConstructionMethod } from '../models/assessment-framework';
import { stripTabs, TAB, NEWLINE } from '../models/constants';
import { QuestionView } from '../models/types';
import { ItemBankCtrl } from './item-bank';
import { ItemFilterCtrl } from './item-filter';
import { PanelCtrl } from './mscat';
import { ItemSetPublishingCtrl } from './publishing';
import { FrameworkQuadrantCtrl } from './quadrants';
import { ItemBankSaveLoadCtrl } from './save-load';
import { TestletCtrl } from './testlets';
import { ItemBankUtilCtrl } from './util';
import { Destroyable } from './destroyable';
import { CLICK_TO_DRAG_DEFAULT } from 'src/app/ui-testrunner/accessibility.service';
import { CUSTOM_PROGRESS_BAR_CONFIG } from '../../widget-framework-settings/editors/progress-bar-editor/constants';

type SimplifiedQuestion = { id: number, label?: string }[];
type RemovedQuestionMap = {[key:string]: string[]};

export class ItemSetFrameworkCtrl implements Destroyable {
  
  selectedNewItemBank = new FormControl();

  accessibilitySettings: {
    [key:string]:IAccessibilitySetting
  } = {
    clickToDragDrop: {
      caption: 'click_to_drag_drop',
      isConfigurable: false,
      defaultVal: CLICK_TO_DRAG_DEFAULT
    }
  }
  asmtFmrk: IAssessmentFrameworkDetail;
  isFrameworkParamImportExport:boolean;
  isScoringScalesExpanded: boolean;
  frameworkParamImportExport = new FormControl();
  isEditRawFramework:boolean;
  rawFrameworkJson = new FormControl();
  paramExpansionMap:Map<IAssessmentFrameworkDimensionDetail, boolean> = new Map();
  isParamImportDryRun = new FormControl(false);
  public isParamDetailExpanded:boolean = false;
  public isAFParamDetailExpanded:boolean = false;
  public isAFParamDetailOrdering:boolean = false;
  public isFrameworkView:boolean;
  jsonImport = new FormControl();
  isImportOpen: boolean;
  isImporting: boolean;
  isImportFlushing: boolean;
  assessmentFrameworks: IAssessmentFrameworkDef[] = [{__id: '1', caption: ''}];
  activeAssessmentFramework = new FormControl();
  activeAsmtFwrkDimensions: Array<IAssessmentFrameworkDimensionDetail[]>; // an array of arrays...
  isEditingFramework: boolean;
  questionFrameworkMap: {d1: string[], d2: string[], count: Map<string, number>};
  selectedQuestionView = QuestionView.QUESTION_BANK;
  public testFormConstructionMethod = new FormControl(TestFormConstructionMethod.TLOFT);
  public questionWordingFc = new FormControl(QUESTION_WORDING_OPTS[0]);
  availableItemSets;
  isReadyToAddItemSet;
  allowNewItemsDuringParamImport = new FormControl(false);
  requireItemBankRefresh: boolean;
  importingQuestionLabel: string;
  progressBarDefaults: {slug: string, config: IProgressBarConfig}[] = [{slug: 'lbl_none', config: CUSTOM_PROGRESS_BAR_CONFIG()}];

  public util = new ItemBankUtilCtrl();
  
  public saveLoadCtrl: ItemBankSaveLoadCtrl;
  public panelCtrl: PanelCtrl;
  public quadrantCtrl: FrameworkQuadrantCtrl;
  public testletCtrl: TestletCtrl;
  public itemFilterCtrl: ItemFilterCtrl;
  public publishingCtrl: ItemSetPublishingCtrl;

  partitionIdToPreambleFc: {[key:number]: FormControl} = {};
  partitionIdToPostambleFc: {[key:number]: FormControl} = {};

  isParamSaveRequired = false;

  constructor(
    public auth: AuthService,
    public myItems: ItemMakerService,
    public itemBankCtrl: ItemBankCtrl,
    private lang: LangService
  ){
    
  }

  destroy() {
    
  }

  isTestformMsCat() { return this.testFormConstructionMethod.value === TestFormConstructionMethod.MSCAT; }
  isTestformLinear() { return this.testFormConstructionMethod.value === TestFormConstructionMethod.LINEAR; }
  isTestformLOFT() { return this.testFormConstructionMethod.value === TestFormConstructionMethod.TLOFT; }
  isTestFormDirectConstruction = () => this.isTestformLinear() && (this.selectedQuestionView === QuestionView.FORM_CONSTRUCTION);

  expandParam(param:IAssessmentFrameworkDimensionDetail, isExpand=true){
    this.paramExpansionMap.set(param, isExpand);
  }
  isParamExpanded(param:IAssessmentFrameworkDimensionDetail){
    return this.paramExpansionMap.get(param);
  }
  getParamCategNaming(dimLevel: number) {
    if (dimLevel === 0) {
      return 'Dimension';
    }
    return this.lang.tra('ie_parameter');
  }

  getPairableHumanScoringScales() {
    // console.log("pair", this.asmtFmrk.pairableHumanScoringScales)
    return this.asmtFmrk.pairableHumanScoringScales || [];
  }

  addPairableHumanScoringScales() {
    if(!this.asmtFmrk.pairableHumanScoringScales) this.asmtFmrk.pairableHumanScoringScales = [];
    this.asmtFmrk.pairableHumanScoringScales.push([
      { id: 0, name: '', description: '' },
      { id: 0, name: '', description: '' }
    ]);
    // console.log(this.asmtFmrk.pairableHumanScoringScales)
  }

  updatePairableHumanScoringScales(pairIdx: number, scaleIdx: number, scale: IScoringCodes) {
    if(!this.asmtFmrk.pairableHumanScoringScales) return;
    const existingScale = this.asmtFmrk.pairableHumanScoringScales[pairIdx][scaleIdx];
    existingScale.name = scale.short_name
    existingScale.id = scale.id
    if(this.lang.c() === 'fr'){
      existingScale.description =  scale.description_fr;
    } else {
      existingScale.description = scale.description_en;
    }

  }

  // removePairableHumanScoringScaleIds(pairIdx) {
  //   if(!this.asmtFmrk.pairableHumanScoringScaleIds) return;
  //   this.asmtFmrk.pairableHumanScoringScaleIds.splice(pairIdx, 1);
  // }
  
  checkIsAllReady() {
    let isAllReady = true;
    this.itemBankCtrl.getItems().forEach(question => {
      if (!question.isInfoSlide && !question.isReady) {
        isAllReady = false;
      }
    });
    return isAllReady;
  }

  getNumModules = () => ''; // deprecated
  
  previewQuestionFrameworkMapping() {
    console.log('previewQuestionFrameworkMapping 1');
    this.questionFrameworkMap = {
      d1: [],
      d2: [],
      count: new Map()
    };
    let d1Def = this.asmtFmrk.primaryDimensions[0];
    let d2Def = this.asmtFmrk.primaryDimensions[1];
    let d1Tags = d1Def.config.tags;
    let d2Tags = d2Def.config.tags;
    // let d1_pre = this.questionFrameworkMap.d1 = d1Tags.map(tag => tag.code);
    let d2 = this.questionFrameworkMap.d2 = d2Tags.map(tag => tag.code);
    let joiner, joinerCode;
    this.asmtFmrk.secondaryDimensions.forEach(sec => { // grossnes....
      if (sec.code === 'D1b') {
        joinerCode = sec.code;
        joiner = sec.config.tags.map(tag => tag.code);
      }
    });
    
    let d1 = this.questionFrameworkMap.d1 = joiner;
    d1.forEach(tag1 => {
      d2.forEach(tag2 => {
        let key = tag1 + '/' + tag2;
        this.questionFrameworkMap.count.set(key, 0);
      });
    });
    this.itemBankCtrl.getItems().forEach(question => {
      let tag1 = question['meta'][joinerCode];
      let tag2 = question['meta'][d2Def.code];
      if (tag1 && tag2) {
        let key = tag1 + '/' + tag2;
        let count = this.questionFrameworkMap.count.get(key);
        this.questionFrameworkMap.count.set(key, count + 1);
      }
    });
    console.log('previewQuestionFrameworkMapping 2');
  }
  
  getSectionQuestionsLinear = (asmtFmrk:IAssessmentFrameworkDetail, partition: IAssessmentPartition) => {
    const sectionId = partition.id;
    if (!this.asmtFmrk.sectionItems) {
      this.asmtFmrk.sectionItems = {};
    }
    let sectionItems = this.asmtFmrk.sectionItems[sectionId];
    if (!sectionItems) {
      sectionItems = this.asmtFmrk.sectionItems[sectionId] = {
        questions: []
      };
    }
    return sectionItems;
  }
  
  removeQuestionFromTestform(questionIdentifier, partition: IAssessmentPartition) {
    if (confirm('Are you sure you want to remove this item? ' + `(${questionIdentifier.label})`)) {
      const sectionQuestions = this.getSectionQuestionsLinear(this.asmtFmrk, partition);
      const i = sectionQuestions.questions.indexOf(questionIdentifier);
      if (i !== -1) {
        sectionQuestions.questions.splice(i , 1);
      }
    }
  }
  /**
   * 
   * @returns A map containing removed questions depending on the type of assesment it'll map to testlets, sections, and/or panels
   */
  getRemovedQuestionInFramework() {
    const removedQuestionsMap: RemovedQuestionMap = {};
    switch (this.testFormConstructionMethod.value as TestFormConstructionMethod) {
      default:
      case TestFormConstructionMethod.LINEAR:
        this.getRemovedQuestionFromLinearForm(removedQuestionsMap);
        break;
      case TestFormConstructionMethod.TLOFT:
        this.getRemovedQuestionFromTestlets(removedQuestionsMap);
        break;
      case TestFormConstructionMethod.MSCAT:
        this.getRemovedQuestionFromMSCATPanel(removedQuestionsMap);
        this.getRemovedQuestionFromTestlets(removedQuestionsMap);
        break;
    }
    return removedQuestionsMap
  }

  /**
   * Function that takes a map and populates with removed question in the current frameworks linear forms
   * @param removedQuestionsMap a map of removed question
   */
  getRemovedQuestionFromLinearForm(removedQuestionsMap: RemovedQuestionMap){
    this.asmtFmrk.partitions.forEach((partition) => {
      const questionInCurrentPartition = this.getSectionQuestionsLinear(this.asmtFmrk, partition).questions;
      const removedInCurrentPartition = this.getRemovedQuestions(questionInCurrentPartition as SimplifiedQuestion);
      if (removedInCurrentPartition.length > 0) {
        removedQuestionsMap[partition.description] = removedInCurrentPartition;
      }
    });
  }

  /**
   * Function that takes a map and populates with removed question in the current frameworks testlets
   * @param removedQuestionsMap a map of removed question
   */
  getRemovedQuestionFromTestlets(removedQuestionsMap: RemovedQuestionMap){
    this.asmtFmrk.testlets.forEach(testlet => {
      const removedInCurrentTestlet = this.getRemovedQuestions(testlet.questions as SimplifiedQuestion);
      if (removedInCurrentTestlet.length > 0) {
        const testletKey = `Testlet ${this.lang.tra('ie_id')} ${testlet.id}`
        removedQuestionsMap[testletKey] = removedInCurrentTestlet;
      }
    });
  }


  getRemovedQuestionFromMSCATPanel(removedQuestionsMap: RemovedQuestionMap) {
    this.asmtFmrk.panels?.forEach(panel => {
      const panelKey = `${this.lang.tra('ie_panel')} ${this.lang.tra('ie_id')} ${panel.id}`;
      let removedQuestionsFormatted = [];
  
      panel.modules.forEach(module => {
        const questions = module.__cached_itemIds.map((id, index) => {
          const question = this.itemBankCtrl.getQuestionById(id);
          return question ? question : { id: id, label: module.itemLabels[index] };
        });        
        const removedInCurrentModule = this.getRemovedQuestions(questions as SimplifiedQuestion);
        if (removedInCurrentModule.length) {
          const removedQuestionInCurrentModule = `Module ${module.moduleId}: ${removedInCurrentModule.join(', ')}`;
          removedQuestionsFormatted.push(removedQuestionInCurrentModule);
        }
      });
  
      if (removedQuestionsFormatted.length) {
        removedQuestionsMap[panelKey] = removedQuestionsFormatted;
      }
    });
  }
  

  /**
   * 
   * @param frmwrkQ question that you want to check whether or not they are a part of the available item banks
   * @returns all questions that were removed in an array of strings formatted: label (id)
   */
  getRemovedQuestions = (frmwrkQ: SimplifiedQuestion) => {
    const removedQuestions = [];
    frmwrkQ.forEach(fq => {
      const isInBankQ = this.itemBankCtrl.questions.some(bq => bq.id === fq.id);
      if (!isInBankQ) {
        removedQuestions.push(fq);
      }
    });
    return removedQuestions.map(q => `${q.label} (${q.id})`);
  }

  alertRemovedQuestions(removedQuestions: RemovedQuestionMap){
    if (Object.keys(removedQuestions).length) {
      Object.entries(removedQuestions)
        .map(([location, questions]) => `\n\t ${location}:${questions.join(', ')}`)
        .join();
      alert( this.lang.tra('framework_remove_missing_item_prompt'));
    }
  }

  removeSectionFromTestForm = (partition: IAssessmentPartition) => {

    const partitionId = partition.id
    const msg = `${this.lang.tra('ie_remove_section')} ${partitionId} ?`;
    if(confirm(msg)) {
      // remove from framework.partitions
      const i = this.asmtFmrk.partitions.indexOf(partition);
      if (i !== -1) {
        this.asmtFmrk.partitions.splice(i, 1);
        // remove from framework.sections
        delete this.asmtFmrk.sectionItems[partitionId];
      }

    }
  }
  
  editRawFramework(){
    this.isEditRawFramework = true;
    this.rawFrameworkJson.setValue(JSON.stringify(this.asmtFmrk, null, 2));
  }
  cancelEditRawFramework(){
    this.isEditRawFramework = false;
  }
  saveRawFramework(){
    const json = JSON.parse(this.rawFrameworkJson.value);
    this.asmtFmrk = json;
    alert('Save and refresh for best results')
    this.isEditRawFramework = false;
  }
  
  openFrameworkParamExport(){
    if (this.isFrameworkParamImportExport){
      this.isFrameworkParamImportExport = false;
    }
    else{
      const data = JSON.stringify(this.activeAsmtFwrkDimensions);
      this.frameworkParamImportExport.setValue(data);
      this.isFrameworkParamImportExport = true;
    }
  }
  
  importFrameworkParams(isInsertOnly:boolean=false){
    const dataJson = this.frameworkParamImportExport.value;
    const newData = JSON.parse(dataJson)
    console.log('isInsertOnly', isInsertOnly)
    if (isInsertOnly){
      const existingFields = new Map();
      const paramLists = [
        this.asmtFmrk.primaryDimensions,
        this.asmtFmrk.secondaryDimensions,
      ]
      paramLists.forEach(paramList =>{
        paramList.forEach(param => {
          existingFields.set(param.code, true)
        })
      })
      for (let list_i=0; list_i<newData.length; list_i++){
        const srcList = newData[list_i];
        const targetList = paramLists[list_i];
        srcList.forEach(param => {
          if (!existingFields.has(param.code)){
            targetList.push(param)
          }
        })
      }
    }
    else{
      this.activeAsmtFwrkDimensions = newData;
      this.asmtFmrk.primaryDimensions = newData[0];
      this.asmtFmrk.secondaryDimensions = newData[1];
    }
    this.isFrameworkParamImportExport = false;
    // this.saveLoadCtrl.saveChanges();
  }
  
  
  addRefDoc(){
    if (!this.asmtFmrk.referenceDocumentPages){
      this.asmtFmrk.referenceDocumentPages = [];
    }
    const itemId = +prompt('Item ID')
    const caption = prompt('Resource Name')
    this.asmtFmrk.referenceDocumentPages.push({itemId, caption})
  }
  
  getTloftFormQuestions(questionSrcDb) {
    const questions = [];
    questionSrcDb.forEach(question => {
      // remove info slides (returning placeholders right now, may need to remove this line in future)
      if (question.isInfoSlide !== undefined) return;

      let questionInfo;
      if (question.label && !question.id) {
        questionInfo = this.itemBankCtrl.getQuestionByLabel(question.label);
      }
      if (!questionInfo) {
        questionInfo = this.itemBankCtrl.getQuestionById(question.id);
      }
      if (questionInfo) {
        console.log(questionInfo.id, questionInfo.label)
        questions.push(questionInfo);  
      }
    });

    return questions;
  }

  getMsCatFormQuestions(panel: IPanelModulesConfig) {
    const questions = []; 
    this.asmtFmrk.panels.forEach(panelConfig => {
      if (panelConfig.id === panel.id) {
        panelConfig.modules.forEach(module => {          
          this.itemBankCtrl.getItems().forEach(question => {
            if (module.itemLabels.indexOf(question.label) !== -1) {
              questions.push(question);
            }
          });
        })
      }
    });
    
    return questions;
  }

  getLinearFormQuestions(){
    const questions = [];
    this.asmtFmrk.partitions.forEach(partition => {
      const questionInfoContainer = this.getSectionQuestionsLinear(this.asmtFmrk, partition);
      
      questionInfoContainer.questions.forEach(questionInfo => {
        let question;
        if (questionInfo.label && !questionInfo.id){
          question = this.itemBankCtrl.getQuestionByLabel(questionInfo.label);
          if (question){
            questionInfo.id = question.id;
          }
        }
        if (!question){
          question = this.itemBankCtrl.getQuestionById(questionInfo.id);
        }
        if (question) {
          questions.push(question);  
        }
      });
      // load simplied list of questions
    });
    return questions;
  }

  /**
   * 
   * @param qIds the question IDs to find
   * @returns an array of references to questions determined by their IDs
   */
  getQuestionsById(qIds: number[]){
    const questions: IQuestionConfig[] = [];
    qIds.forEach((id) => {
      let question;
      question = this.itemBankCtrl.getQuestionById(id);
      if (question) {
        questions.push(question);  
      }
    })
    return questions;
  }
  
  getNumQuestions() {
    return this.itemBankCtrl.getItems().length;
  }
  
  getNumQuadrants() {
    if (this.asmtFmrk && this.asmtFmrk.quadrantItems) {
      return this.asmtFmrk.quadrantItems.length;
    }
    return '--';
  }
  getNumTestlets() {
    if (this.asmtFmrk && this.asmtFmrk.testlets) {
      return this.asmtFmrk.testlets.length;
    }
    return '--';
  }
  getNumStoredPanels() {
    if (this.asmtFmrk && this.asmtFmrk.assembledPanels) {
      return this.asmtFmrk.assembledPanels.length;
    }
    return '--';
  }
  getNumTestForms() {
    if (this.asmtFmrk && this.asmtFmrk.testForms) {
      return this.asmtFmrk.testForms.length;
    }
    return '--';
  }
  
  createNewSection() {
    const description = prompt('new section name', 'New Section');
    if(description === null){return;}
    this.asmtFmrk.partitions.push({
      id: this.util.nextId(this.asmtFmrk.partitions),
      description
    });

    this.refreshPartitionToPreambleFcMap();
  }
  
  selectQuestionView(id: QuestionView) {
    this.selectedQuestionView = id;
    this.panelCtrl.activePanel = null;
    this.panelCtrl.filterToUsed = false;
    this.quadrantCtrl.activeQuadrant = null;
    this.testletCtrl.activeTestlet = null;
    this.itemFilterCtrl.updateItemFilter();
    if (id === QuestionView.TESTLETS) {
      this.testletCtrl.updateTestletFilter();
    }
    if (id !== QuestionView.QUESTION_BANK) {
      this.itemBankCtrl.currentQuestion = null;
    }
  }
  
  parseStats(stats: any) {
    let arr = [];
    Object.keys(stats).forEach(key => {
      arr.push({key, val: stats[key]});
    });
    return arr;
  }
  
  
  newItemBankAdd() {
    const newItemBankId = +this.selectedNewItemBank.value;
    let newItemBank;
    this.availableItemSets.forEach(itemBank => {
      if (itemBank.id === newItemBankId) {
        newItemBank = itemBank;
      }
    });
    // console.log('newItemBank', newItemBankId, newItemBank)
    if (newItemBank) {
      this.saveLoadCtrl.itemBanksUsed.push(newItemBank);
      this.saveLoadCtrl.saveChanges(); // to do: dedicated api call
      this.requireItemBankRefresh = true;
      this.saveLoadCtrl.fakeItemSetSaveBuffer();
    }
    this.isReadyToAddItemSet = false;
  }
  
  removeItemBank(i) {
    this.saveLoadCtrl.itemBanksUsed.splice(i, 1);
    this.saveLoadCtrl.saveChanges(); // to do: dedicated api call
    this.requireItemBankRefresh = true;
    this.saveLoadCtrl.fakeItemSetSaveBuffer();
  }

  newItemBankStart() {
    if ( this.isReadyToAddItemSet) {
      this.isReadyToAddItemSet = false;
    } 
    else if (this.availableItemSets) {
      this.isReadyToAddItemSet = true;
    } 
    else {
      this.isReadyToAddItemSet = true;
      this.myItems
        .loadMyItemSets()
        .then(itemSets => {
          this.saveLoadCtrl.isLoadingItemSets =  false;
          this.availableItemSets = itemSets;
        });
    }
  }

  scrollToQuestionListing() {
    setTimeout(() => {
      // const el = this.questionListingEl.nativeElement;
      // const options = {behavior: 'smooth', block: 'start'};
      // try {
      //   el.scrollIntoView(options);
      // } catch (e) {
      //   el.scrollIntoViewIfNeeded(options);
      // }
    }, 100);
  }
  
  getCurrentParameters (): IAssessmentFrameworkDimensionDetail[] {
    if (this.asmtFmrk) {
      return []
      .concat(this.asmtFmrk.standardParameters ?? [])
      .concat(this.asmtFmrk.primaryDimensions)
      .concat(this.asmtFmrk.secondaryDimensions);
    }
    return [];
  }
  
  identifySpecialParams(key: string) {
    const params = this.getCurrentParameters();
    const matchingParams: IAssessmentFrameworkDimensionDetail[] = [];
    params.forEach(param => {
      if (param.config && param.config.special) {
        if (param.config.special[key]) {
          matchingParams.push(param);
        }
      }
    });
    return matchingParams;
  }
  
  identifySingleEntryParams() {
    return this.identifySpecialParams(PARAM_SPECIAL_FLAGS.SINGLE_RESPONSE_ENTRY);
  }
  
  identifyBooleanParams() {
    const paramRef: Map<string, boolean> = new Map();
    const params = this.getCurrentParameters();
    params.forEach(param => {
      if (param.type === 'binary') {
        paramRef.set(param.code, true);
      }
    });
    return paramRef;
  }
  
  parseParamColor(color: string) {
    if (color) {
      return color;
    }
    return 'inherit';
  }
  
  openImport() {
    this.isImportOpen = true;
    this.jsonImport.reset();
  }
  closeImport() {
    this.jsonImport.reset();
    this.isImportOpen = false;
  }
  importTarget: FileList
  updateImportTarget(importTarget) {
    this.importTarget = importTarget;
  }
  async startImport() {
    let rows;
    let i = 0;
    const allowNew = this.allowNewItemsDuringParamImport.value
    const isParamImportDryRun = this.isParamImportDryRun.value
    const dimensions = [this.asmtFmrk.standardParameters || [], this.asmtFmrk.primaryDimensions || [], this.asmtFmrk.secondaryDimensions || []];
    const PRIMARY_KEY = 'question_label';
    const CONTENT_KEY = 'content';
    const LANG_KEY = 'lang';
    const ISSUES_KEY = 'issues';
    const NOTES_KEY = 'notes';
    const reservedKeys = [PRIMARY_KEY,CONTENT_KEY, LANG_KEY, ISSUES_KEY, NOTES_KEY];
    try {
      const file = this.importTarget.item(0);
      const fileTypeFull = file.type;
      const fileType = fileTypeFull.split('/')[0];
      const fileExt = file.name.split('.').pop();
      if (fileExt !== 'xlsx') {  
        alert(this.lang.tra('sa_unsupported_file',undefined,{file_type:fileExt})+'. Need XLSX for upload.');
        return 
      }
      const res:any = await this.auth.excelToJson(file);
      rows = res.json;
      console.log(rows)
      // rows = JSON.parse(this.jsonImport.value);
      
    } catch (e) {
      alert('invalid JSON input');
      return;
    }
    // identify parameters
    const paramRef: Map<string, IAssessmentFrameworkDimensionDetail> = new Map();
    dimensions.forEach(dim => {
      dim.forEach(param => {
        paramRef.set(param.code, param);
      });
    });
    // identify changes
    type ParamVal = boolean | string | number;
    const changedQuestionProp:{question_label:string, param:string, val_old:ParamVal, val_new:ParamVal }[] = [];
    const unknownParameters:{[key:string]:boolean} = {};
    // sanitize labels
    rows.forEach(questionRow => {
      questionRow.question_label = ''+questionRow.question_label;
    });
    // identify questions
    const questionsByLabel = new Map();
    this.itemBankCtrl.getItems().forEach(question => questionsByLabel.set(question.label, question));
    const missingQuestions = [];
    if (!allowNew){
      rows.forEach(questionRow => {
        const label = questionRow.question_label;
        if (!questionsByLabel.has(label)){
          missingQuestions.push(label);
        }
      });
      if (missingQuestions.length > 0){
        console.log('new items', missingQuestions);
        alert( missingQuestions.length + ' new question labels detected. Please select the checkbox to allow new items to be created with the import, or remove the new item labels from your import (see the log for a full list of the question_labels at issue).');
        return;
      }
    }
    // apply modifications
    this.isImporting = true;
    const increment = () => {
      i ++;
      if (i < rows.length) {
        applyNextQuestion();
      } 
      else {
        this.isImporting = false;
        this.isImportFlushing = true;
        console.log('Unknown Parameters', Object.keys(unknownParameters))
        console.log('Changed Question Props', changedQuestionProp)
        setTimeout(() => {
          this.isImportFlushing = false;
        }, 500);
        this.itemFilterCtrl.updateItemFilter();
        this.saveLoadCtrl.saveContent();
        this.closeImport();
      }
    };
    const selectQuestionByLabel = (questionLabel:string)=> {
      this.importingQuestionLabel = questionLabel;
      const question = questionsByLabel.get(questionLabel);
      if (!question) {
        if (allowNew){
          console.warn('Creating a new item', questionLabel);
          return this.itemBankCtrl.createNewQuestion(ItemType.ITEM).then(question=>{
            question.label = questionLabel;
            return question;
          })
        }
        else{
          increment();
        }
      }
      else{
        return this.itemBankCtrl.selectQuestion(question).then(res => question);
      }
    }
    const applyNextQuestion = () => {
      const row = rows[i];
      const questionLabel = row[PRIMARY_KEY];
      return selectQuestionByLabel(questionLabel)
      .then((question:IQuestionConfig) => {
        // identify changes
        const rowValues:{[key:string]:number | string | boolean} = {};
        Object.keys(row).forEach(paramCode => {
          let val = row[paramCode];
          if ( reservedKeys.indexOf(paramCode) === -1) {
            const param = paramRef.get(paramCode);
            if (!param) { unknownParameters[paramCode] = true }
            else {
              if (param.type === DimensionType.BINARY){
                switch (val){
                  case 'TRUE':
                  case 'true':
                  case '1':
                  case 1:
                    val = true;
                    break;
                  case 'false':
                  case 'FALSE':
                  case 0:
                  default:
                    val = false;
                    break;
                }
              }
              if (param.type === DimensionType.NUMERIC){
                if (val || val === 0){
                  val = ''+val;
                }
              }
              const val_old = question.meta[paramCode];
              const val_new = val;
              const isBothFalsey = ( (!val_old && val_old!==0) && (!val_new && val_new!==0) ) // except zeroes, those are important to handle NaN errors
              const isSame = (''+val_old === ''+val_new);
              if (!isSame && !isBothFalsey){ // intentionally less strict due to high number of undefined etc. being picked up
                changedQuestionProp.push({
                  question_label: questionLabel, 
                  param: paramCode, 
                  val_old, 
                  val_new,
                })
                rowValues[paramCode] = val;
              }
            }
          }
        })
        if (!isParamImportDryRun){
          // apply changes
          Object.keys(rowValues).forEach(paramCode => {
            const val = rowValues[paramCode];
            const param = paramRef.get(paramCode);
            if (param) {
              if (param.type === DimensionType.SELECT){
                let isMatchFound = false;
                if (!param.config.tags){ param.config.tags = []; }
                param.config.tags.forEach(tag => {
                  if (tag.code === val){
                    isMatchFound = true;
                  }
                })
                if (!isMatchFound){
                  param.config.tags.push({code:<string>val, name:''})
                }
              }
            }
            else{
              console.warn(paramCode, ' meta data is not defined in framework');
            }
            question.meta[paramCode] = val;
          });
          if (row[CONTENT_KEY]){
            if (row[LANG_KEY] === 'fr'){
              question.langLink.content = row[CONTENT_KEY];
            }
            else{
              question.content = row[CONTENT_KEY];
            }
          }
          if (row[NOTES_KEY]){
            question.notes = (question.notes || '') + '\n\n' + row[NOTES_KEY]
          }
        }
        
        if (isParamImportDryRun){
          increment();
        }
        else{
          this.itemBankCtrl.selectQuestion(question).then(()=>{ // this forces a save
            // console.log('ISSUES_KEY', row[ISSUES_KEY])
            if (row[ISSUES_KEY]){
              row[ISSUES_KEY].map(issueCaption => {
                this.createIssue(question.id, issueCaption);
              })
            }
            increment();
          })
        }
        // console.log(question.label, 'updated question')
      });
    };
    console.log('rows', rows);
    applyNextQuestion();
  }
  
  sanitizeParameterValue = (paramCode, val, paramRef, isExport=false) => {
    const param = paramRef.get(paramCode);
    if (param){
      switch(param.type){
        case DimensionType.BINARY:  return !!val;
        case DimensionType.SELECT:  return val || ''  ; 
        case DimensionType.NUMERIC:
          const isNan = ['', true, false].includes(val) || Number.isNaN(+val);
          const exportVal = isNan? '': +val; 
          const nonExportVal = +(val || 0);
          return isExport ? exportVal : nonExportVal; // reverted so only on export logic will change
        case DimensionType.LABEL:   return val || ''  ; 
        case DimensionType.TEXT:    return val ? val.caption : '' ; 
      }
    }
    return val ? JSON.stringify(val) : ''
  }

  getVal = (param: IAssessmentFrameworkDimensionDetail, question:IQuestionConfig, scoringInfo: IQuestionScoringInfo, paramRef: Map<string, IAssessmentFrameworkDimensionDetail>, isExport:boolean = false ) => {
    switch(param.category){
      case DimensionCategory.SCORING:
        return this.sanitizeParameterValue(param.code, scoringInfo[param.code], paramRef, isExport)
      case DimensionCategory.META:
      default:
        return this.sanitizeParameterValue(param.code, question.meta[param.code], paramRef, isExport);
    }
  }
  
  generateRows(questions, excludeHidden = null, parseEmptyNumericToString = false) {
    const dimensions = [this.asmtFmrk.standardParameters || [], this.asmtFmrk.primaryDimensions, this.asmtFmrk.secondaryDimensions];
    const paramRef: Map<string, IAssessmentFrameworkDimensionDetail> = new Map();
    const params = this.getCurrentParameters();
    params.forEach(param => {
      paramRef.set(param.code, param);
    });

    const rows = []
    questions.forEach(question => {
      const row = {
        item_id: question.id, 
        question_label: question.label,
        quadrantFreq: question.quadrantFreq,
        estimatedExposure: question.estimatedExposure,
      };

      this.generateDynamicColumns(row, question);

      dimensions.forEach(dim => {
        dim.forEach(param => {
          if (excludeHidden === false) {
            row[param.code] = this.getVal(param, question, this.itemBankCtrl.getQuestionScoringInfo(question.id), paramRef, parseEmptyNumericToString );
          } else {
            if (!param.isHidden) {
              row[param.code] = this.getVal(param, question, this.itemBankCtrl.getQuestionScoringInfo(question.id), paramRef, parseEmptyNumericToString );
            }
          }
        });
      });
      rows.push(row);
    });
    console.log(rows);
    return rows;
  }

  generateDynamicColumns(row: any, question: any) {
    if(!question || !question.id) return;
    
    const cols = ['stage', 'assignees', 'assigned_editors', 'pending_graphic_requests', 'assigned_resource', 'is_resource', 'is_questionnaire', 'expected_answer', 'weight']
    cols.forEach((val) => row[val] = null);

    // Workflow (commenting out because these columns are not found on qc8)
    // row['stage'] = this.itemBankCtrl.renderQuestionStageFull(question.id) || null
    // if(this.itemBankCtrl.getQuestionStage(question.id)?.isEditStage) {
    //   row['stage'] + ' ' + this.itemBankCtrl.getNumTimesEnterEdit(question.id);
    // }
    // row['assignees'] = this.itemBankCtrl.renderQuestionStageAssignees(question.id) || null;
    // row['assigned_editors'] = this.itemBankCtrl.renderQuestionEditorAssignees(question.id) || null;
    row['pending_graphic_requests'] = this.itemBankCtrl.getNumPendingGraphicReqs(question.id) || null;
    
    // Expected Answer
    if(this.itemBankCtrl.hasExpectedAnswer(question.id)) {
      row['expected_answer'] =  this.itemBankCtrl.getEAOptions(question.id).join(', ');
      row['weight'] = this.itemBankCtrl.getEAWeight(question.id);
    }
    
    // Reading selections
    const readSels: string[] = this.itemBankCtrl.getReadingSelections(this.itemBankCtrl.getQuestionContent(question));
    if(readSels && readSels.length) {
      row['assigned_resource'] = readSels.join(', ');
    }

    row['is_resource'] = question.isReadingSelectionPage ?? false;
    row['is_questionnaire'] = question.isQuestionnaire ?? false;
  }
  
  async exportMsCatTable(panel) {
    console.log('exportTable')
    const rows = this.generateRows(this.getMsCatFormQuestions(panel));
    const filename = `qb-${this.itemBankCtrl.currentSetName.value}-${moment().format('YYYY-MM-DD[T]HH_mm_ss')}`;
    const res = <any> await this.auth.jsonToExcel(rows, filename)
    downloadFile(res.url);
  }

  async exportTable(excludeHidden: boolean) {
    console.log('exportTable')
    const rows = this.generateRows(this.itemFilterCtrl.filteredQuestions, excludeHidden, true);
    const filename = `qb-${this.itemBankCtrl.currentSetName.value}-${moment().format('YYYY-MM-DD[T]HH_mm_ss')}`;
    const res = <any> await this.auth.jsonToExcel(rows, filename)
    downloadFile(res.url);
  }
  
  private createIssue(item_id, text){
    const user = this.auth.user().getValue();
    const data: IItemAuthNote = {
      text,
      parent_note_id: null,
      item_id,
      has_child: 0,
      is_resolved: 0,
      assigned_uid: null,
      created_by_first_name: user.firstName, // get user info from auth in API.
      created_by_last_name: user.lastName,
      created_by_uid: user.uid,
    };
    return this.auth.apiCreate('public/test-auth/notes', data);
  }

  refreshPartitionToPreambleFcMap() {
    for(const partition of this.asmtFmrk.partitions) {
      if(!this.partitionIdToPreambleFc[partition.id]) {
        this.partitionIdToPreambleFc[partition.id] = new FormControl();
      }
      if(!this.partitionIdToPostambleFc[partition.id]) {
        this.partitionIdToPostambleFc[partition.id] = new FormControl();
      }
    }
  }

  hoistAssessmentFrameworkForEditing() {
    // console.log('Assessment Framework: ', asmtFwrk);
    const asmtFwrk = this.asmtFmrk;
    this.refreshPartitionToPreambleFcMap();
    this.asmtFmrk.quadrantIdAI = this.asmtFmrk.quadrantIdAI || 0;
    this.asmtFmrk.parititionIdAI = this.asmtFmrk.parititionIdAI || 0;
    this.asmtFmrk.showDocumentsSplitScreen = this.asmtFmrk.showDocumentsSplitScreen || false;
    this.asmtFmrk.showQHeader = this.asmtFmrk.showQHeader || false;
    this.asmtFmrk.nextButtonOpt = this.asmtFmrk.nextButtonOpt || ENextBtnOpt.NEXT;
    if (!this.asmtFmrk.partitions) {
      this.asmtFmrk.partitions = [];
    }
    if (!this.asmtFmrk.quadrants) {
      this.asmtFmrk.quadrants = [];
    }
    if (!this.asmtFmrk.testlets) {
      this.asmtFmrk.testlets = [];
    }
    this.activeAsmtFwrkDimensions = [
      asmtFwrk.primaryDimensions,
      asmtFwrk.secondaryDimensions,
      asmtFwrk.standardParameters || []

    ];

    this.asmtFmrk.styleProfile = this.itemBankCtrl.styleProfileSelector.value;

    this.publishingCtrl.loadTestDesignReleaseHistory();

    this.questionWordingFc.setValue(this.asmtFmrk.questionWordSlug);

    this.testFormConstructionMethod.setValue(this.asmtFmrk.testFormType || TestFormConstructionMethod.LINEAR);
    if (this.asmtFmrk.testFormType === TestFormConstructionMethod.LINEAR && this.asmtFmrk.partitions) {
      this.itemBankCtrl.selectSection(this.asmtFmrk.partitions[this.asmtFmrk.partitions.length - 1]);
      this.selectQuestionView(QuestionView.FORM_CONSTRUCTION);
    }
    this.quadrantCtrl.refreshQuadrantItems();
  }

  newTag(){
    const slug = prompt('Which tag?')
    if (!this.asmtFmrk.tags){
      this.asmtFmrk.tags = []
    }
    this.asmtFmrk.tags.push({slug})
  }

  newPreservedParam(){
    const slug = prompt('Which parameter?')
    if (!this.asmtFmrk.preservedMetaParams){
      this.asmtFmrk.preservedMetaParams = []
    }
    this.asmtFmrk.preservedMetaParams.push(slug)
  }

  defineNewParameter(arr: IAssessmentFrameworkDimensionDetail[]) {
    arr.push({
      name: 'New Parameter', // ex: Number Sense
      code: '', // ex: NS
      type: DimensionType.BINARY,
      config: {

      }
    });
  }

  newSimilaritySlug(){
    const slug = prompt('Which slug?')
    if (!this.asmtFmrk.similaritySlugs){
      this.asmtFmrk.similaritySlugs = []
    }
    this.asmtFmrk.similaritySlugs.push({slug})
  }

  isPsychoParam(param: IAssessmentFrameworkDimensionDetail) {
    return param && param.config && param.config.special && param.config.special[PARAM_SPECIAL_FLAGS.PSYCHO_EDIT];
  }

  getAccSettingConfigVal(settingProp: string, configProp: string) {
    if(!this.asmtFmrk.accessibilitySettings || (typeof this.asmtFmrk.accessibilitySettings !== 'object')) {
      this.asmtFmrk.accessibilitySettings = {};
    }
    if(!this.asmtFmrk.accessibilitySettings[settingProp]) {
      this.asmtFmrk.accessibilitySettings[settingProp] = this.accessibilitySettings[settingProp];
    }
    return this.asmtFmrk.accessibilitySettings[settingProp][configProp];
  }

  getAccSettingConfigurable(settingProp: string) {
    return this.getAccSettingConfigVal(settingProp, 'isConfigurable');
  }

  getAccSettingDefault(settingProp: string) {
    return this.getAccSettingConfigVal(settingProp, 'defaultVal');
  }

  getPartitionPropValue = <K extends keyof IAssessmentPartition>(
    partition: IAssessmentPartition,
    prop: K
  ): IAssessmentPartition[K] => {

    const lang = this.lang.c();
    const useLangSpecificSectionProps = this.asmtFmrk.useLangSpecificSectionProps;
    return getPartitionPropValue(partition, prop, lang, useLangSpecificSectionProps);

  }
  
}