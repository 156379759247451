import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { initMappedList, IMappedList } from './data/util';
import { STUDENT_G9_COURSES, STUDENT_G9_COURSES_SIMPLE, G9_COURSES, STUDENT_SEMESTET_INDICATOR } from './data/constants';
import { IIdentifiedEntry, IDataDefinition, IStudentAccount, IStudentTestSession, AUTOSAVE_MESSAGES } from './data/types';
import { IClassroomArchivedTeachers, IUserInfo, IClassroomSlot, ASSESSMENT } from '../../app/ui-teacher/data/types';
import { IClassroom, ISemester, ISession} from "./data/types"
import { generateEntries, randArrEntry, randInt, randId, coinFlip, randDate, IAvailableSession } from "../ui-testadmin/demo-data.service";
import { randomIdentity } from "../constants/fakenames";
import { LangService } from '../core/lang.service';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { AssessmentCode, DATA_MAPPING_EQAO_G9_STUDENT, ICheckMarkMapping, IDataMappingG9Student } from './data/mappings/eqao-g9';
import { BC_SAMPLE_SCHOOL_FSA } from './data/sample-data/school';
import { WhitelabelService } from "../../app/domain/whitelabel.service";
import { ClassFilterId } from './my-school.service';
import { ReadingSelectionButtonRendererComponent } from '../ui-scoring-leader/panel-sl-training-materials/reading-selection-button-renderer/reading-selection-button-renderer.component';
import { tzLangAbbrs } from '../core/util/moment'
import { getActivatedExemptions } from './data/mappings/accommodations';
import { ClassroomsService } from '../core/classrooms.service';

export const generateAccessCode = (len: number) => {
  let result = '';
  const characters = 'ABCDEFGHJKLMNPQRTUVWXYZ12346789';
  const charactersLength = characters.length;
  for (var i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export const hyphenateAccessCode = (accessCode: string) => {
  if (!accessCode) { return accessCode; }
  let result = '';
  const hyphenInterval = 4;
  for (var i = 0; i < accessCode.length; i++) {
    if (i > 0 && (i % hyphenInterval == 0)) {
      result += '-'
    }
    result += accessCode.charAt(i);
  }
  return result;
}

export const G9_SLUG_TO_CAPTION = {
  PRIMARY_SAMPLE: 'lbl_primary_sample_assessment',
  PRIMARY_OPERATIONAL: 'lbl_primary_operational_assessment',
  JUNIOR_SAMPLE: 'lbl_junior_sample_assessment',
  JUNIOR_OPERATIONAL: 'lbl_junior_operational_assessment',
  G9_SAMPLE: 'g9_sample_test',
  G9_OPERATIONAL: 'g9_assess_math',
  OSSLT_SAMPLE: 'lbl_osslt_practice_test',
  OSSLT_OPERATIONAL: 'lbl_osslt_test',
}

export const overlapVariables = [
  'first_name',         'last_name',                'eqao_student_school_enrol_type', 'eqao_student_gov_id',     'SASN',
  'date_of_birth',      'eqao_gender',              'eqao_date_entered_school',       'eqao_date_entered_board', 'eqao_indigenous_type',
  'eqao_first_language','eqao_enrolled_ontario',    'eqao_out_province_residence',    'eqao_status_in_canada',   'eqao_time_in_canada',
  'eqao_refugee',       'eqao_born_outside_canada', 'eqao_is_g3',                     'eqao_is_g6',              'eqao_is_g9',        'eqao_is_g10',            
 ]
 
@Injectable({
  providedIn: 'root'
})

export class G9DemoDataService {

  
  teachers: IMappedList<IIdentifiedEntry>;
  invigilators:any[];
  schoolAdminStudents: IMappedList<IStudentAccount>;
  classOptions: IMappedList<IDataDefinition>;
  classrooms: IClassroom[];
  guestClasses : any[];
  guestClassesInvigilate : any[];
  semesters: IMappedList<ISemester>;
  teacherClassrooms: IMappedList<any>;
  schoolData: any;
  assessments:IMappedList<ISession>;
  schoolSessions: any[];
  private isFromSchoolAdmin = false;
  private studentMappings = DATA_MAPPING_EQAO_G9_STUDENT;
  public checkMarkMapping: ICheckMarkMapping = {};
  public g10PropCheckMarkMapping: ICheckMarkMapping = {};

  private classroomMappings = [
    { source: 'id', target: 'id' },
    { source: 'name', target: 'class_code' },
    { source: 'teacher', target: 'educator' },
    { source: 'teacher_uid', target: 'teacher_uid' },
    { source: 'semester_id', target: 'semester' },
    { source: 'semester_foreign_id', target: 'semester_foreign_id' },
    { source: 'num_students', target: 'students' },
    { source: 'group_type', target: 'course_type' },
    { source: 'is_grouping', target: 'is_grouping' },
    { source: 'is_placeholder', target: 'is_placeholder' },
    { source: 'is_fi', target: 'is_fi' },
  ];
  private schoolDistrictGroupId: number
  activeSessionsCount:number;
  scheduledSessionsCount:number;
  primaryActiveSessionsCount:number;
  primaryScheduledSessionsCount:number;
  juniorActiveSessionsCount:number;
  juniorScheduledSessionsCount:number;
  g9ActiveSessionsCount:number;
  g9ScheduledSessionsCount:number;
  ossltActiveSessionsCount:number;
  ossltScheduledSessionsCount:number;
  attempts: IMappedList<IIdentifiedEntry>;
  testWindows: any[];
  schoolDistrict:{foreign_id?: string} = {};
  schoolDist: {};
  isUnSubmitting = false;
  showPaymentAgreement = false;
  totalNonAcceptedRecords = [];
  studentAttemptInfo = []
  altVersionFormats = {};
  private autoSaveModalMessageSource:BehaviorSubject<AUTOSAVE_MESSAGES> = new BehaviorSubject(null);
  autosaveModalMessage = this.autoSaveModalMessageSource.asObservable()
  // Keep track of if the student's class was changed in the modal for alt request purposes
  isStudentClassChanged: boolean = false;

  scan_response_requires = [];
  constructor(
    private lang: LangService,
    private whitelabelService: WhitelabelService
  ) {
    // const res = require("./data/sample-response.json");
    // this.init(BC_SAMPLE_SCHOOL_FSA)
  }

  newAutosaveModalMessage(message:AUTOSAVE_MESSAGES) {
    this.autoSaveModalMessageSource.next(message)
  }

  initCheckmarkMappings() {
    this.studentMappings.forEach((studentMapping: IDataMappingG9Student) => {
      this.checkMarkMapping[studentMapping.target] = (this.lang.c() === 'fr' && studentMapping.ignoreChekMarkInFrench) ? undefined:studentMapping.checkMarkMap;

      const isG10 = studentMapping.key_namespace === 'eqao_sdc_g10';
      let prefix = isG10 ? '_g10_' : '';
      this.g10PropCheckMarkMapping[`${prefix}${studentMapping.target}`] = studentMapping.checkMarkMap;
    })
  }

  init(res: any) {
    this.schoolData = res.school[0];
    this.showPaymentAgreement = res.showPaymentAgreement;
    this.totalNonAcceptedRecords = res.totalNonAcceptedRecords;
    this.studentAttemptInfo = res.student_attempt_info;
    const schoolLang = this.schoolData["lang"];
    this.lang.setCurrentLanguage(schoolLang);
    this.initCheckmarkMappings();
    const studentData = res.students || [];
    const teacherData = res.teachers || [];
    const semesterData = res.school_semesters || [];
    const sessionData = res.sessions || []; 
    const classesData: any[] = res.classes || [];
    const guestClassesData:any[] = res.guest_classes || [];
    const guestClassesInvigilateData:any[] = res.guest_classes_invigilators || [];
    const testAttempts = res.test_attempts || [];
    const studentSubmissions = res.student_submissions || [];
    const studentTestAttempts: any[] = res.student_testAttempts || []
    const SDC_conflicts_g3 = res.SDC_conflicts_g3 ||[]
    const SDC_conflicts_g6 = res.SDC_conflicts_g6 ||[]
    const SDC_conflicts_g9 = res.SDC_conflicts_g9 ||[]
    const SDC_conflicts_g10 = res.SDC_conflicts_g10 ||[]
    const alt_version_information = res.alt_version_information ||[]
    const alt_version_format_values = res.alt_version_format_values ||[]
    const scan_response_requires = res.scan_response_requires || []
    this.scan_response_requires = scan_response_requires
    //const students_created_loaded_time = res.students_created_loaded_time || []
    const mapBy = (arr, prop) => {
      const m = new Map();
      arr.forEach(entry => {
        m.set(entry[prop], entry)
      })
      return m;
    }

    const metaKeys = [];


    // access_code: "ANTW"
    // caption: null
    // date_time_start: "2020-11-05T01:45:18.000Z"
    // school_class_id: 9
    // slug: "G9_SAMPLE"
    // test_session_id: 584


    //creates map from student id value to student data
    const studentByUid = mapBy(studentData, 'id');
    const teachersByUid = mapBy(teacherData, 'id');
    const classesByGroupId = mapBy(classesData, 'group_id');
    const classesByClassId = mapBy(classesData, 'id');
    const teacherByClassGroupId = new Map();

    res.classes_sessions = res.classes_sessions || [];
    res.classes_closed_sessions = res.classes_closed_sessions || [];
    res.classes_teachers = res.classes_teachers || [];
    res.classes_invigilators = res.classes_invigilators || [];
    res.student_meta = res.student_meta || [];
    res.classes_students = res.classes_students || [];

    classesData.forEach(classroom => {
      classroom.when = (classroom.notes || '').split('When:').join('')
      classroom.students = []
      classroom.openAssessments = []
      classroom.recentAssessments = []
      classroom.scheduledAssessments = []
    })

    res.classes_sessions.forEach(session => {
      const classroom = classesByClassId.get(session.school_class_id);
      let currDate = new Date();
      let sessionDate = new Date(session.date_time_start)
      if (currDate.getTime() < sessionDate.getTime()) {
        //scheduled for the future
        classroom.scheduledAssessments.push(session)
      }
      else{
        classroom.openAssessments.push(session);
      }
    });

    res.classes_closed_sessions.forEach(session => {
      const classroom = classesByClassId.get(session.school_class_id);
      const closeTime = new Date(session.closed_on);
      const openTime = new Date(session.date_time_start);
      if(closeTime.getTime() > openTime.getTime()){
        classroom.recentAssessments.push(session);
      }
    })

    res.student_meta.forEach(meta => {
        const student = studentByUid.get(meta.uid);
        if(!student) {
          console.warn('no student found for uid: ' + meta.uid)
          return;
        }
        student[meta.key_namespace] = student[meta.key_namespace] || {}
        student[meta.key_namespace][meta.key] = meta.value;
        //student[meta.key] = meta.value;
        //metaKeys.push(meta.key)
    })

    res.classes_teachers.forEach(entry => {
      const teacher = teachersByUid.get(entry.uid)
      const classroom = classesByGroupId.get(entry.group_id);
      if(classroom){
        classroom.teacher_uid = entry.uid;
      }  
      if (teacher) {
        teacher.classes = teacher.classes || [];
        teacher.semesters = teacher.semesters || [];
        if(classroom){
          classroom.teacher = teacher.first_name + ' ' + teacher.last_name;
          teacher.classes.push(classroom.name)
          teacher.semesters.push(classroom.semester_id)
        }
        teacherByClassGroupId.set(entry.group_id, teacher)
      }
    })

    // Array to keep track of teachers and their students
    const teachersWithStudents: {
      teacher: any,
      students: any[]
    }[] = [];

    // Helper function to find one element in the array
    const firstOrDefault = (inputArray: any[], condition: any): any => {
      if (inputArray == null || inputArray.length == 0) {
        return null;
      }
      for(let i = 0; i < inputArray.length; i++) {
        const el = inputArray[i];
        if (condition(el) === true) {
          return el;
        }
      }
      return null;
    };

    res.classes_students.forEach(entry => {
      const student = studentByUid.get(entry.uid)
      const teacher = teacherByClassGroupId.get(entry.group_id)
      const classroom = classesByGroupId.get(entry.group_id);
      if (student) {
        //student.class_code = classroom.name;
        student.class_code = student.class_code||[];
        student.class_code.push(classroom.name)
        student.teacher = classroom.teacher;
        student.classroomIds = student.classroomIds || []
        student.classroomIds.push(entry.group_id);
        student.semester_id = classroom.semester_id
        student.is_guest = entry.is_guest
        student.scg_id = entry.scg_id
        student.from_sasn_schl = entry.from_sasn_schl
        student.paidForClass = student.paidForClass || {}
        student.paidForClass[classroom.id] = {
          isPaid: entry.isPaid,
          alternativeStatus: entry.alternativeStatus
        }
        // classroom.semesters = classroom.semesters || []
        // classroom.semesters.push(student.Program); //This doesn't seem right
        // classroom.course_id = classroom.course_ids || []
        classroom.courses = classroom.courses || []
        const relevantCourse = STUDENT_G9_COURSES.map[student.Program];
        if (relevantCourse) {
          classroom.courses.push(this.lang.tra(relevantCourse.course));
        }
        classroom.course_id = student.Program;
        classroom.num_students = classroom.num_students || 0;
        classroom.num_students++;
        if (teacher) {
          // Track the teachers with their students
          const teacherWithStudents = firstOrDefault(teachersWithStudents, (ts: any) => ts.teacher && ts.teacher.id === teacher.id);
          // New teacher, let's add it to the list
          if (!teacherWithStudents) {
            teachersWithStudents.push({
              teacher: teacher,
              students: [student]
            })
          } else {
            // Check for students duplication to avoid extera student count BUG# 
            const teachersStudent = firstOrDefault(teacherWithStudents.students, (st: any) => st.id === student.id);
            if (!teachersStudent) {
              teacherWithStudents.students.push(student);
            }
          }
          // teacher.num_students = teacher.num_students || 0;
          // teacher.num_students++;
        }
      }
    });

    // Fix num_students on teacher object
    teachersWithStudents.forEach((ts: any) => {
      if (ts.teacher) {
        ts.teacher.num_students = ts.students ? ts.students.length : 0
      }
    });
  

    classesData.forEach(classroom => {
      classroom.courses = _.uniq(classroom.courses).join(', ')
    })

    teacherData.forEach(teacher => {
      teacher.classes = teacher.classes || []
      teacher.classes = teacher.classes.join(', ')
      //teacher.semester = 'test'
    })

    const studentsList = [];

    const submissionMap = {}

    // todo: split up is_submitted by assessment
    const operationalSubmRef = [
      {filter: ClassFilterId.Primary, slug: 'PRIMARY_OPERATIONAL'},
      {filter: ClassFilterId.Junior, slug: 'JUNIOR_OPERATIONAL'},
      // {filter: ClassFilterId.G9, slug: 'G9_OPERATIONAL'},
      {filter: ClassFilterId.OSSLT, slug: 'OSSLT_OPERATIONAL'},
    ]
    const operationalSubmMap = new Map()
    operationalSubmRef.forEach(record => {
      submissionMap[record.filter] = {};
      operationalSubmMap.set(record.slug, record.filter)
    });
    studentSubmissions.forEach(stuSubmRecord =>{
      if(stuSubmRecord){ // .is_submitted == 1
        const submFilter = operationalSubmMap.get(stuSubmRecord.slug);
        if (submFilter){
          submissionMap[submFilter][stuSubmRecord.id] = stuSubmRecord
        }
      }
    });

    const getSubmissionStatusStr = (state:boolean) => {
      if (state){
        return '1'; // this.lang.tra('lbl_status_submitted');
      }
      else {
        return '#'; // this.lang.tra('lbl_no')
      }
    }

    for (let student of studentByUid.values()) {
      const studentNormalized = this.apiToClientPayloadStudent(student);
      let submState;
      operationalSubmRef.forEach(record => {
        const submissionMapRecord = submissionMap[record.filter][student.id];
        if(submissionMapRecord) {
          let submState = submissionMapRecord;
          // todo: split up is_submitted by assessment
          studentNormalized.is_submitted_state = submState;
          studentNormalized.is_submitted = getSubmissionStatusStr(!!submState);
        }
        if(record.filter === ClassFilterId.OSSLT) {
          if (submState) {
            // todo: other data models to be updated when g9 included here
            // console.log('studentNormalized submission')
            studentNormalized.osslt__is_submitted = '1'; // "Yes";
            if (submState.overall == 1){
              studentNormalized.osslt__is_success = '1'; // 'Yes'
            }
            else if (submState.is_data_insufficient == 1){
              studentNormalized.osslt__is_success = '2'; // 'Insuf.'
            }
            else {
              studentNormalized.osslt__is_success = '0'; // 'Not Yet'
            }
          }
          else{
            studentNormalized.osslt__is_success = '#';
            studentNormalized.osslt__is_submitted = '#'; // no 
          }
        }
      });
      studentNormalized.testAttempts = studentTestAttempts.filter(attempt => attempt.uid == studentNormalized.id)
      studentNormalized.havePrimarySubmitted = '#';
      const primarySlug = 'PRIMARY_OPERATIONAL';
      const havePrimarySubmitted = studentNormalized.testAttempts.find(ta => ta.slug === primarySlug && +ta.is_submitted === 1)
      if(havePrimarySubmitted!=undefined){
        studentNormalized.havePrimarySubmitted = '1';
      }
      studentNormalized.haveJuniorSubmitted = '#';
      const juniorSlug = 'JUNIOR_OPERATIONAL';
      const haveJuniorSubmitted = studentNormalized.testAttempts.find(ta => ta.slug === juniorSlug && +ta.is_submitted === 1)
      if(haveJuniorSubmitted!=undefined){
        studentNormalized.haveJuniorSubmitted = '1';
      }

      studentNormalized.haveOSSLTSubmitted = '#';
      const ossltSlug = 'OSSLT_OPERATIONAL';
      const haveOSSLTSubmitted = studentNormalized.testAttempts.find(ta => ta.slug === ossltSlug && +ta.is_submitted === 1)
      if(haveOSSLTSubmitted!=undefined){
        studentNormalized.haveOSSLTSubmitted = '1';
      }
      studentNormalized.haveG9Submitted = '#';
      const g9Slug = 'G9_OPERATIONAL';
      const haveG9Submitted = studentNormalized.testAttempts.find(ta => ta.slug === g9Slug && +ta.is_submitted === 1)
      if(haveG9Submitted!=undefined){
        studentNormalized.haveG9Submitted = '1';
      } 
      studentNormalized.is_guest =  student.is_guest
      studentNormalized.from_sasn_schl = student.from_sasn_schl
      studentNormalized.scg_id = student.scg_id
      studentNormalized.paidForClass = student.paidForClass || []
      studentsList.push(studentNormalized);


      if (student.classroomIds) {
        student.classroomIds.forEach(classGroupId => {
          const classroom = classesByGroupId.get(classGroupId);
          classroom.students.push(studentNormalized)
        })
      }

      //attach alternative version data
      res.test_windows.forEach(tw =>{
        const altVersionRequestName =  this.getStudentAltVersionRequestName(tw)
        const student_tw_alt_version_information =  alt_version_information.find(avi => +avi.student_uid === +studentNormalized.id && +avi.test_window_id === +tw.id)
        if(student_tw_alt_version_information!= undefined){
          studentNormalized[altVersionRequestName] = student_tw_alt_version_information
          this.setAltVersionRequires(studentNormalized[altVersionRequestName])
        }
      })

      //attach scan response requires
      res.test_windows.forEach(tw =>{
        const scanResponseRequireName =  this.getStudentScanResponseRequireName(tw)
        const student_tw_scan_response_requires =  scan_response_requires.find(srr => +srr.student_uid === +studentNormalized.id && +srr.test_window_id === +tw.id)
        if(student_tw_scan_response_requires!= undefined){
          studentNormalized[scanResponseRequireName] = student_tw_scan_response_requires
        }
      })
    }

    this.schoolAdminStudents = initMappedList(studentsList);
    const sampleStudents = this.schoolAdminStudents.list;
    const nameToId = new Map();
    const classOptionsList = classesData.map((classroom, index) => {
      const id = classroom.id;
      nameToId.set(classroom.name, id);
      return { id, label: classroom.name, group_type: classroom.group_type};
    });
    for (let student of sampleStudents) {
      //student.eqao_g9_class_code_label = student.eqao_g9_class_code;
      //student.eqao_g9_class_code = nameToId.get(student.eqao_g9_class_code);

      studentByUid.get(student.id).classroomIds.forEach(classID => {
        const studentClass = classesData.find(theClass => theClass.group_id == classID)
        if(studentClass.group_type == 'EQAO_G3'){
          student.class_group_id = studentClass.group_id
          student._g3_eqao_g9_class_code = studentClass.id;
          student._g3_eqao_g9_class_code_label = studentClass.name
        }
        if(studentClass.group_type == 'EQAO_G6'){
          student.class_group_id = studentClass.group_id
          student._g6_eqao_g9_class_code = studentClass.id;
          student._g6_eqao_g9_class_code_label = studentClass.name
        }
        if(studentClass.group_type == 'EQAO_G9'){
          student.class_group_id = studentClass.group_id
          student.eqao_g9_class_code = studentClass.id;
          student.eqao_g9_class_code_label = studentClass.name
        }
        if(studentClass.group_type == 'EQAO_G10'){
          student.class_group_id = studentClass.group_id
          student._g10_eqao_g9_class_code = studentClass.id;
          student._g10_eqao_g9_class_code_label = studentClass.name
        }
      });
    }
    this.classOptions = initMappedList(classOptionsList);
    const teachersList = [];
    for (let teacher of teacherData) {
      let time = moment.tz('2020-10-28 17:00', moment.tz.guess()).format(this.lang.tra('datefmt_dashboard_short'));
      let time2 = moment.tz('2020-11-07 17:00', moment.tz.guess()).format(this.lang.tra('datefmt_dashboard_short'));
      let secret_key;
      let invit_id
      if (teacher.created_on) {
        time = moment.tz(teacher.created_on, moment.tz.guess()).format(this.lang.tra('datefmt_dashboard_short'));
      }
      if (teacher.expire_on) {
        time2 = moment.tz(teacher.expire_on, moment.tz.guess()).format(this.lang.tra('datefmt_dashboard_short'));
      }

      const name = teacher.first_name + " " + teacher.last_name;
      teachersList.push({
        id: teacher.id,
        label: name,
        invigilator: name,
        // firstName: name.trim().split(" ")[0],
        // lastName: name.trim().split(" ")[1],
        firstName: teacher.first_name,
        lastName: teacher.last_name,
        email: teacher.contact_email,
        invit_id: teacher.invit_id || null,
        secret_key: teacher.secret_key || null,
        invit_email: teacher.invit_email || null,
        isConfirmed: !!teacher.contact_email,
        classCode: teacher.classes,
        semester:teacher.semesters,
        semester_labels: (teacher.semesters && teacher.semesters.lengh>0) ? teacher.semesters.map(semester => semesterData.find(semester2 => semester2.id == semester).name):[],
        students: teacher.num_students || 0,
        startTime: time, //This and below are still sample data
        endTime: time2,
        expireOn: teacher.expire_on
      });
    }

    this.teachers = initMappedList(teachersList);
    this.invigilators = res.classes_invigilators

    // console.log('classesData', classesData)
    this.semesters = initMappedList(semesterData.map(semester => {
      const testWindow = res.test_windows.find(tw => +tw.id == +semester.test_window_id)
      let label = semester.name;
      if(testWindow && testWindow.type_slug == 'EQAO_G9M'){
          // update (translate) semester label
          const studentSemester = STUDENT_SEMESTET_INDICATOR.list.find(termformat => +termformat.id == +semester.foreign_id);
          if(studentSemester) {
            label = studentSemester.label;
          }
      }
      return {
        id: semester.id,
        foreign_scope_id: semester.foreign_scope_id,
        foreign_id: semester.foreign_id,
        label,
        testWindowId: semester.test_window_id
      }
    }))
    this.attempts = initMappedList(testAttempts.map(attempt => {
      return {
        twtdar_order: attempt.twtdar_order,
        submissions: attempt.submissions,
        submitted_test_session_id: attempt.submitted_test_session_id,
      }
    }))

    if(!Array.isArray(sessionData)){
      this.activeSessionsCount = 0;
      this.scheduledSessionsCount = 0;
      this.primaryActiveSessionsCount = 0;
      this.primaryScheduledSessionsCount = 0;
      this.juniorActiveSessionsCount = 0;
      this.juniorScheduledSessionsCount = 0;
      this.g9ActiveSessionsCount = 0;
      this.g9ScheduledSessionsCount = 0;
      this.ossltActiveSessionsCount = 0;
      this.ossltScheduledSessionsCount = 0;
      this.assessments = initMappedList(sessionData.school_sessions.map(session => {
        const sessionStates = sessionData.session_states.find(ss => ss.test_session_id === session.test_session_id)
        const subSessionRecord_A = sessionStates.states.subSessionRecords.find(ssr => ssr.caption === "A");
        let subSessionRecord_B = sessionStates.states.subSessionRecords.find(ssr => ssr.caption === "B");
        if(session.slug == ASSESSMENT.PRIMARY_SAMPLE || session.slug == ASSESSMENT.PRIMARY_OPERATIONAL || session.slug == ASSESSMENT.JUNIOR_SAMPLE || session.slug == ASSESSMENT.JUNIOR_OPERATIONAL){
          subSessionRecord_B = sessionStates.states.subSessionRecords.find(ssr => ssr.caption === "1");
        }
        const isSampleTest = session.slug == ASSESSMENT.PRIMARY_SAMPLE || session.slug == ASSESSMENT.JUNIOR_SAMPLE || session.slug == ASSESSMENT.G9_SAMPLE || session.slug == ASSESSMENT.OSSLT_SAMPLE
        return {
          id: session.test_session_id,
          school_name:null,
          isPaid: '0/1',
          paymentStatus: isSampleTest ? 'lbl_not_required' : 'lbl_required',
          studentsPaid: session.studentsPaid || 0,
          invigilator: `${session.first_name} ${session.last_name}`,
          classroom_id: session.school_class_id,
          firstName: session.first_name,
          lastName: session.last_name,
          description: '',
          slug:session.slug,
          classCode: session.name,
          students: session.num_students,
          startTime: this.getDate(session.date_time_start),
          startTimeUTC: session.date_time_start,
          endTime: session.date_time_end != null ? this.getDate(session.date_time_end) : '',
          closed_on: this.getDate(session.closed_on),
          isclosed: (session.is_closed == 1) ? true: false,
          status: this.returnSessionStatus(session) ? 'active' : 'pending',
          submissions: this.getSubmissionStatus(this.attempts.list, session), // twtdar_order = 0
          submissions_1: this.getSubmissionStatus(this.attempts.list, session, 1), // twtdar_order = 1
          //submissions: 0,
          isConfirmed: coinFlip(),
          session_a: subSessionRecord_A ? subSessionRecord_A : null,
          session_b: subSessionRecord_B ? subSessionRecord_B : null
        }
      })) 
    }
    this.teacherClassrooms = initMappedList(classesData.map(classroom => {
      classroom.scheduledAssessments.forEach(session => this.processSessionEntry(session));
      classroom.openAssessments.forEach(session => this.processSessionEntry(session));
      classroom.recentAssessments.forEach(session => this.processSessionEntry(session));
      const is_invigilating = res.classes_teachers.find( record => record.group_id == classroom.group_id && record.role_type == 'schl_teacher_invig' ) != null
      return {
        id: classroom.id,
        name: classroom.name,
        classCode: hyphenateAccessCode(classroom.access_code),
        owner: '',
        isAssigned: !(classroom.is_grouping == 1),
        group_id: classroom.group_id,
        curricShort: classroom.group_type || 'EQAO_G9',
        // curricShort: `${this.lang.tra('txt_g9_math')} (${classroom.courses})`,
        enableClassListingByCC: true,
        currentStudents: initMappedList(classroom.students.map(student => {
          this.processNewStudentAccount(student)
          return {
            ...student,
            uid: student.id, 
            displayName: [student.first_name, student.last_name].join(' ')
          }
        })),
        // currentTeachers: [],
        currentTeachers: classroom.teacher ? classroom.teacher : [],
        openAssessments: classroom.openAssessments,
        recentAssessments: classroom.recentAssessments,
        scheduledAssessments: classroom.scheduledAssessments,
        timeCreated: '--',
        timeLastTouched: '--',
        is_invigilating,
        is_fi: classroom.is_fi,
        allow_ISR: classroom.allow_ISR || 0
      }
    }));

    // this.schoolData = res.school[0];
    this.schoolDistrict = {
      foreign_id: this.schoolData.sd_foreign_id
      //foreign_id:res.schl_dist[0].foreign_id
    };
    this.schoolDist = res.schl_dist; 
    this.classrooms = [];
    for (let classroom of classesData) {
      this.classrooms.push(this.apiToClientPayloadClass(classroom));
    }

    this.guestClasses = [];
    for (let classroom of guestClassesData) {
      //this.guestClasses.push(this.apiToClientPayloadClass(classroom));
      classroom.access_code = hyphenateAccessCode(classroom.access_code)
      this.guestClasses.push(classroom);
    }

    this.guestClassesInvigilate = guestClassesInvigilateData;

    //create alternative Version Format list
    alt_version_format_values.forEach( formatValue => {
      let altVersionFormat = this.altVersionFormats[formatValue.format_name]
      if(!altVersionFormat){
        altVersionFormat = {
          form_id: formatValue.form_id,
          format_name: formatValue.format_name,
          values:[],
        }
        this.altVersionFormats[formatValue.format_name] = altVersionFormat
      }
      let altVersionFormatValues = this.altVersionFormats[formatValue.format_name].values.find(value => +value.id === +formatValue.format_value_id)
      if(!altVersionFormatValues){
        altVersionFormat.values.push(
          {
            id: ''+formatValue.format_value_id,
            label: ''+formatValue.format_value_label,
            format_value: ''+formatValue.format_value,
            format_value_order: ''+formatValue.format_value_order,
            is_g9: formatValue.is_g9,
            is_primary: formatValue.is_primary,
            is_junior: formatValue.is_junior,
            is_osslt_en: formatValue.is_osslt_en,
            is_osslt_fr: formatValue.is_osslt_fr,
          }
        )
      }
    })

    //sort altVersionFormats Value by orders and create value_list
    Object.values(this.altVersionFormats).forEach((altVersionFormat:any) => {
      altVersionFormat.values.sort((a,b) =>{
        return (+a.format_value_order)-(+b.format_value_order)
      })
      altVersionFormat.value_list = initMappedList(altVersionFormat.values)
    })


    this.schoolAdminStudents.list.forEach(student => {
      //attatch conflict info
      const SDC_conflict_g3 =  SDC_conflicts_g3.find(conflict => conflict.uid == student.id)
      if(SDC_conflict_g3 != undefined && SDC_conflict_g3.compare_result!='[]'){
        student.SDC_conflict_g3 = SDC_conflict_g3
      } 
      const SDC_conflict_g6 =  SDC_conflicts_g6.find(conflict => conflict.uid == student.id)
      if(SDC_conflict_g6 != undefined && SDC_conflict_g6.compare_result!='[]'){
        student.SDC_conflict_g9 = SDC_conflict_g6
      } 
      const SDC_conflict_g9 =  SDC_conflicts_g9.find(conflict => conflict.uid == student.id)
      if(SDC_conflict_g9 != undefined && SDC_conflict_g9.compare_result!='[]'){
        student.SDC_conflict_g9 = SDC_conflict_g9
      } 
      const SDC_conflict_g10 =  SDC_conflicts_g10.find(conflict => conflict.uid == student.id)
      if(SDC_conflict_g10 != undefined && SDC_conflict_g10.compare_result!='[]'){
        student.SDC_conflict_g10 = SDC_conflict_g10
      }
      this.processNewStudentAccount(student)
      
      //attatch loaded_created_time
      // const student_created_loaded_time = students_created_loaded_time.find(record => record.uid == student.id)
      // if(student_created_loaded_time != undefined){
      //   const created_time = student_created_loaded_time.school_role_created_on
      //   const loaded_time = student_created_loaded_time.user_metas_import_created_date
      //   student.created_loaded_time = created_time
      //   if(loaded_time > created_time){
      //     student.created_loaded_time = loaded_time
      //   }
      // }
    });

    this.testWindows = res.test_windows;
    if(res.sessions && res.sessions.school_sessions){
      this.schoolSessions = res.sessions.school_sessions
    }  
  }

  setAltVersionRequires(altVersionRequest ){
    //convert all values in student_tw_alt_version_information into string type
    Object.keys(altVersionRequest).forEach(key => {
      if(altVersionRequest[key]){
        altVersionRequest[key] = ''+altVersionRequest[key] 
      }else{
        altVersionRequest[key] = ''
      }
    })

    //set altVersionRequest.alt_version_require
    if( +altVersionRequest.braille_format        !== 0 ||
        +altVersionRequest.pdf_format            !== 0 ||
        +altVersionRequest.asl_format            !== 0 ||
        +altVersionRequest.audio_delivery_format !== 0 ||
        +altVersionRequest.audio_format          !== 0){
          altVersionRequest.alt_version_require = '1'
    } else{
      altVersionRequest.alt_version_require =  undefined
    }

    //set altVersionRequest.alt_version_braille_require
    if( +altVersionRequest.braille_format !== 0){
      altVersionRequest.alt_version_braille_require = '1'
    }else if(+altVersionRequest.audio_format !== 0 || +altVersionRequest.audio_delivery_format !== 0 || +altVersionRequest.asl_format !== 0){
      altVersionRequest.alt_version_braille_require = '#'
    }else{
      altVersionRequest.alt_version_braille_require = undefined
    }

      //set altVersionRequest.alt_version_asl_require
    if( +altVersionRequest.asl_format !== 0){
          altVersionRequest.alt_version_asl_require = '1'
    }else{
      altVersionRequest.alt_version_asl_require = "#"
    }

    //set altVersionRequest.alt_version_pdf_require
    if( +altVersionRequest.braille_format === 0 && 
        +altVersionRequest.pdf_format !== 0){
          altVersionRequest.alt_version_pdf_require = '1'
    }else if(+altVersionRequest.audio_format !== 0 || +altVersionRequest.audio_delivery_format !== 0){
      altVersionRequest.alt_version_pdf_require = '#'
    }else{
      altVersionRequest.alt_version_pdf_require = undefined
    }

    //set saltVersionRequest.alt_version_online_require
    if( +altVersionRequest.braille_format === 0 && 
        +altVersionRequest.pdf_format === 0 && 
        +altVersionRequest.audio_delivery_format !== 0){
          altVersionRequest.alt_version_online_require = '1'
    }else{
      altVersionRequest.alt_version_online_require = undefined
    }
  }

  processNewStudentAccount(student: IStudentAccount) {
    student['_g10_NonParticipationStatus_deferred'] = "#"
    student['_g10_NonParticipationStatus_exempted'] = "#"
    student['_g10_NonParticipationStatus_osslc'] = "#"
    student['_g10_NonParticipationStatus_osslc_spring'] = "#"
    if(student['_g10_NonParticipationStatus'] == '1'){
      student['_g10_NonParticipationStatus_exempted'] = '1';
    }
    if(student['_g10_NonParticipationStatus'] == '2'){
      student['_g10_NonParticipationStatus_deferred'] = '1';
    }
    if(student['_g10_NonParticipationStatus'] == '3'){
      student['_g10_NonParticipationStatus_osslc'] ='1'; 
    }
    if(student['_g10_NonParticipationStatus'] == '4'){
      student['_g10_NonParticipationStatus_osslc_spring'] ='1'; 
    }

    const prefixs = ['_g3_','_g6_','','_g10_']
    const afixs = ['_pj_reading', '_pj_writing', '_pj_mathematics']
    prefixs.forEach(prefix => {
      afixs.forEach(afixs =>{
        student[prefix+'eqao_acc_assistive_tech_1'+afixs+'_chrome'] = '#';
        student[prefix+'eqao_acc_assistive_tech_1'+afixs+'_other'] = '#';
        if(student[prefix+'eqao_acc_assistive_tech'+afixs] == '2'){
          student[prefix+'eqao_acc_assistive_tech_1'+afixs+'_chrome'] = '1';
          student[prefix+'eqao_acc_assistive_tech_1'+afixs+'_other'] = '#';
        }
        if(student[prefix+'eqao_acc_assistive_tech'+afixs] == '1'){
          student[prefix+'eqao_acc_assistive_tech_1'+afixs+'_chrome'] = '#';
          student[prefix+'eqao_acc_assistive_tech_1'+afixs+'_other'] = '1';
        }
      })
    })
    student['_g10_eqao_acc_assistive_tech_1_chrome'] = '#';
    student['_g10_eqao_acc_assistive_tech_1_other'] = '#';
    if(student['_g10_eqao_acc_assistive_tech'] == '2'){
      student['_g10_eqao_acc_assistive_tech_1_chrome'] = '1';
      student['_g10_eqao_acc_assistive_tech_1_other'] = '#';
    }
    if(student['_g10_eqao_acc_assistive_tech'] == '1'){
      student['_g10_eqao_acc_assistive_tech_1_chrome'] = '#';
      student['_g10_eqao_acc_assistive_tech_1_other'] = '1';
    }
    student['eqao_acc_assistive_tech_1_chrome'] = '#';
    student['eqao_acc_assistive_tech_1_other'] = '#';
    if(student['eqao_acc_assistive_tech'] == '2'){
      student['eqao_acc_assistive_tech_1_chrome'] = '1';
      student['eqao_acc_assistive_tech_1_other'] = '#';
    }
    if(student['eqao_acc_assistive_tech'] == '1'){
      student['eqao_acc_assistive_tech_1_chrome'] = '#';
      student['eqao_acc_assistive_tech_1_other'] = '1';
    }  
  }



  public processSessionEntry(session: any) {
    const mStart = moment.tz(session.date_time_start, moment.tz.guess());
    session.timeDirectStart = mStart.format(this.lang.tra('datefmt_day_month'));
    session.dateTimeStartLong = mStart.format(this.lang.tra('datefmt_sentence'));
    session.hourDirectStart = mStart.format(this.lang.tra('timefmt_hour_time'));
    if(session.closed_on) {
      const mClosed = moment.tz(session.closed_on, moment.tz.guess());
      session.timeDirectClose = mClosed.format(this.lang.tra('datefmt_day_month'));
    }

    session.name = this.lang.tra(G9_SLUG_TO_CAPTION[session.slug] || 'New Assessment');
    return session;
  }

  getAPITargetMapping(sourceMapping: string): string {
    let targetMapping = '';
    let mapping = this.studentMappings.find(mapping => mapping.source === sourceMapping);
    if (mapping) {
      targetMapping = mapping.target;
    }
    return targetMapping;
  }

  generateStudentInvigilationRecord(studentAccount) {
    // console.log(JSON.stringify(studentAccount))
    // studentAccount.status = {};
    return {
      studentAccount,
      session_a: { // this should be an array
        is_session_active: true,
      },
    }
  }
  getSubmissionStatus(testAttempts, session, twtdar_order = 0) {

    const attempt = testAttempts.find(attempt => attempt.submitted_test_session_id === session.test_session_id && attempt.twtdar_order === twtdar_order);
    if(!attempt){
      return '0';
    }
    return attempt.submissions;

    /* const states = session_states.find(state => { return state.test_session_id === session.test_session_id }).states.studentStates;
    for (const [key, value] of Object.entries(states)) {
      for (const [innerKey, innerValue] of Object.entries(value)) {
        if (innerKey === 'submitted_test_session_id' && innerValue === session.test_session_id) {
          submissions.push(key)
        }
      }
    } */
  }
  getDate(dateTime) {
    const mStart = moment.tz(dateTime, moment.tz.guess());
    let formatted = mStart.format(this.lang.tra('datefmt_sentence'));
    formatted = (formatted === 'Invalid date') ? this.lang.tra('sa_invalid_date') : formatted;
    return formatted;
  }
  returnSessionStatus(session) {
    let currDate = new Date();
    let sessionDate = new Date(session.date_time_start);
    // console.log(session);
    // console.log(session.slug);
    if (currDate.getTime() > sessionDate.getTime()) {
      this.activeSessionsCount++;
      if (session.slug.toUpperCase().indexOf("PRIMARY") > -1)    this.primaryActiveSessionsCount++;
      if (session.slug.toUpperCase().indexOf("JUNIOR") > -1)    this.juniorActiveSessionsCount++;
      if (session.slug.toUpperCase().indexOf("G9") > -1)    this.g9ActiveSessionsCount++;
      if (session.slug.toUpperCase().indexOf("OSSLT") > -1) this.ossltActiveSessionsCount++;
      return true;
    }
    this.scheduledSessionsCount++;
    if (session.slug.toUpperCase().indexOf("PRIMARY") > -1)    this.primaryScheduledSessionsCount++;
    if (session.slug.toUpperCase().indexOf("JUNIOR") > -1)    this.juniorScheduledSessionsCount++;
    if (session.slug.toUpperCase().indexOf("G9") > -1)    this.g9ScheduledSessionsCount++;
    if (session.slug.toUpperCase().indexOf("OSSLT") > -1) this.ossltScheduledSessionsCount++;
    return false;
  }
  public timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rhours > 0) {
      return rhours + "hr " + rminutes + "min";
    }
    return rminutes + "min";
  }

  getStudentsByClassroomId(classroomId: string): IMappedList<IStudentAccount> {
    const classroom = this.teacherClassrooms.map[classroomId]
    if (classroom) {
      return classroom.currentStudents;
    }
  }

  getSessionByClassroomId(classroomId: string, sessionId: string) {
    const classroom = this.teacherClassrooms.map[classroomId]
    if (classroom && classroom.openAssessments) {
      for (let i = 0; i < classroom.openAssessments.length; i++) {
        const session = classroom.openAssessments[i];
        if (('' + session.test_session_id) === ('' + sessionId)) {
          return session;
        }
      }
      for (let i = 0; i < classroom.scheduledAssessments.length; i++) {
        const session = classroom.scheduledAssessments[i];
        if (('' + session.test_session_id) === ('' + sessionId)) {
          return session;
        }
      }
    }

    return {};
  }

  getAssessmentsByClassroomId(classroomId: string) {
    const sessions = this.schoolSessions
    const targetSession = sessions.filter( (session:any) => session.school_class_id == classroomId)
    return targetSession;
  }

  getOngoingSessionsByClassroomId(classroomId: string) {
    const classSessions = this.getAssessmentsByClassroomId(classroomId)
    const onGoingClassSessions = classSessions.filter( (session:any) => session.is_closed !== 1)
    return onGoingClassSessions
  }

  getClassroomNameById(classroomId: string) {
    const classroom = this.teacherClassrooms.map[classroomId]
    if (classroom) {
      return classroom.name;
    }
    return '';
  }
  getClassroomById(classroomId: string){
    const classroom = this.teacherClassrooms.map[classroomId]
    if (classroom) {
      return classroom;
    }
    return '';
  }

  apiToClientPayloadClass(fromAPI: any): IClassroom {
    let newPayload: any = {};

    this.classroomMappings.forEach((mapping: { source: string, target: string }) => {
      let val = _.get(fromAPI, mapping.source);
      newPayload[mapping.target] = val;
    });

    newPayload.course_type = newPayload.course_type || 'EQAO_G9';
    newPayload.is_grouping = +(newPayload.is_grouping || 0);
    
    const courseId = fromAPI.course_id;
    const course = STUDENT_G9_COURSES.map[courseId];
    if (course) {
      const isInPerson = course.inPerson;
      const isRemote = course.remote;
      const courseType = course.course;
      const simpleCourseId = STUDENT_G9_COURSES_SIMPLE.list.filter(item => item.course === courseType)[0].id;
      newPayload.course = simpleCourseId;
      newPayload.remote_courses_sdc_student_rem_inp_1 = isInPerson;
      newPayload.remote_courses_sdc_student_rem_inp_2 = isRemote;
    }
    newPayload.openAssessments = []; //Here and below is still unchanged from the sample data
    newPayload.recentAssessments = [];
    newPayload.onboarding = 0;
    newPayload.assessment = 0;
    return newPayload;
  }

  //Use this when saving the data back to the API
  clientToApiPayloadClass(fromClient: IClassroom): any {
    let newPayload: any = {};

    this.classroomMappings.forEach((mapping: { source: string, target: string }) => {
      _.set(newPayload, mapping.source, fromClient[mapping.target]);
    });

    const simpleCourse = STUDENT_G9_COURSES_SIMPLE.map[fromClient.course];
    const courseType: G9_COURSES = simpleCourse.course;
    const complexCourse = STUDENT_G9_COURSES.list.filter((course) => {
      return course.course === courseType &&
        course.inPerson === fromClient.remote_courses_sdc_student_rem_inp_1 &&
        course.remote === fromClient.remote_courses_sdc_student_rem_inp_2;
    })[0];
    newPayload.course_id = complexCourse.id;

    return newPayload;
  }

  getNamespacePropPrefix(key_namespace: string) {
    switch(key_namespace) {
      case 'eqao_sdc_g3':
        return '_g3_';
      case 'eqao_sdc_g6':
        return '_g6_';
      case 'eqao_sdc_g10':
        return '_g10_';
      case 'eqao_sdc':
      default:
        return '';
    }
  }

  key_namespaces = ['eqao_sdc', 'eqao_sdc_g3', 'eqao_sdc_g6', 'eqao_sdc_g10'];

  apiToClientPayloadStudent(fromAPI: any): IStudentAccount {
    let newPayload: IStudentAccount = { id: fromAPI.id, first_name: fromAPI.first_name, last_name: fromAPI.last_name}; //id is only here because it is required for IStudentAccount (not sure why), but we only use uid
    this.studentMappings.forEach((mapping: { source: string, target: string, key_namespace:string}) => {
      if(mapping.source == 'StudentOEN'){ //temp solution
        for(const key_namespace of this.key_namespaces) {
          let val = _.get(fromAPI[key_namespace], mapping.source);
          if(val) {
            newPayload[mapping.target] = val;
            break;
          }
        }
        return;
      }
      let val = _.get(fromAPI[mapping.key_namespace], mapping.source);

      const prefix = this.getNamespacePropPrefix(mapping.key_namespace);
      newPayload[prefix+mapping.target] = val;
    });
    newPayload.uid = fromAPI.id;
    newPayload.first_name = fromAPI.first_name;
    newPayload.last_name = fromAPI.last_name;
    newPayload.exemption_override_applied = fromAPI.exemption_override_applied;
    newPayload.twes_is_pended = fromAPI.twes_is_pended;
    if (!newPayload['eqao_is_g3']&&!newPayload['eqao_is_g6']&&!newPayload['eqao_is_g9'] && !newPayload['eqao_is_g10']){
      newPayload['eqao_is_g3'] = '1'
    }
    newPayload['test_sessions'] = [];
    newPayload.status = { isOnline: false, assessments: {} };
    return newPayload;
  }

  clientToApiPayloadStudent(fromClient: Partial<IStudentAccount>): any {
    let newPayload: any = { id: 0, first_name: "", last_name: "", roles: [] };

    this.studentMappings.forEach((mapping: { source: string, target: string }) => {
      _.set(newPayload, mapping.source, fromClient[mapping.target]);
    });

    return newPayload;
  }

  clientToApiPayloadOSSLTStudent(fromClient: Partial<IStudentAccount>): any {
    let newPayload: any = { id: 0, first_name: "", last_name: "", roles: [] };

    this.studentMappings.forEach((mapping: { source: string, target: string, key_namespace:string}) => {
      if(overlapVariables.indexOf(mapping.source) > -1){
        _.set(newPayload, mapping.source, fromClient[mapping.target]);
      }else{
        if(mapping.key_namespace == 'eqao_sdc_g10'){
          _.set(newPayload, mapping.source, fromClient["_g10_"+mapping.target]);
        }  
      }  
    });

    return newPayload;
  }

  getKeyNamespace(curricShort: AssessmentCode) {
    switch(curricShort) {
      case AssessmentCode.EQAO_G3:
        return 'eqao_sdc_g3'
      case AssessmentCode.EQAO_G6:
        return 'eqao_sdc_g6'
      case AssessmentCode.EQAO_G9:
        return 'eqao_sdc'
      case AssessmentCode.EQAO_G10:
        return 'eqao_sdc_g10'
    }
    return 'eqao_sdc';
  }

  getPropName(prop: string, curricShort: AssessmentCode) {
    if(overlapVariables.includes(prop)) {
      return prop;
    }

    return `${this.getNamespacePropPrefix(this.getKeyNamespace(curricShort))}${prop}`;
  }

  getPropVal(student:IStudentAccount, prop: string, curricShort: AssessmentCode) {
    return student[this.getPropName(prop, curricShort)];
  }

  classFilterToCurricShort(classFilter: ClassFilterId) {
    switch(classFilter) {
      case ClassFilterId.G9:
        return AssessmentCode.EQAO_G9;
      case ClassFilterId.OSSLT:
        return AssessmentCode.EQAO_G10;
      case ClassFilterId.Junior:
        return AssessmentCode.EQAO_G6;
      case ClassFilterId.Primary:
        return AssessmentCode.EQAO_G3;
    }
  }

  asmtSlugToCurricShort(asmtSlug: ASSESSMENT) {
    if(asmtSlug?.includes('PRIMARY')) {
      return AssessmentCode.EQAO_G3;
    } else if(asmtSlug?.includes('JUNIOR')) {
      return AssessmentCode.EQAO_G6;
    } else if(asmtSlug?.includes('OSSLT')) {
      return AssessmentCode.EQAO_G10;
    } else if(asmtSlug?.includes('G9')){
      return AssessmentCode.EQAO_G9
    }
  } 

  // generateStudents(len=30){
  //   const mappedList = initMappedList(generateEntries(len, (i) => {
  //     const lang = this.lang.getCurrentLanguage();
  //     const random = genericIdentity(this.lang.tra('sample_student_last_name'), lang);
  //     const courses = STUDENT_G9_COURSES_SIMPLE.list
  //     const classroom = randArrEntry([
  //       {classCode:'MFM1P - AP - 3', course:courses[0].id},
  //       {classCode:'MPM1D - AC - 1', course:courses[1].id},
  //       {classCode:'MPM1D - AC - 2', course:courses[1].id},
  //     ])
  //     return {
  //       id: randInt(1000,9999),
  //       uid: randId(),
  //       email: random.email,
  //       group_id: 0, //randInt(1000,9999),
  //       first_name: random.firstName,
  //       last_name: random.lastName,
  //       displayName: random.name,
  //       eqao_student_gov_id: String(randInt(Math.pow(10, 8), Math.pow(10, 9) - 1)),
  //       test_sessions: [],
  //       lang: randArrEntry(['EN', 'FR']),
  //       eqao_g9_course: <string> classroom.course,
  //       classCode: coinFlip(0.9) ? classroom.classCode : undefined,
  //     }
  //   })) ;
  //  mappedList.list.sort((a, b) => {
  //     const _a = a;
  //     const _b = b;
  //     if (_a.last_name > _b.last_name){ return 1 }
  //     if (_a.last_name < _b.last_name){ return -1 }
  //     if (_a.first_name > _b.first_name){ return 1 }
  //     if (_a.first_name < _b.first_name){ return -1 }
  //     else{ return 0 }
  //   })
  //   return mappedList;
  // }

  getguestClasssByHostClassroomGroupId(classroomGroupId: string) {
    return this.guestClasses.filter( (gclass:any) => gclass.invig_sc_group_id == classroomGroupId)
  }

  getGuestClassInvigilateByHostClassroomGroupId(classroomGroupId: string) {
    return this.guestClassesInvigilate.filter( (gci:any) => gci.group_id == classroomGroupId);
  }

  addGuessClass(newGuessClass){
    this.guestClasses.splice(0,0,newGuessClass)
  }

  addGuessClassInvigilate(newGuessInvigilate){
    this.guestClassesInvigilate.splice(0,0,newGuessInvigilate)
  }

  removeGuessClass(guessClassId){
    const targetClass = this.guestClasses.find(gc => gc.scg_id == guessClassId )
    const targetClassIndex = this.guestClasses.indexOf(targetClass)
    if(targetClassIndex != -1){
      this.guestClasses.splice(targetClassIndex,1)
    }  
    const targetInvigilate = this.guestClassesInvigilate.find(gci => gci.scg_id == guessClassId )
    const targetInvigilateIndex = this.guestClassesInvigilate.indexOf(targetInvigilate)
    if(targetInvigilateIndex != -1){
      this.guestClassesInvigilate.splice(targetInvigilateIndex,1)
    } 
  }

  isCurrentSchoolSandbox(){
    if (this.schoolData){
      return +this.schoolData.is_sandbox === 1
    }
  }

  getAdditionalInvigilatorByGroupId(groupId: string){
    return this.invigilators.filter(invig =>  +invig.group_id == +groupId)
  }
  
  setIsFromSchoolAdmin(value){
    this.isFromSchoolAdmin = value
  }

  getIsFromSchoolAdmin(){
    return this.isFromSchoolAdmin
  }

  getIsUnsubmitting(){
    return this.isUnSubmitting
  }

  setIsUnsubmitting(value){
    this.isUnSubmitting = value
  }

  removeStudentFromClassroom(student, classroomId) {
    this.teacherClassrooms.map[classroomId].currentStudents.list = this.teacherClassrooms.map[classroomId].currentStudents.list.filter((s) => {
      console.log(`s.id: ${s.id} student.id: ${student.id}`)
      return s.id !== student.id
    });
  }

  getLinearAssignDate(uid:any, classId:any){

    const classSessions = this.teacherClassrooms.map[classId].openAssessments
    const testSessionIds:any[] = classSessions.map( (cs:any) => {return cs.test_session_id });

    let returnValue = ''
    //The API does not return studentAttemptInfo if there are no class sessions, handle error
    if (!this.studentAttemptInfo) return returnValue

    const theStudentAttemptInfo = this.studentAttemptInfo.filter(studentAttemptInfoRecord => 
        +studentAttemptInfoRecord.uid === +uid 
     && testSessionIds.includes(+studentAttemptInfoRecord.test_session_id)
     && (  studentAttemptInfoRecord.twtdar_user_metas_filter.includes('"eqao_dyn.Linear": ["1"]')
         ||studentAttemptInfoRecord.twtdar_user_metas_filter.includes('"eqao_dyn.Linear": ["2"]')
         ||studentAttemptInfoRecord.twtdar_user_metas_filter.includes('"eqao_dyn.Linear": ["1","2"]')
        )// use "eqao_dyn.Linear": ["1"]  or ["2"]  or ["1","2"] in twtdar.user_metas_filter to determine if the attempt has linear test form.
    )     
    theStudentAttemptInfo.forEach((sai:any) => {
      const  ta_created_on_moment = moment.tz(sai.ta_created_on, moment.tz.guess());
      const timezone = ta_created_on_moment.zoneAbbr();
      if(returnValue.length > 0){
        returnValue += '\n'
      }
      returnValue += `${ta_created_on_moment.format('YYYY-MM-DD kk:mm')} ${timezone}`
    })
    return returnValue
  }

  filterSessionTestWindow(session: ISession, currentTestWindowId: number): boolean {
    //const semester = this.g9DemoData.semesters.list.find(sm => sm.id === +theClass.semester);
    return this.filterClassroomTestWindow(session.classroom_id, currentTestWindowId);
  }

  filterClassroomTestWindow(classroomId: number | string, currentTestWindowId: number): boolean {
    const classroom = this.classrooms.find(cr => cr.id === +classroomId)
    if(!classroom) {
      return false;
    }
    
    const semester = this.semesters.map[+classroom.semester]
    if(semester){
      return currentTestWindowId == semester.testWindowId
    }
    return false;
  }

  getStudentAltVersionRequestName(test_window, withPrefix = null){

    if (withPrefix){
      if (withPrefix == ClassFilterId.OSSLT){
        return "_g10_alt_version_req_"+test_window.id
      } else if (withPrefix == ClassFilterId.Primary){
        return "_g3_alt_version_req_"+test_window.id
      } else if (withPrefix == ClassFilterId.Junior){
        return "_g6_alt_version_req_"+test_window.id
      }
    }
    
    return "alt_version_req_"+test_window.id
  }
  
  getStudentScanResponseRequireName(test_window){
    return "scan_response_require_"+test_window.id
  }

  twHaveScanResponseRequire(test_window){
    const haveScanResponseRequire = this.scan_response_requires.find(srr => +srr.test_window_id  === +test_window.id)
    return !!haveScanResponseRequire
  }
  
  showReportToBoard(testWindowId) {
    const testWindow = this.testWindows.find(tw => tw.id === testWindowId);
    if(testWindow && +testWindow.show_report_to_Board === 1) {
        return true;
    }
    return false;
  }

  getTestWindowFromClassroom(classroom){
    const semester = this.semesters.list.find( sm => sm.id == +classroom.semester)
    if(semester){
      const test_window = this.testWindows.find( tw => tw.id == semester.testWindowId)
      return test_window
    }
    return null;
  }

  /**
   * Generates list of alternative formats to be displayed
   * (Used in the school admin alt request confirmation modal, and teacher modals)
   * @param formatValues  String format values
   * @returns A list of strings for the requested alt formats
   */
  getAltRequestFormatList(formatValues: {braille_format_value:string, pdf_format_value:string, audio_delivery_format_value:string, audio_format_value:string, asl_format_value:string, alt_version_online_require:string}){
    const {braille_format_value, pdf_format_value, audio_delivery_format_value, audio_format_value, asl_format_value, alt_version_online_require} = formatValues;
    const formatList = []
    // Add name of braille format to list 
    if(+braille_format_value > 0){
      const braille_format = this.altVersionFormats["braille_format"].values.find(value => +value.id === +braille_format_value)
      if(braille_format) formatList.push(this.lang.tra(braille_format.label))
    }
    // Add name of ASL format to list
    if(+asl_format_value > 0){
      const asl_format = this.altVersionFormats["asl_format"].values.find(value => +value.id === +asl_format_value)
      if(asl_format) formatList.push(this.lang.tra(asl_format.label))
    }
    // Add name of PDF format to list 
    if(+pdf_format_value > 0){
      const pdf_format = this.altVersionFormats["pdf_format"].values.find(value => +value.id === +pdf_format_value)
      if(pdf_format) formatList.push(this.lang.tra(pdf_format.label))
    }
    // Add audio delivery to list (E-Assessment System, or External MP3 Audio Files)
    if (+audio_delivery_format_value > 0) {
      const audio_delivery_format = this.altVersionFormats["audio_delivery_format"].values.find(value => +value.id === +audio_delivery_format_value)

      // Special case: The same alternative format outcome can be referred to as "e-assessment system (built-in text-to-speech)", or "Online format only" - it means the same thing
      // It should match what they saw and answered in the form
      // If  "Do you require the online format only of the alternative version?" was asked and they choose yes (alt_version_online_require == 1), it should show as "Online format only"
      const audio_delivery_format_slug = (+alt_version_online_require == 1) ? "sdc_audio_delivery_format_assessment_online_only" : audio_delivery_format.label
      let audio_request_final_string = this.lang.tra(audio_delivery_format_slug);

      // Add audio format to list (With Full Description, or Without Full Description) - this is only in math, so question not asked in OSSLT
      if (+audio_format_value > 0) {
        const audio_format = this.altVersionFormats["audio_format"].values.find(value => +value.id === +audio_format_value);
        audio_request_final_string += (" "+this.lang.tra(audio_format.label));
      }
      
      formatList.push(audio_request_final_string)
    }
    return formatList
  }

  /**
   * Convert test window date-times to strings in specific time zone, formats and language abbreviations
   * @param {string | Date} startDate  Test window start date
   * @param {string | Date} endDate  Test window start date
   * @param {string} lang  Language setting
   * @param {string} startFormat  Date String conversion format
   * @param {string} endFormat  Date String conversion format
   * @param {string} timezone  Date conversion timezone
   * @returns { windowStart: string, windowEnd: string }
   */
  parseWindowStartEndDateTime(startDate:string | Date, endDate:string | Date, startFormat:string, endFormat:string, timezone?: string){
    const timezoneFormat = timezone ? timezone : moment.tz.guess()
    let windowStart = moment.tz(startDate, timezoneFormat).format(this.lang.tra(startFormat));
    let windowEnd = moment.tz(endDate, timezoneFormat).format(this.lang.tra(endFormat));
    if(this.lang.c() === 'fr' && ( this.lang.tra(startFormat).includes('z') || this.lang.tra(endFormat).includes('z') )){ // if tz format has timezone abbreviation e.g (EST)
      windowStart = tzLangAbbrs(windowStart, 'fr')
      windowEnd = tzLangAbbrs(windowEnd, 'fr')
    }
    return { windowStart, windowEnd };
  }

  /**
   * Finds the student paid status in classes_students table for a specific class id
   */
  isStudentPaid(student: IStudentAccount, classroom_id: number) {
    return student?.paidForClass[+classroom_id]?.isPaid === 1;
  }


  /**
   * Finds a class for the student based on the classFilter
   */
  findStudentClass(student: IStudentAccount, classFilter: ClassFilterId): IClassroom | undefined {
    let classcode = classFilter === ClassFilterId.G9 ? student.eqao_g9_class_code:student._g10_eqao_g9_class_code
    switch(classFilter){
      case ClassFilterId.Primary:
        classcode = student._g3_eqao_g9_class_code
      break;
      case ClassFilterId.Junior:
        classcode = student._g6_eqao_g9_class_code
      break;
      case ClassFilterId.G9:
        classcode = student.eqao_g9_class_code
      break;
      case ClassFilterId.OSSLT:
        classcode = student._g10_eqao_g9_class_code
      break;
      default:
        classcode = student._g3_eqao_g9_class_code
      break; 
    }

    return this.classrooms.find(classroom => +classroom.id === +classcode)
  }

  getStudentExemptions(student, curricShort, classroomService: ClassroomsService) {
    return getActivatedExemptions(
      classroomService.genTeacherExemptionProps(curricShort), 
      student, 
      this.g10PropCheckMarkMapping,
      this.whitelabelService,
      curricShort,
      this);
  }
}
