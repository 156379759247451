<div [class.high-contrast]="isHighContrast()">
  <div *ngIf="!isFormatTypeTextLong()">
    <div style="display:inline-block; vertical-align:middle; margin-right:0.2em;" *ngIf="element.prefix">
      {{element.prefix}}
    </div>
    <div style="display:inline-block; vertical-align:middle; ">
      <div *ngIf="isFormatTypeNumber()">
        <input
          type="text" 
          [readonly]="isReadOnly()"
          [class.is-input-read-only]="isReadOnly()"
          [formControl]="numberInput" 
          (blur)="onNumberInputBlur()" 
          (focus)="onNumericInputFocusIn(numberInput)"
          class="input" 
          style="text-align:center"
          [style.border]="inputOutOfRangeFlag ? '1px solid red' : ''"
          [style.width.em]="getWidthNum('mathCharLimit', 'value')">
        <span class="input-range">
          <p *ngIf="element.maxValue !== undefined && element.maxValue !== null"><tra slug="ie_max_value"></tra>: {{element.maxValue}}</p>
          <p *ngIf="element.minValue !== undefined && element.minValue !== null"><tra slug="ie_min_value"></tra>: {{element.minValue}}</p>
        </span>
      </div>
      <div *ngIf="isFormatTypeFraction()">
        <div style="display:inline-block; vertical-align:middle;">
          <div *ngIf="element.isMixedNumber" style="display:inline-block; vertical-align:middle; margin-right:0.2em;">
            <input 
              [readonly]="isReadOnly()"
              [class.is-input-read-only]="isReadOnly()"
              type="text" 
              [formControl]="fractionWholeInput" 
              (focus)="onNumericInputFocusIn(fractionWholeInput)"
              (blur)="onNumericInputFocusOut()"
              [style.width.em]="getWidthNum('', 'fracWholeNumber')"
              class="input input-fraction" >
          </div>
          <div style="display:inline-block; vertical-align:middle;">
            <input 
              [readonly]="isReadOnly()"
              [class.is-input-read-only]="isReadOnly()"
              type="text"
              [formControl]="fractionNumeInput" 
              (focus)="onNumericInputFocusIn(fractionNumeInput)"
              (blur)="onNumericInputFocusOut()"
              [style.width.em]="getFractionWidth()"
              class="input  input-fraction is-small" >
            <div style="margin-bottom:6px; border:1px solid #333;"></div>
            <input 
              [readonly]="isReadOnly()"
              [class.is-input-read-only]="isReadOnly()"
              type="text" 
              [formControl]="fractionDenoInput" 
              (focus)="onNumericInputFocusIn(fractionDenoInput)"
              (blur)="onNumericInputFocusOut()"
              [style.width.em]="getFractionWidth()"
              class="input  input-fraction is-small" >
          </div>
        </div>
      </div>
      <div *ngIf="isFormatTypeTextShort()">
        <input 
          type="text" 
          class="input" 
          [formControl]="textInput" 
          [ngStyle]="{'width.em':element.maxChars*1 + 2}"
          [readonly]="isReadOnly()"
          [class.is-input-read-only]="isReadOnly()"
        />
      </div>
      <div *ngIf="isFormatTypeRatio()">
        <span *ngFor="let listEntry of ratioTerms; let listIndex = index;">
          <span *ngIf="listIndex !== 0" style="margin-left:0.3em; margin-right:0.3em;">:</span>
          <input 
            [readonly]="isReadOnly()"
            [class.is-input-read-only]="isReadOnly()"
            type="text" 
            class="input" 
            [formControl]="listEntry" 
            (focus)="onNumericInputFocusIn(listEntry)"
            (blur)="onNumericInputFocusOut()"
            style="width:40px; vertical-align:middle; text-align:center" >
        </span>
      </div>
      <div *ngIf="isFormatTypeAlgebra()" style="min-width:12em">
        <capture-math 
          [obj]="latexCapture" 
          prop="latexInput"
          [isLocked]="isLocked"
          [isManualKeyboard]="true"
          (onChange)="updateState()"
        ></capture-math>
      </div>
      <div *ngIf="isFormatTypeAlgebraList()">
        <div *ngFor="let algebraVal of algebraListInput; let listIndex = index" style="display: inline-block;">
          <capture-math 
            [obj]="algebraListGiven"
            [isLocked]="isLocked"
            [isManualKeyboard]="true"
            (onChange)="updateState()"
            [latexValue]="algebraListInput[listIndex].value" 
            [control]="algebraVal"
            style="display: inline-block; min-width:12em"
          ></capture-math>
          <div style="display: inline-block; margin: 0 0.2em;">{{algebraSuffixListInput[listIndex].value}}</div>
        </div>
      </div>
      <div *ngIf="isFormatTypeAlgebraGroups()">
        <div *ngFor="let algebraVal of algebraGroupsInput; let listIndex = index" style="display: inline-block;">
          <capture-math 
            [obj]="algebraGroupsGiven"
            [isLocked]="isLocked"
            [isManualKeyboard]="true"
            (onChange)="updateState()"
            [latexValue]="algebraGroupsInput[listIndex].value" 
            [control]="algebraVal"
            style="display: inline-block; min-width:12em"
          ></capture-math>
          <div style="display: inline-block; margin: 0 0.2em;">{{algebraSuffixGroupsInput[listIndex].value}}</div>
        </div>
      </div>
      <div *ngIf="isFormatNumberListTypeForm()">
        <div *ngFor="let numberVal of numberListInput; let listIndex = index" style="display: inline-block;">
          <input 
            [readonly]="isReadOnly()"
            [class.is-input-read-only]="isReadOnly()"
            type="text" 
            [formControl]="numberVal" 
            (blur)="onNumberListInputBlur()" 
            (focus)="onNumericInputFocusIn(numberVal)"
            class="input" 
            style="width:5em; text-align:center; margin:0 0.2em; vertical-align: baseline;" >
          <div style="display: inline-block; margin-right: 0.2em;">{{suffixListInput[listIndex].value}}</div>
        </div>
      </div>
      <div *ngIf="isFormatNumberGroupsTypeForm()">
        <div *ngFor="let numberVal of numberGroupsInput; let listIndex = index" style="display: inline-block;">
          <input 
            [readonly]="isReadOnly()"
            [class.is-input-read-only]="isReadOnly()"
            type="text" 
            [formControl]="numberVal" 
            (blur)="onNumberGroupsInputBlur()" 
            (focus)="onNumericInputFocusIn(numberVal)"
            class="input" 
            style="width:5em; text-align:center; margin:0 0.2em; vertical-align: baseline;" 
            >
          <div style="display: inline-block; margin-right: 0.2em;">{{suffixGroupsInput[listIndex].value}}</div>
        </div>
      </div>
    </div>
    <div style="display:inline-block; vertical-align:middle; margin-left:0.2em;" *ngIf="element.suffix">
      {{element.suffix}}
    </div>
  </div>

  <div *ngIf="isFormatTypeTextLong()" [style.font-size.em]="getInputFontSize()">
    <div *ngIf="isDualPaper()">
      <ng-container *ngIf="!isPaperConfigured">
        <div *ngIf="isPlainTextArea()">
          <textarea 
            #textArea
            class="textarea is-fullwidth" 
            style="width: 24em;"
            [style.font-weight]="'bold'"
            [style.font-size.em]="1"
            [formControl]="textInput"
            [readonly]="isReadOnly()"
            [class.is-input-read-only]="isReadOnly()"
            [spellcheck]="isSpellCheckEnabled()"
            disabled="true"
            [placeholder]="getPlaceholderDefaultPaperText()">
          </textarea>
        </div>
  
        <div *ngIf="!isPlainTextArea()">
          <ckeditor 
            [editor]="Editor_Paper" 
            [config]="ckEditorConfig_Paper" 
            (focus)="!!isInFocus" 
            (blur)="!isInFocus" 
            [disabled]="true"
          ></ckeditor>
          <div>
            <render-audio *ngIf="getTextVoiceURL(element)"
              [url]="getTextVoiceURL(element)" 
              [trigger]="getClickTrigger(element)" 
              [isTriggerDisabled]="!isVoiceoverEnabled()"
            ></render-audio>
          </div>
    
          <div 
            *ngIf="element.defaultText_Paper && !isInFocus" 
            class="default-text-container"
            [style.justify-content] = "element.defaultTextAlignment_Paper || 'center'"
            [class.is-hide-toolbar]="element.isToolbarAutoHide && !isInFocus"
            [class.is-in-fixed-height]="element.isFixedHeight"
          >
            <div #defaultText_Paper class="default-text" [style.color]="element.defaultTextColour_Paper" [innerHTML]="element.defaultText_Paper"></div>
          </div>
        </div>
      </ng-container>

      <div *ngIf="isPaperConfigured" class="paper-container" (mouseenter)="getClickTrigger(this.element.paperVoiceOver).next(true)">
        <div class="paper-text">
          <markdown-inline [input]="element.paperText"></markdown-inline>
        </div>
        <div class="paper-image">
          <render-image [element]="renderPaperImageElement()"></render-image>
        </div>
        <render-audio *ngIf="getButtonVoiceURL(element.paperVoiceOver)"
          [url]="getButtonVoiceURL(element.paperVoiceOver)" 
          [trigger]="getClickTrigger(element.paperVoiceOver)" 
          [isTriggerDisabled]="!isVoiceoverEnabled()"
        ></render-audio>
      </div>
    </div>

    <div *ngIf="!isDualPaper()" (mouseenter)="getClickTrigger(this.element.inputVoiceOver).next(true)">
      <div *ngIf="isPlainTextArea()">
        <textarea 
          #textArea
          class="textarea is-fullwidth" 
          style="width: 24em; min-height: 70vh;"
          [formControl]="textInput"
          [spellcheck]="isSpellCheckEnabled()"
          [style.font-weight]="isTextInputValueEmpty() ? 'bold' : 'normal'"
          [style.font-size.em]="1"
          [readonly]="isReadOnly()"
          [class.is-input-read-only]="isReadOnly()"
          [placeholder]="getPlaceholderDefaultText()">
        </textarea>
      </div>

      <div 
        *ngIf="!isPlainTextArea()"
        style="position:relative;" 
        [class.hide-toolbar-ckeditor]="element.isToolbarAutoHide && !isInFocus"
        [class.fixed-height-ckeditor]="element.isFixedHeight"
      >
        <ckeditor 
          [editor]="Editor" 
          [config]="ckEditorConfig" 
          (blur)="!isInFocus" 
          (ready)="onCkEditorReady($event)" 
          (change)="onCkEditorUpdate($event)"
          [disabled]="isLocked">
        </ckeditor>
        <div 
          *ngIf="isBlankText() && element.defaultText && !isInFocus" 
          class="default-text-container"
          [class.is-hide-toolbar]="element.isToolbarAutoHide && !isInFocus"
          [class.is-in-fixed-height]="element.isFixedHeight"
        >
          <div #defaultText class="default-text" [style.color]="element.defaultTextColour" [innerHTML]="element.defaultText"></div>
        </div>
      </div>

      <render-audio *ngIf="getButtonVoiceURL(element.inputVoiceOver)"
        [url]="getButtonVoiceURL(element.inputVoiceOver)" 
        [trigger]="getClickTrigger(element.inputVoiceOver)" 
        [isTriggerDisabled]="!isVoiceoverEnabled()"
      ></render-audio>
  </div>
  
    <div class="keyboard-container" *ngIf="element.isShowingFrenchKeys && !isPaperImageShown" (mousedown)="$event.preventDefault()">
      <div class="keyboard">
        <div>
          <button class="button is-small character " style="width: 5em;" [class.is-warning]="!isShowingCapitals" [class.is-dark]="isShowingCapitals" (click)="isShowingCapitals=!isShowingCapitals">
            Maj.
          </button>
        </div>
        <ng-container *ngIf="!isShowingCapitals">
          <div *ngFor="let letter of frenchAccent">
            <button class="button is-small character" (click)="addFrenchCharacter(letter, $event.target, $event);">
              {{letter}}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="isShowingCapitals">
          <div *ngFor="let letter of frenchAccentCapital">
            <button class="button is-small character" (click)="addFrenchCharacter(letter, $event.target, $event);">
              {{letter}}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
      <!-- (ready)="onReady($event)"
      -->
    <div>
      <small *ngIf="element.isShowingWordCount && !isPaperImageShown" class="remaining-chars">
        <tra slug="auth_word_count"></tra>{{getWords()}}
      </small>
    </div>
    <div *ngIf="isLimitTypeWord() && (!onlineOrPaper.isPaper || !element.isDual)" [ngSwitch]="!!isCountdownMode()"> 
      <ng-container *ngSwitchCase="true">
        <small *ngIf="this.element.maxWords && getRemainingWords()>=0" class="remaining-chars" [class.is-complete]="getRemainingWords() == 0" >
          <tra slug="open_response_rem_words"></tra>{{getRemainingWords()}}
        </small>
        <small *ngIf="this.element.maxWords && getRemainingWords()<0" class="remaining-chars is-overflow" >
          <tra slug="ques_words_over"></tra> {{-1*getRemainingWords()}}
        </small>
      </ng-container>
      <ng-container  *ngSwitchCase="false">
        <small *ngIf="!element.maxWords && element.isShowingWordCount" class="remaining-chars" [class.is-complete]="getRemainingWords() == 0 && !element.isShowingWordCount" [class.is-overflow]="getWords() > element.maxWords && !element.isShowingWordCount" >
          <tra slug="open_response_words"></tra>{{getWords()}} {{element.maxWords ? '/ '+ element.maxWords : ''}}
        </small>
        <small *ngIf="element.maxWords" class="remaining-chars" [class.is-complete]="getRemainingWords() == 0" [class.is-overflow]="getWords() > element.maxWords" >
          <tra slug="open_response_words"></tra>{{getWords()}} / {{element.maxWords}}
        </small>
      </ng-container>
    </div>
    
    <div *ngIf="element.selectedLimitType == 'char' && (!onlineOrPaper.isPaper || !element.isDual)" [ngSwitch]="!!isCountdownMode()"> 
      <ng-container *ngSwitchCase="true">
        <small *ngIf="this.element.maxChars && getRemainingChars()>=0" class="remaining-chars" [class.is-complete]="getRemainingChars() == 0" >
          <tra slug="open_response_rem_chars"></tra>{{getRemainingChars()}}
        </small>
        <small *ngIf="this.element.maxChars && getRemainingWords()<0" class="remaining-chars is-overflow" >
          <tra slug="ques_chars_over"></tra> {{-1*getRemainingChars()}}
        </small>
      </ng-container>
      <ng-container  *ngSwitchCase="false">
        <small *ngIf="element.maxChars" class="remaining-chars" [class.is-complete]="getRemainingChars() == 0" [class.is-overflow]="getCharArray().length > element.maxChars" >
          <tra slug="open_response_chars"></tra>{{getCharArray().length}} / {{element.maxChars}}
        </small>
      </ng-container>
    </div>

    <div *ngIf="onlineOrPaper.isPaper && element.isDual" style="margin-top:10px;">
      <button class="button is-small is-info has-icon" [class.is-outlined]="!isResponded" (click)="confirmFinish()" (mouseenter)="buttonMouseEnter()">
        <span class="icon"><i class="fa" [class.fa-circle]="!isResponded" [class.fa-check-circle]="isResponded"></i></span>
        <span>{{this.element.buttonText}}</span>
      </button>
      <div>
        <render-audio *ngIf="getButtonVoiceURL(element.buttonVoiceOver)"
          [url]="getButtonVoiceURL(element.buttonVoiceOver)" 
          [trigger]="getClickTrigger(element.buttonVoiceOver)" 
          [isTriggerDisabled]="!isVoiceoverEnabled()"
        ></render-audio>
      </div>
    </div>
  </div>

  <div *ngIf="isFormatTypeForm()">
    <div *ngFor="let element of element.formElements">
      <element-render 
        [contentElement]="element" 
        [isLocked]="isLocked" 
        [questionState]="questionState"
        [questionPubSub]="questionPubSub"
      ></element-render>
    </div>
  </div>

  <div 
    [ngStyle]="{'display':displayNumericKeyboard}" 
    style="align-items:flex-start;"
    class="keyboard-container" 
    *ngIf="element.isShowingNumericKeyboard" 
    (mousedown)="$event.preventDefault()">
    <div class="keyboard">
        <div *ngFor="let digit of keyboardLocal.digits">
          <button class="button is-small character" (click)="addKeyboardCharacter($event, digit);">
            {{digit}}
          </button>
        </div>
        <div *ngIf="element.isShowingNumericKeyboardWithDecimal">
          <button class="button is-small character" (click)="addKeyboardCharacter($event, keyboardLocal.signs.decimal);">
            {{keyboardLocal.signs.decimal}}
          </button>
        </div>
    </div>
  </div>
</div>

