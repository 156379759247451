<div 
  *ngIf="formEntry && !formEntry.isHidden" 
  class="field" 
  [class.is-vertical]="isVertical()"
  [class.is-horizontal]="isHorizontal()" 
  [class.is-vertical-text]="isVertical() && formEntry.isCustomWidth" 
>
  <div class="field-label is-normal" *ngIf="formEntry.label!=''">
    <!-- <label class="label" [class.is-label-entry]="isLabel()"> -->
    <label class="label" [class.is-label-entry]="isLabel()" [class.greyOut]= "formEntry.greyOut" [class.notGreyOut]= "!formEntry.greyOut">  
      <div class= "form-entry-label" style="display: flex;">
        <div class="lable-container" [ngStyle]="formEntryLabelContainerStyle ? formEntryLabelContainerStyle : {}">
          <tra-md [slug]="formEntry.label" [isCondensed]="true"></tra-md>
        </div>
        <div class="asterisk-container">
          <i *ngIf="formEntry.required" class="fa fa-asterisk" aria-hidden="true"></i>
        </div>
      </div>
      <div *ngIf="formEntry.sublabel">
        <tra-md [slug]="formEntry.sublabel"  [isCondensed]="true"></tra-md>
      </div>
    </label>
  </div>
  <div class="field-body" [ngSwitch]="formEntry.type" [class.indentation]="formEntry.indentation">
    <div *ngSwitchCase="SaFormType.TEXT" class="field" [class.indentation]="formEntry.indentation" >
      <p class="control is-expanded ">
        <input [class.disabled]="disabled" [pattern]="formEntry.pattern ? formEntry.pattern : null" class="input is-small"  [formControl]="formEntry.formControlRef" type="text">
      </p>
    </div>
    <div *ngSwitchCase="SaFormType.TEXTAREA" class="field" >
      <p class="control is-expanded ">
        <textarea style="resize: none; height: 15em;" [class.disabled]="disabled" class="input is-small"  [formControl]="formEntry.formControlRef" type="text"></textarea>
      </p>
    </div>
    <div *ngSwitchCase="SaFormType.SELECT" class="field">
      <div class="select is-fullwidth is-small">
        <select [class.disabled]="disabled" [formControl]="formEntry.formControlRef" (change)="showSelected($event.target.value)">
          <option></option>
          <option *ngFor="let option of formEntry.options" [value]="option.id">
            <tra [slug]="option.label"></tra>
          </option>
        </select>
      </div>
    </div>
    <div *ngSwitchCase="SaFormType.RADIO" class="field is-radio-container" [class.is-vertical]="formEntry.isVertical">
      <mat-radio-group [class.disabled]="disabled" [formControl]="formEntry.formControlRef">
        <mat-radio-button 
          *ngFor="let option of formEntry.options" 
          [value]="option.id"
          [disabled]="option.greyOut"
        >
        <tra *ngIf="!formEntry.space" [slug]="option.label"></tra>
        <tra *ngIf="formEntry.space" style ="white-space: normal;" [slug]="option.label"></tra>
        <!-- <tra-md *ngIf="!formEntry.space" [slug]="option.label"></tra-md>
        <tra-md *ngIf="formEntry.space" style ="white-space: normal;" [slug]="option.label"></tra-md> -->
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngSwitchCase="SaFormType.RADIOC" class="field is-radio-container" [class.is-vertical]="formEntry.isVertical">
      <mat-checkbox [class.disabled]="disabled" [formControl]="getSubFormControl(formEntry.valProp, option.tag)" *ngFor="let option of formEntry.options">
        <tra-md [slug]="option.label" [class.radioc-word-wrapping]="formEntry.isWordWrapping" class="is-markdown remove-margin-bottom"></tra-md>
      </mat-checkbox>
    </div>
    <div *ngSwitchCase="SaFormType.CHECK" class="field is-radio-container"  [class.is-vertical]="formEntry.isVertical" [class.indentation]="formEntry.indentation">
      <mat-checkbox [class.disabled]="disabled" [formControl]="getSubFormControl(formEntry.valProp, option.tag)" *ngFor="let option of formEntry.options" >
        <tra [slug]="option.label"></tra>
      </mat-checkbox>
    </div>
    <div *ngSwitchCase="SaFormType.DATE">
      <sa-widget-date-entry  [class.disabled]="disabled || formEntry.greyOut" [fc]="formEntry.formControlRef" [nullDbPlaceholder]="formEntry.nullDbPlaceholder" ></sa-widget-date-entry>
    </div>
  </div>
</div>
