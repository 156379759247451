import { getSubValProp } from "../../sa-widget-form-entry/sa-widget-form-entry.component";
import { STUDENT_ASSIST_TECH_1, STUDENT_ASSIST_TECH_2, STUDENT_ASSIST_TECH_3, STUDENT_IPRC, STUDENT_NonParticipationStatus } from "../constants";
import { IMultiSelect, IStudentAccount } from "../types";
import { IMappedList } from "../util";
import { AssessmentCode, ICheckMarkMapping } from "./eqao-g9";
import {WhitelabelService} from "../../../domain/whitelabel.service";
import { G9DemoDataService, overlapVariables } from "../../g9-demo-data.service";

export const getValProps = (valProp: string, options: IMappedList<IMultiSelect>): string[] => {
    return options.list.map(option => getSubValProp(valProp, option.tag));
}
const eqaoAccommodationProps: string[] = [
    ...getValProps('eqao_acc_assistive_tech_1', STUDENT_ASSIST_TECH_1),
    ...getValProps('eqao_acc_assistive_tech_2', STUDENT_ASSIST_TECH_2),
    ...getValProps('eqao_acc_assistive_tech_3', STUDENT_ASSIST_TECH_3),
    ...getValProps('eqao_acc_assistive_tech_1_pj_reading', STUDENT_ASSIST_TECH_1),
    ...getValProps('eqao_acc_assistive_tech_2_pj_reading', STUDENT_ASSIST_TECH_2),
    ...getValProps('eqao_acc_assistive_tech_3_pj_reading', STUDENT_ASSIST_TECH_3),
    ...getValProps('eqao_acc_assistive_tech_1_pj_writing', STUDENT_ASSIST_TECH_1),
    ...getValProps('eqao_acc_assistive_tech_2_pj_writing', STUDENT_ASSIST_TECH_2),
    ...getValProps('eqao_acc_assistive_tech_3_pj_writing', STUDENT_ASSIST_TECH_3),
    ...getValProps('eqao_acc_assistive_tech_1_pj_mathematics', STUDENT_ASSIST_TECH_1),
    ...getValProps('eqao_acc_assistive_tech_2_pj_mathematics', STUDENT_ASSIST_TECH_2),
    ...getValProps('eqao_acc_assistive_tech_3_pj_mathematics', STUDENT_ASSIST_TECH_3),
    ...getValProps('NonParticipationStatus', STUDENT_NonParticipationStatus),
    'eqao_acc_braille',
    'eqao_acc_braille_pj_reading',
    'eqao_acc_braille_pj_writing',
    'eqao_acc_braille_pj_mathematics',
    'eqao_pres_format',
    "eqao_pres_format_pj_reading",
    "eqao_pres_format_pj_writing",
    "eqao_pres_format_pj_mathematics",
    '_extended_time',
    '_audio_recording_of_resp',
    'eqao_acc_scribing',
    "eqao_acc_scribing_pj_reading",
    "eqao_acc_scribing_pj_writing",
    "eqao_acc_scribing_pj_mathematics",
    'eqao_acc_alt_version',
    'AccAudioVersion',
    'AccAudioVersion_pj_reading',
    'AccAudioVersion_pj_writing',
    'AccAudioVersion_pj_mathematics',
    'AccOther',
    'AccVideotapeResponse',
    '_periodic_breaks',
    'osslt_alternative_version_test',
    'g9_alternative_version_request',
    'eqao_exemption_pj_reading',
    'eqao_exemption_pj_writing',
    'eqao_exemption_pj_mathematics',
];

export const getAccommodationProps = (whitelabel: WhitelabelService, curricShort: AssessmentCode, g9DemoData: G9DemoDataService) => {
    if (whitelabel) {
        const isBCSite = (): boolean => whitelabel.getSiteFlag('BC_STUDENT');
        if (isBCSite()) {
            return []
        } else {
            return eqaoAccommodationProps.map( p => {
                return g9DemoData.getPropName(p, curricShort);
            })
        }       
    } else {
        return [];
    }
}

const isSet = (
    prop: string, 
    account: IStudentAccount, 
    checkMarkMapping: ICheckMarkMapping, 
    whitelabel: WhitelabelService,
    curricShort: AssessmentCode,
    g9DemoData: G9DemoDataService): boolean => 
{
    const accommodationProps = getAccommodationProps(whitelabel, curricShort, g9DemoData); 
    if (accommodationProps.includes(prop)) {
        
        const val = account[prop];
        const checkMarkMap = checkMarkMapping[prop];
        
        if(checkMarkMap) {
            return checkMarkMap[val];
        }

        if(val === '#') {
            return false;
        }

        switch (typeof val) {
        case 'boolean':
            return val;
        case 'number':
            return true;
        case 'string':
            return !!val;
        case 'undefined':
        case null:  
            return false;
        }
    }
    return false;
};
  
export const getActivatedAccommodations = (
    accProps: string[], 
    account: IStudentAccount, 
    checkMarkMapping: ICheckMarkMapping, 
    whitelabel: WhitelabelService,
    curricShort: AssessmentCode,
    g9DemoData: G9DemoDataService) => {    
    return accProps.filter(prop => isSet(prop, account, checkMarkMapping, whitelabel, curricShort, g9DemoData));
}

export const getActivatedAssistiveTechs = (
    accProps: string[], 
    account: IStudentAccount, 
    checkMarkMapping: ICheckMarkMapping, 
    whitelabel: WhitelabelService,
    curricShort: AssessmentCode,
    g9DemoData: G9DemoDataService) => {    
    return accProps.filter(prop => isSet(prop, account, checkMarkMapping, whitelabel, curricShort, g9DemoData));
}

export const getActivatedExemptions = (
    accProps: string[], 
    account: IStudentAccount, 
    checkMarkMapping: ICheckMarkMapping, 
    whitelabel: WhitelabelService,
    curricShort: AssessmentCode,
    g9DemoData: G9DemoDataService) => {    
    return accProps.filter(prop => isSet(prop, account, checkMarkMapping, whitelabel, curricShort, g9DemoData));
}