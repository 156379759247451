// app services
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { getShowCameraText } from '../../../ui-testrunner/element-render-camera/element-render-camera.component';
import { getGroupingInstrSlug } from '../../../ui-testrunner/element-render-grouping/element-render-grouping.component';
import { IContentElementGroup } from '../../../ui-testrunner/element-render-grouping/model';
import { getInsertionInstrSlug } from '../../../ui-testrunner/element-render-insertion/element-render-insertion.component';
import { IContentElementInsertion } from '../../../ui-testrunner/element-render-insertion/model';
import { getDefaultOrderInstrSlug } from '../../../ui-testrunner/element-render-order/element-render-order.component';
import { IContentElementOrder } from '../../../ui-testrunner/element-render-order/model';
import { LangService } from '../../../core/lang.service';
import { ElementType } from "../../../ui-testrunner/models";
import { IContentElement, IQuestionConfig } from '../../../ui-testrunner/models/index';
import { IItemTag } from '../../item-tag/item-tag.component';
import { getElementChildren } from '../models';
import { QuestionView } from '../models/types';
import { ItemBankAuditor } from './audits';
import { ItemSetFrameworkCtrl } from './framework';
import { ItemBankCtrl } from './item-bank';
import { PanelCtrl } from './mscat';
import { FrameworkQuadrantCtrl } from './quadrants';
import { TestletCtrl } from './testlets';
import { ItemBankUtilCtrl } from './util';
import { Destroyable } from './destroyable';
import { AuditTarget } from '../../widget-audits/data/audits';
export class ItemFilterCtrl implements Destroyable {

  filterSettings = {};
  workflowFilterSettings = {};
  activeTestFormFilter;
  activeSignOffFilter;
  numUnfilteredQuestions = 0;
  filteredQuestions: any[];
  filterQuestionIds: any[];
  isItemPropsActive = new FormControl(true);
  isQuestionFiltersActive = new FormControl(true);
  searchQuery = new FormControl();
  isExcludeBlankItemVersions:boolean;

  private util = new ItemBankUtilCtrl();

  public auditCtrl: ItemBankAuditor;

  filteredTags: Observable<IItemTag[]>;
  filteringTags: IItemTag[] = [];

  constructor(
    public frameworkCtrl: ItemSetFrameworkCtrl,
    public itemBankCtrl: ItemBankCtrl,
    public panelCtrl: PanelCtrl,
    public quadrantCtrl: FrameworkQuadrantCtrl,
    public testletCtrl: TestletCtrl,
    private lang: LangService
  ){
    this.filteredTags = this.searchQuery.valueChanges.pipe(
      startWith(''),
      map(value => this._filterTag(value))
    )
  }

  destroy() {

  }

  private _filterTag(value:string): IItemTag[] {
    const filterValue = value.toLowerCase();
    return this.itemBankCtrl.availableTags.filter(option => !this.filteringTags.filter(t=>t.id === option.id).length && option.caption.toLowerCase().includes(filterValue));
  }



  addTag(tag: IItemTag) {
    if(this.filteringTags.filter(t => t.id === tag.id).length) {
      return;
    }

    this.filteringTags.push(tag);
    this.searchQuery.setValue("");//Refreshes the filtered list
    this.updateItemFilter();
  }

  deleteTag(tag: IItemTag) {
    const index = this.filteringTags.findIndex( t => t.id === tag.id); 
    if(index === -1) {
      return;
    }
    this.filteringTags.splice(index, 1);
    this.searchQuery.setValue(this.searchQuery.value);//Refreshes the filtered list
    this.updateItemFilter();
  }

  clearFilter() {
      this.filterSettings = {};
      this.workflowFilterSettings = {};
      if(this.filterQuestionIds) {
        this.filterQuestionIds = undefined;
      }
      if(this.searchQuery) {
        this.searchQuery.setValue('');
      }
      if(this.filteringTags) {
        this.filteringTags = [];
      }
      this.activeTestFormFilter = null;
      this.activeSignOffFilter = null;
      this.updateItemFilter();
      this.isQuestionFiltersActive.setValue(false)
  }


  getDefaultElementTextContent(element: IContentElement): string[] {
    switch(element.elementType) {
      case ElementType.INSERTION: 
        const insertionSlug = getInsertionInstrSlug(<IContentElementInsertion>element);
        if(!insertionSlug) {
          return [];
        } 
        return [this.lang.tra(insertionSlug)];
      case ElementType.ORDER: 
        const orderSlug = getDefaultOrderInstrSlug(<IContentElementOrder>element);
        if(!orderSlug) {
          return [];
        }
        return [this.lang.tra(orderSlug)];
      case ElementType.GROUPING:
        const groupingSlug = getGroupingInstrSlug(<IContentElementGroup>element);
        if(!groupingSlug) {
          return [];
        }
        return [this.lang.tra(groupingSlug)];
      case ElementType.VIDEO:
        return []; //Too complicated for now
      case ElementType.MIC:
        return []; //Too complicated for now
      case ElementType.CAMERA:
        return [getShowCameraText()];
      case ElementType.PASSAGE:
      case ElementType.TEMPLATE: // todo: maybe we can put something in place here
      default: 
        return [];
    }
  }


  getElementTextContent(element: IContentElement, isMcqOption: boolean) : string[] {
    const textContent : string[] = [];

    let contentPropertyMap = {};

    contentPropertyMap[ElementType.TEXT] = 'caption';
    // contentPropertyMap[ElementType.MATH] = 'latex';
    contentPropertyMap[ElementType.CANVAS] = 'caption';
    contentPropertyMap[ElementType.IMAGE] = 'altText';
    contentPropertyMap[ElementType.DOC_LINK] = 'caption';
    contentPropertyMap[ElementType.INPUT] = 'defaultText';
    contentPropertyMap[ElementType.IMAGE_SUBTEXT] = 'text';

    //MCQ options have variable element type but their display content is in a different property than usual for that element type
    const contentProp = isMcqOption ? 'content' : contentPropertyMap[element.elementType]; 

    const content = element[contentProp];

    if(content) {
      textContent.push(element[contentProp]);
    }

    return textContent.concat(this.getDefaultElementTextContent(element));    ;
  }


  elementContainsContent(element: IContentElement, search: string, isMcqOption: boolean = false) {

    const textContent = this.getElementTextContent(element, isMcqOption);

    for(const content of textContent) {
      if(content && (typeof content === "string") && content.toLowerCase().includes(search)) {
        return true;
      }
    }
    
    const children = getElementChildren(element);

    for(const child of children) {
      if(this.elementContainsContent(child, search, element.elementType === ElementType.MCQ || element.elementType === ElementType.SELECT_TEXT)) {
        return true;
      }
    }
    return false;
  }

  applyTagFilter = (config: IQuestionConfig) => {
    const linkedTags = this.itemBankCtrl.itemInfo[config.id].linkedTags;
    const linkedTagIds = linkedTags.map(t => t.id);
    for(const tag of this.filteringTags) {
      if(!linkedTagIds.includes(tag.id)) {
        return false;
      }
    }

    return true;
  }

  applyContentFilter = (config: IQuestionConfig) => {
    let search = this.searchQuery.value;
    if(!search) {
      return true;
    }

    search = search.toLowerCase();


    if(config.caption && config.caption.toLowerCase().includes(search)) {
      return true;
    }

    for(const element of config.content) {
      if(this.elementContainsContent(element, search)) {
        return true;
      }
    }

    // const searchArray = search.split(' ');
    // for (let i = 0; i < config.content.length; i++) {
    //   let element:any = config.content[i];
    //   if (element.elementType === 'text') {
    //     if (element.advancedList) {
    //       for (let k = 0; k < element.advancedList.length; k++) {
    //         const subElement = element.advancedList[k];
    //         if (subElement.elementType === 'text') {
    //           const subCaption = <string> subElement.caption.toLowerCase();
    //           // if (searchArray.some((v: string) => subCaption.includes(v))) {
    //           if (subCaption.includes(search)) {
    //             return true;
    //           }
    //         }
    //       }
    //     }
    //     const caption = <string> element.caption.toLowerCase();
    //     if (caption.includes(search)) {
    //     // if (searchArray.some((v: string) => caption.includes(v))) {
    //       return true;
    //     }
    //   }
    // }

    return false;
  }

  filterResourceFlag = ''
  applyQuestionResourceFilter = (question: IQuestionConfig) => {
    const isReadingSelectionPage = this.itemBankCtrl.getQuestionContent(question).isReadingSelectionPage // todo:TRANS
    if (this.filterResourceFlag == 'yes'){
      return !!isReadingSelectionPage
    }
    if (this.filterResourceFlag == 'no'){
      return !isReadingSelectionPage
    }
    return true
  }

  filterQuestionnaireFlag = ''
  applyQuestionnaireFilter = (question: IQuestionConfig) => {
    const isQuestionnaire = this.itemBankCtrl.getQuestionContent(question).isQuestionnaire // todo:TRANS
    if (this.filterQuestionnaireFlag == 'yes'){
      return !!isQuestionnaire
    }
    if (this.filterQuestionnaireFlag == 'no'){
      return !isQuestionnaire
    }
    return true
  }

  applyQuestionIdFilter = (question: IQuestionConfig) => {
    return this.filterQuestionIds && this.filterQuestionIds.includes(question.id);
  }

  //** Apply filters for stage, stage assignees, and pending graphic requests columns */
  applyWorkflowFilters = (question: IQuestionConfig) => {

    let isMatchAll = true;
    Object.keys(this.workflowFilterSettings).forEach(wfFilterParam => {

      const wfFilterValue = this.workflowFilterSettings[wfFilterParam]; 

      if (!wfFilterValue) return;
      let target;
      // For graphic requests, filter for check or no check with specific strings
      if (wfFilterParam == 'graphicReqPending') {
        target = !!this.itemBankCtrl.getNumPendingGraphicReqs(question.id)
        const isValueForTrue = ["true", "1"].includes(wfFilterValue)
        const isValueForFalse = ["false", "0", "undefined"].includes(wfFilterValue)
        if (!(isValueForTrue && target) && !(isValueForFalse && !target)) isMatchAll = false;
      } 
      // For stages and assignees, match the substring
      else {
        // TODO: bring back when editing tools merge
        // if (wfFilterParam == 'stage') target = this.itemBankCtrl.renderQuestionStageFull(question.id)
        // else if (wfFilterParam == 'assignees') target = this.itemBankCtrl.renderQuestionStageAssignees(question.id)
        if (
          !target ||
          !target.toLowerCase().includes(wfFilterValue.toLowerCase())
        ) isMatchAll = false;
      }

    });

    return isMatchAll;
  }

  applyQuestionFilters = (question: IQuestionConfig) => {
    let isMatchAll = true;
    Object.keys(this.filterSettings).forEach(filterParam => {
      let filterValue = this.filterSettings[filterParam];
      
      if(filterValue.onlyForLang) {
        if(filterValue.onlyForLang !== this.lang.c()) {
          return; //Ignore this filter -- not for this lang
        } else {
          filterValue = filterValue.val;
        }
      } 

      if (filterValue !== '' && filterValue !== undefined) {
        const filterPieces = filterParam.split('.');
        let target = question;
        filterPieces.forEach(filterPiece =>  target = target[filterPiece]);
        let isCurrentMatch = false;
        const complexFilter = filterValue.split('->');
        if (complexFilter.length > 1) {
          let isComplexSatisfied = true;
          const minVal = complexFilter[0];
          const maxVal = complexFilter[1];
          if (minVal !== '') {
            if (+target < +minVal) {
              isComplexSatisfied = false;
            }
          }
          if (maxVal !== '') {
            if (+target > +maxVal) {
              isComplexSatisfied = false;
            }
          }
          if (!target &&  (<any>target) !== 0) {
            isComplexSatisfied = false;
          }
          if (isComplexSatisfied) {
            isCurrentMatch = true;
          }
        } else {
          const targetValue = this.util.numStrToBool(filterValue);
          if (targetValue === this.util.numStrToBool(target)) {
            isCurrentMatch = true;
          }
          const strTarget = ('' + target).toLowerCase();
          const strTargetValue = ('' + targetValue).toLowerCase();
          if (strTarget.indexOf(strTargetValue) !== -1) {
            isCurrentMatch = true;
          }
        }
        if (!isCurrentMatch) {
          isMatchAll = false;
        }
      }
    });
    return isMatchAll;
  }

  updateItemFilter(selectedQuestionView?:QuestionView) {
    let unfilteredQuestions = [];
    const selectedView = selectedQuestionView ?? this.frameworkCtrl.selectedQuestionView
    switch (selectedView) {
      case QuestionView.QUESTION_BANK:
        unfilteredQuestions = [].concat(this.itemBankCtrl.getItems());
        break;
      case QuestionView.FORM_CONSTRUCTION:
        if (this.activeTestFormFilter){
          unfilteredQuestions = this.activeTestFormFilter.questions;
        } else if(this.activeSignOffFilter) {
          unfilteredQuestions = this.activeSignOffFilter.questions;
        }
        else{
          unfilteredQuestions = [].concat(this.itemBankCtrl.getItems());
        }
        break;
      case QuestionView.MSCAT_PANELS:
        if (this.frameworkCtrl.panelCtrl && this.frameworkCtrl.panelCtrl.activePanel) {
          unfilteredQuestions = this.frameworkCtrl.panelCtrl.activePanel.questions;
        }
        break;
      case QuestionView.QUADRANTS:
        if (this.quadrantCtrl.activeQuadrant) {
          unfilteredQuestions = this.quadrantCtrl.activeQuadrant.questions;
        }
        break;
      case QuestionView.TESTLETS:
        if (this.testletCtrl.activeTestlet) {
          unfilteredQuestions = this.testletCtrl.activeTestlet.questions;
        }
        break;
      case QuestionView.AUDITS:
        switch(this.auditCtrl.auditItemListingMode){
          case AuditTarget.QUESTIONS:
            if (this.auditCtrl.activeAuditItemMemId) {
              unfilteredQuestions = this.auditCtrl.auditQuestionMem[this.auditCtrl.activeAuditItemMemId] || [];
            }
            break;
          // If were viewing mscat panels we want the item filter to be related to the panels (i.e. when we view module items)
          case AuditTarget.MSCAT_PANELS:
            this.updateItemFilter(QuestionView.MSCAT_PANELS)
            return;
        }
        break;
      case QuestionView.ASSEMBLED_FORMS:
        if(this.frameworkCtrl.panelCtrl.filterToUsed){
          unfilteredQuestions = this.frameworkCtrl.panelCtrl.activePanel.questions;
        }
        break;

    }
    this.numUnfilteredQuestions = unfilteredQuestions.length;
    this.filteredQuestions = unfilteredQuestions.filter(this.applyQuestionFilters)
                                                .filter(this.applyWorkflowFilters);
    
    if (this.isExcludeBlankItemVersions){
      this.filteredQuestions = this.filteredQuestions.filter(q => !!q.item_version_code);
    }

    if(this.searchQuery.value){
      this.filteredQuestions = this.filteredQuestions.filter(this.applyContentFilter);
    }
    if(this.filterQuestionIds) {
      this.filteredQuestions = this.filteredQuestions.filter(this.applyQuestionIdFilter);
    }

    if(this.filteringTags) {
      this.filteredQuestions = this.filteredQuestions.filter(this.applyTagFilter);
    }
    if(this.filterResourceFlag) {
      this.filteredQuestions = this.filteredQuestions.filter(this.applyQuestionResourceFilter);
    }
    if(this.filterQuestionnaireFlag) {
      this.filteredQuestions = this.filteredQuestions.filter(this.applyQuestionnaireFilter);
    }
    this.updateItemList();
    this.itemBankCtrl.refreshQuestionsView();
    if(this.filteredQuestions?.length !== this.itemBankCtrl.getItems()?.length) this.isQuestionFiltersActive.setValue(true)
  }

  getSideItemList(){
    return this.itemBankCtrl.topLevelQuestions;
  }


  prevItemListPage() {
    if (this.itemBankCtrl.currentItemListPage > 0) {
      this.itemBankCtrl.currentItemListPage --;
      this.updateItemList();
    }
  }
  nextItemListPage() {
    if (this.itemBankCtrl.currentItemListPage * this.itemBankCtrl.itemListLength < this.filteredQuestions.length) {
      this.itemBankCtrl.currentItemListPage ++;
      this.updateItemList();
    }
  }
  updateItemList() {
    const len = this.itemBankCtrl.itemListLength;
    let currentItemListPage = this.itemBankCtrl.currentItemListPage;
    currentItemListPage = Math.min(currentItemListPage, Math.ceil(this.filteredQuestions.length / len));
    currentItemListPage = Math.max(1, currentItemListPage);
    const i_0 = (currentItemListPage - 1) * len;
    const i_1 = Math.min(this.filteredQuestions.length - 1, i_0 + len);
    this.itemBankCtrl.itemList = this.filteredQuestions.slice(i_0, i_1 + 1);
    this.itemBankCtrl.totalItemListPages = Math.ceil(this.filteredQuestions.length / len);
    this.itemBankCtrl.currentItemListPage = currentItemListPage;
  }

  clearItemsFilter(){
    this.clearFilter()
    this.filteredQuestions = null;
    this.itemBankCtrl.topLevelQuestions = this.itemBankCtrl.getItems();

  }

  isFiltering() {
    return this.filteredQuestions && this.filteredQuestions.length;
  }

  getFirstQuestionInList() {
    return this.getSideItemList()?.[0] || null;
  }

  setItemIdFilter(filter: string) {
    if(filter) {
      this.filterQuestionIds = JSON.parse(filter);
    }
  }

}