import { IContentElementMoveableDragDrop } from "src/app/ui-testrunner/element-render-moveable-dnd/model";
import { ElementType, IContentElement, IElementTypeDef } from "../../ui-testrunner/models";
import { IContentElementGroup } from "src/app/ui-testrunner/element-render-grouping/model";
import { IContentElementInsertion } from "src/app/ui-testrunner/element-render-insertion/model";
import { IContentElementOrder, IContentElementOrderOption, OrderMode } from "src/app/ui-testrunner/element-render-order/model";
import { IContentElementDndDraggable } from "src/app/ui-testrunner/element-render-dnd/model";

// List which element types need a score matrix (which was implemented)
export const scoreMatrixElementTypes: (ElementType | string)[] = [
  ElementType.GROUPING,
  ElementType.MOVEABLE_DND,
  ElementType.INSERTION,
  ElementType.ORDER
]

export enum MatrixHeaderType {
  TEXT = "TEXT",
  IMAGE = "IMAGE",
  MATH = "MATH",
  TABLE = "TABLE",
}
export interface IMatrixHeader {
  type: MatrixHeaderType,
  content: string
}
export interface IMatrixColHeader extends IMatrixHeader {};
export interface IMatrixRowHeader extends IMatrixHeader {
  id?: string | number;
  key_id?: string|number;
  option: IContentElementDndDraggable | IContentElementOrderOption
}
export interface IMatrixValueMetadata{
  isTargetIDMissing?: boolean;
  isOptionIDMissing?: boolean;
  numberRequired?: number; // for grouping as sometimes an option needs to go into a target multiple times
  linkedCombinations?: any[]; // for DND as certain correct values are linked to combinations where that value is correct

}
export interface IMatrixValues {
  value: string | number
  metadata?: IMatrixValueMetadata;
}

export interface IScoreMatrix {
  columns: IMatrixColHeader[],
  rows: IMatrixRowHeader[],
  values: IMatrixValues[][],
  isConfirmed: boolean,
  isUpdated: boolean
}
/**
 * Transform an element into content to be rendered inside the row/column header cell
 * Can be reused by different `getMatrix...` functions
 */
const elementToMatrixHeader = (element:IContentElement) : IMatrixHeader => {
  let type, content;
  switch(element.elementType){
    case ElementType.TEXT:
      type = MatrixHeaderType.TEXT;
      content = element.caption;
      break;
    case ElementType.IMAGE:
      type = MatrixHeaderType.IMAGE;
      content = element.images.default.image.url;
      break;
    case ElementType.MATH:
      type = MatrixHeaderType.MATH;
      content = element.latex || element.content;
      break;
    case ElementType.TABLE:
      type = MatrixHeaderType.TEXT;
      content =  `Table ${element.entryId? element.entryId:""}`;
      break;
    default:
      type = MatrixHeaderType.TEXT;
      content = ""
      break;
  }
  return {type, content}
}


/** Obtain the matrix rows (options) and columns (targets) for a block element */
export const getMatrix = (element: IContentElement): { rows: IMatrixRowHeader[]; columns: IMatrixColHeader[]; values: IMatrixValues[][] } => {
  let rows: IMatrixRowHeader[] = [];
  let columns: IMatrixColHeader[] = [];
  let values: IMatrixValues[][] = [];

  switch (element.elementType) {
    case ElementType.GROUPING:
      ({ rows, columns, values } = getMatrixGrouping(<IContentElementGroup>element));
      // values = getMatrixValuesGrouping(<IContentElementGroup>element, columns, rows);
      break;
    case ElementType.MOVEABLE_DND:
      ({ rows, columns, values } = getMatrixDND(<IContentElementMoveableDragDrop>element));
      break;
    case ElementType.INSERTION:
      ({ rows, columns, values } = getMatrixInsertion(<IContentElementInsertion>element));
      // values = getMatrixValuesInsertion(<IContentElementInsertion> element, columns, rows);
      break;
    case ElementType.ORDER:
      ({ rows, columns, values} = getMatrixOrder(<IContentElementOrder>element));
      break;
    default:
      console.error("Matrix Dimension Unavailable for element type " + element.elementType);
  }

  return { rows, columns, values };
};

const getMatrixGrouping = (element:IContentElementGroup | IContentElementMoveableDragDrop) : {rows: IMatrixRowHeader[], columns: IMatrixColHeader[], values: IMatrixValues[][]} => {
  const rows:IMatrixRowHeader[] = [];
  const columns:IMatrixColHeader[] = [];
  const values = [];
  // Targets as columns - Only use the ID of the target
  element.targets?.forEach(target => {
    const newColumn: IMatrixColHeader = {
      type: MatrixHeaderType.TEXT,
      content: ''+ (target.id ?? '')
    }
    columns.push(newColumn)
  })
  // Draggables as rows
  const areOptionsReusable = element.isOptionsReusable;
  element.draggables?.forEach(draggable => {
    let {type, content} = elementToMatrixHeader(draggable.element);
    const valuesForRow: IMatrixValues[] = [];
    const newRow: IMatrixRowHeader = {
      type,
      content,
      key_id: draggable.key_id ?? '',
      id: draggable.id ?? '',
      option: JSON.parse(JSON.stringify(draggable))
    }
    columns.forEach((col)=>{
      
      if(!areOptionsReusable){
        if (draggable.id && col.content){
          if("" + draggable.id === col.content){
            valuesForRow.push({value: 1});
          }
          else{
            valuesForRow.push({value: 0});
          }
        }
        else {
          valuesForRow.push({
            value: null,
            metadata: {
              isTargetIDMissing: col.content?false:true,
              isOptionIDMissing:  draggable.id?false:true
            }
          });
        }
      }
      else {
        if( draggable?.targetID2Amount?.[col.content] > 0){
          valuesForRow.push({
            value: 1,
            metadata: {
              numberRequired: +draggable.targetID2Amount[col.content],
            }
          });
        }
        else if (col.content) {
          valuesForRow.push({
            value: 0,
            metadata: {
              numberRequired: +draggable.targetID2Amount?.[col.content],
            }
          });
        } else {
          valuesForRow.push({
            value: null,
            metadata: {
              isTargetIDMissing: true
            }
          });
        }
      }
    
    });
    values.push(valuesForRow);
    rows.push(newRow);
  });
  return {rows, columns, values}
}

const getMatrixDND = (element: IContentElementMoveableDragDrop) : {rows: IMatrixRowHeader[], columns: IMatrixColHeader[], values: IMatrixValues[][]} => {
  const rows:IMatrixRowHeader[] = [];
  const columns:IMatrixColHeader[] = [];
  const values = [];
  const isMultipleOptionsRight = element.isMultipleOptionsRight;
  const isAcceptMultipleCombinations = element.isAcceptMultipleCombinations;
  // Targets as columns - Only use the ID of the target
  element.targets?.forEach(target => {
    const newColumn: IMatrixColHeader = {
      type: MatrixHeaderType.TEXT,
      content: ''+ (target.id ?? '')
    }
    columns.push(newColumn)
  })
  // Draggables as rows

  element.draggables?.forEach(draggable => {
    let {type, content} = elementToMatrixHeader(draggable.element);
    const valuesForRow: IMatrixValues[] = [];
    const newRow: IMatrixRowHeader = {
      type,
      content,
      id: draggable.id ?? '',
      option: JSON.parse(JSON.stringify(draggable))
    }
    columns.forEach((col, colIndex) => {
      if(isAcceptMultipleCombinations){
        const correctCombinationsIndex = [];
        element?.multipleCombinations?.forEach((combination, index)=>{
          if(combination[col.content] === draggable.id){
            correctCombinationsIndex.push(index);
          }
        });
        if(correctCombinationsIndex.length){
          valuesForRow.push({
            value: 1,
            metadata: {
              linkedCombinations: correctCombinationsIndex
            }
          });
        }
        else {
          valuesForRow.push({
            value: 0,
            metadata: {
              isTargetIDMissing: col.content?false:true,
              isOptionIDMissing:  draggable.id?false:true
            }
          })
        }

      }
      else if(!isMultipleOptionsRight){
        if (draggable.id && col.content){
          if(draggable.targetId + "" === col.content){
            valuesForRow.push({value: 1});
          }
          else{
            valuesForRow.push({value: 0});
          }
        }
        else {
          valuesForRow.push({
            value: null,
            metadata: {
              isTargetIDMissing: col.content?false:true,
              isOptionIDMissing:  draggable.id?false:true
            }
          });
        }
      }
      else {
        const isCorrect = element.pairMapping?.filter((pair) => pair.optionID+"" === draggable.id+"" && pair.targetID === col.content);
        if(isCorrect?.length){
          valuesForRow.push({value: 1});
        } 
        else if(col.content) {
          valuesForRow.push({value: 0})
        }
        else {
          valuesForRow.push({
            value:null, 
            metadata: {
              isTargetIDMissing: true
            }
          })
        }
      }
    })
    values.push(valuesForRow);
    rows.push(newRow);
  })
  return {rows, columns, values}
}

const getMatrixInsertion = (element: IContentElementInsertion) : {rows: IMatrixRowHeader[], columns: IMatrixColHeader[], values: IMatrixValues[][]} => {
  const rows:IMatrixRowHeader[] = [];
  const columns:IMatrixColHeader[] = [];
  let values: IMatrixValues[][] = [];

  element.textBlocks?.forEach((textBlock) => {
    if (textBlock.element.elementType === ElementType.BLANK || textBlock.element.elementType === ElementType.BLANK_DEPRECIATED) {
      columns.push({
        type: MatrixHeaderType.TEXT,
        content: (textBlock.id ?? '').toString(),
      });
    }
  });

  element.draggables?.forEach(draggable => {
    let {type, content} = elementToMatrixHeader(draggable.element);
    const valuesForRow: IMatrixValues[] = []
    columns.forEach((col, colIndex) => {
      if (draggable.id && col.content){
        if(""+draggable.id === col.content){
          valuesForRow.push({value: 1});
        }
        else{
          valuesForRow.push({value: 0});
        }
      }
      else {
        valuesForRow.push({
          value: null,
          metadata: {
            isTargetIDMissing: col.content?false:true,
            isOptionIDMissing:  draggable.id?false:true
          }
        });
      }
    })
    values.push(valuesForRow)
    const newRow: IMatrixRowHeader = {
      type,
      content,
      key_id: draggable.key_id ?? '',
      id: draggable.id ?? '',
      option: JSON.parse(JSON.stringify(draggable))
    }
    rows.push(newRow)
  })
  return {rows, columns, values}
}

const getMatrixOrder = (element: IContentElementOrder) : {rows: IMatrixRowHeader[], columns: IMatrixColHeader[], values: IMatrixValues[][]} => {
  const rows:IMatrixRowHeader[] = [];
  const columns:IMatrixColHeader[] = [];
  const values: IMatrixValues[][] = [];
  const rowItemsToProcesss = element.orderMode === OrderMode.TARGET ? element.options.filter(option => !option.isReadOnly): element.options;
  
  var count = 1;
  element.options?.forEach((option) => {//? I'm not sure how ordering mode calculates what is wrong and what is right and what exactly targets would be in this context
    if(!option.isReadOnly || element.orderMode === OrderMode.REORDER){// this is due to the fact that we can't count fixed elements in target mode but in reorder they still appear
      columns.push({
        type: MatrixHeaderType.TEXT,
        content: ""+count++,
      });
    }
  });
  
  rowItemsToProcesss?.forEach((item, index) => {
    const { type, content } = elementToMatrixHeader(item.element || item);
    const newRow: IMatrixRowHeader = {
      type,
      content,
      key_id: item.key_id ?? '',
      option: JSON.parse(JSON.stringify(item))
    };
    const valuesForRow: IMatrixValues[] = []
    columns.forEach((col, colIndex) => {
      if(col.content === (index+1).toString()){
        valuesForRow.push({value: 1});
      }
      else{
        valuesForRow.push({value: 0});
      }
    })
    values.push(valuesForRow)
    rows.push(newRow);
  });
  return {rows, columns, values}
}



/**
 * Refresh the score matrix based on the content of the element
 * For any unchanged rows or columns, preserve the old values - otherwise use blank values
 * @param element The block element which contains the matrix (is updated in-place)
 */
export const refreshScoreMatrix = (element:IContentElement, forceRefresh = false) => {

  // Element must qualify to have a matrix, otherwise skip
  if (!scoreMatrixElementTypes.includes(element.elementType)) {
    return
  }

  // If no matrix exists on the block yet, make a blank one
  if (!element.scoreMatrix){
    element.scoreMatrix = {
      isUpdated: false,
      isConfirmed: false,
      rows: [],
      columns: [],
      values: []
    }
  }
  if(element.scoreMatrix.isConfirmed === undefined){
    element.scoreMatrix.isConfirmed = false;
  }
  if(element.scoreMatrix.isUpdated === undefined){
    element.scoreMatrix.isUpdated = false;
  }

  // Get the previous matrix content
  const prevColumns = element.scoreMatrix.columns
  const prevRows = element.scoreMatrix.rows
  const prevValues = element.scoreMatrix.values

  // Generate matrix columns and rows based on the element
  const {columns, rows, values} = getMatrix(element)
  const rowsOutdated = checkRowsOutdated(prevRows, rows, element.elementType);
  if((JSON.stringify(prevColumns) !== JSON.stringify(columns) || rowsOutdated || JSON.stringify(prevValues) !== JSON.stringify(values))&& element.scoreMatrix.isConfirmed){
    element.scoreMatrix.isUpdated = false;
  }
  else {
    element.scoreMatrix.isUpdated = true;
  }
  // Update to new matrix
  if(!element.scoreMatrix.isConfirmed || forceRefresh){
    element.scoreMatrix.rows = rows;
    element.scoreMatrix.columns = columns;
    element.scoreMatrix.values = values;
  }
}

const checkRowsOutdated = (prevRows:IMatrixRowHeader[], rows:IMatrixRowHeader[], elementType:ElementType | string): boolean => {
  let rowsOutdated = false;
  if(prevRows?.length !== rows?.length){
    rowsOutdated = true;
  } else {
    for(let idx = 0; idx < rows.length && !rowsOutdated; idx++){
      const current = rows[idx];
      const previous = prevRows[idx];
      const currKeys = Object.keys(current);
      const prevKeys = Object.keys(previous);
      if(prevKeys.length !== currKeys.length){
        rowsOutdated = true;
        break;
      }
      for(let key of currKeys){
        if(key === 'option'){
          // only check relevant keys in options that affect scoring
          const keysToCheck = COMMON_OPTION_KEY_CHECKS.concat(OPTION_KEY_CHECKS_MAP[elementType] ?? []);
          for(let commonKey of keysToCheck){
            if(
              previous[key]?.[commonKey] !== current[key]?.[commonKey] && // if they aren't equal
              !(!previous[key]?.[commonKey] && !current[key]?.[commonKey]) // and they aren't both falsely
            ){
              rowsOutdated= true;
              break
            }
          }
        } else if(previous[key] !== current[key]){
          rowsOutdated = true;
          break;
        }
      }
      
    }
  }
  return rowsOutdated;
}

const COMMON_OPTION_KEY_CHECKS = ['key_id', 'id']
const OPTION_KEY_CHECKS_MAP = {
  'moveable_dnd' : ['targetId', ]

}