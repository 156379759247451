// this should move to the DB (watch out for the todo:db comments first, they should be pretty quick to del with before proceeding)

import { ElementType } from 'src/app/ui-testrunner/models';

export interface IAuditConfig {
    auditType: AuditType,
    auditTarget?: AuditTarget,
    slug: string,
    caption: string,
    itemScope?: AuditQuestionScope[],
    checks?: IAuditCheck[],
    commonCheckProps?: Partial<IAuditCheck>,
    defaultScope?: string,
    isCompleteCheck?:boolean, 
    isCompleteFix?:boolean, 
    isCustomResult?:boolean, 
    isContentDiff?:boolean, 
    autoFixes?: IAuditPatch, 
    isDisabled?:boolean, 
    isHidden?:boolean, // for work in progress
    isLangSensitive?: boolean // controls wether or not we want to retrieve questions relative to curr lang
}

/**
 * The content that we are auditing (e.g. forms, panels, questions (default))
 * It'll dictate what is store in auditResults items
 */
export enum AuditTarget {
    QUESTIONS = "QUESTIONS",
    MSCAT_PANELS = "MSCAT_PANELS"
}
export type AuditType =
    "DATA"
    | "CONTENT"
export interface IAuditPatch {
    slug: string,
    checkCompositeSlug?: string,
    caption: string,
    checks?: {id: string},
    fixType?: CheckType,
    fixConfigs?: IFixByBlockType[],
}

export interface IAuditCheck {
    id: string,
    caption?: string,
    checkType?: CheckType,
    checkConfig?: ICheckByBlockType 
        | ICheckPropVal | ICheckProp[],
    typeCaption?: string,
    checkElType?:EAuditElementType,
    isInverted?:boolean,
    isGood?:boolean,
    isDisabled?:boolean,
    excludedItems?: SubAuditExcludedItems[], // used to exclude items from sub audits
    informativeOnly?: InformativeCheckType, // used to exclude subaudits from issue count (doesn't apply to custom results)
    autoFix?: boolean; // used for non custom result audits to determine if they are auto fix
}

export type InformativeCheckType = // this is done to allow for more complex checks of if the audit is informative only
    boolean 
    | 'SAMPLE_TEST'
    | 'NOT_SAMPLE_TEST';

export interface ICheckProp {
    targetProp: string;
    filterMethod: FilterMethods;
    filterValue?: string | number | boolean | any[];
    }
export interface ICheckPropVal {
    targetProp?: string,
    isScoringConfig?: boolean,
    filterMethod?: FilterMethods, // condition on which the check with fail in this case
    filterValue?: string | number | boolean,
    transforms?: {
        type: BlockPropValTransformTypes,
        config?: any,
    }[]
}

export interface ICheckByBlockType {
    targetBlockType?: string,
    relativePropPath?: string,
    filterMethod?: FilterMethods,
    filterValue?: string | number | boolean,
    transforms?: {
        type: BlockPropValTransformTypes,
        config?: ITransformConfig,
    }[]
    isMissing?: boolean,
    isArrMissingOrEmpty?: boolean,
    dndKeys?: DndCheckFlags[],
    setHas?: string[],
    setMissing?: string[]
    // isMissing: boolean,
}

export interface ITransformConfig {
    conditions:{propPath:string, val:any, method:FilterMethods}[]
}

export interface IFixByBlockType {
    targetBlockType?: string,
    propPath: string,
    val: any,
}

export type CheckId = string;

export type ItemTypesParam = 'DDP' | 'DP' | 'CLK' | 'DG' | 'CH' | 'OR' | 'LW' | 'SW';

export const ITEM_MAP_MODULE_META_AUDIT_CONST_LABELS = ['MM_LW', 'MM_WMC'];

export type BlockPropValTransformTypes = 'URL_TO_TYPES' 
    | 'ARR_FILTER'
    | ''
    | '';

type DndCheckFlags = 'hasDuplicateKeyIds'
    | 'hasMissingKeyIds'
    | 'hasMissingHomeTargetIds'
    | 'hasDuplicateHomeTargetIds'
    '';

export type CheckType = 
    'PROP_VAL' // to check a single question param
    | 'BY_BLOCK_TYPE' // to check against questions containing specific block types (deep search)
    | 'ITEM_SETTINGS' // to check item settings or anything that is emmbedded directly in the qconfig 
    | 'PROPS_CONFIG' // to check combination of params if all the checks are true it'll flag the item
    | 'PROPS_CONFIG_OR' // to check if any single condition is true

export type FilterMethods = 'NOT_EQUAL' 
    | 'EQUAL'
    | 'IS_FALSEY'
    | 'IS_TRUTHY'
    | 'IS_NULL'
    | 'IS_ARR_EMPTY'
    | 'GREATER_THAN'
    | 'IS_NAN' // check if it's not a number 
    | 'IS_NEGATIVE' // checks if value is negative or not a number
    | 'DOES_NOT_INCLUDE' // check if value isn't in an array
    | '';

export type EAuditElementType = 'ASSET' | 'QUESTION';

//? All scopes are subset of SCREENS except ITEM_BANK
export type AuditQuestionScope =
      'ITEMS_SCORED' // fetches all the questions that are not reading selection pages and not questionnaires
    | 'ITEMS_SCORED_MINUS_FT' // fetches all scored questions which do not have a field trial flag set in their meta data
    | 'ITEMS_HUMAN_SCORED' // fetches all questions that look like they should be human scored
    | 'ITEMS_SCORED_MINUS_HS' // fetches all scored questions that are not human scored
    | 'ITEMS_SURVEY' // fetches all questions that are questionnaires, or if the assessment framework in context is a questionnaire assessment, it fetches all questions
    | 'SCREENS' // fetches all the screens (question being used) (which includes items)
    | 'SCREENS_NONSCORED' // todo
    | 'SCREENS_PASSAGES' // fetches all screens that are reading selection pages
    | 'SCREENS_NON_PASSAGES' // fetches all the questions that are not reading selection pages
    | 'ITEM_BANK' // fetches all questions in item bank
    | 'ITEM_BANK_SCORED' // fetches all questions in item bank that are scored
    | 'SCREENS_INCLUDE_DISABLED' // extra flag to see which 
    | 'PASSAGE_ITEMS' // to retrieve all questions in testletes that have a reading passage;
    | 'ITEM_BANK_PASSAGES'
;
export type SubAuditExcludedItems =
      'QUESTIONNAIRE'
    | 'READING_SELECTIONS'
;

export interface IAuditFilterScopedQuestions {
    [scopeSlug: string /* AuditQuestionScope */ ]: boolean,
}

export const AuditScopes:IAuditFilterScopedQuestions[] = [
    {}
]
export const AuditConfigs:IAuditConfig[] = [
    {
        auditType: 'CONTENT',
        slug: 'QUESTION_UTIL',
        caption: 'Overall Question Utilization',
        itemScope: ['ITEMS_SCORED'],
        commonCheckProps:{
            informativeOnly: true
        },
        checks: [
            {
                id:'UTIL_TESTLET', 
                caption: 'do not appear in any testlets',
            },
            {
                id:'UTIL_QUAD', 
                caption: 'do not appear in any quadrants',
            },
        ],
        // autoFixes: [
        //     { 
        //         slug:'xxxxx',
        //         caption: '',
        //     }, 
        // ],
    },
    {
        auditType: 'CONTENT',
        slug: 'QUESTION_LABEL',
        caption: 'Duplicate Item Label',
        itemScope: ['SCREENS', 'ITEM_BANK'],
        checks: [
            {
                id:'Q_LABEL_DUPE', 
                caption: 'items with overlapping labels',
            },
        ],
        // autoFixes: [
        //     { 
        //         slug:'xxxxx',
        //         caption: '',
        //     }, 
        // ],
    },
    {
        auditType: 'DATA',
        slug: 'EXP_ANS',
        caption: 'Expected Answer',
        itemScope: ['SCREENS'],
        checks: [
            {id:'Q_EXP_ANS_MISSING', caption: 'MCQ items missing simulated expected answer', excludedItems: ['QUESTIONNAIRE', 'READING_SELECTIONS'], autoFix: true},//
            {id:'Q_EXP_ANS_MISMATCH', caption: `MCQ items configuration doesn\'t match simulated expected answer`, excludedItems: ['QUESTIONNAIRE', 'READING_SELECTIONS'], autoFix: true},
            {id:'Q_EXP_ANS_LANG_MISMATCH', caption: `contain(s) different expected answers between languages`, excludedItems: ['QUESTIONNAIRE', 'READING_SELECTIONS']},
            {id:'Q_EXP_ANS_MULTI_ENTRY', caption: `contain(s) more than one response entry ` },//
            {id:'Q_EXP_ANS_NO_ENTRY', caption: `contain(s) no response entry `, excludedItems: ['READING_SELECTIONS']},//
            {id:'Q_EXP_ANS_NOT_MCQ', caption: `contain(s) non-MCQ responses`,  excludedItems: ['READING_SELECTIONS'], informativeOnly: true},
            {id:'Q_EXP_ANS_NO_ACC', caption: `contain(s) no specified correct answer `, excludedItems: ['QUESTIONNAIRE', 'READING_SELECTIONS']},
        ],
        autoFixes: { // EXP_ANS_CATEGORIES
            slug:'EXP_ANS_IS_AUTOFIXING',
            caption: '',
        },
        // autoFixes: [
        //     { 
        //         slug: 'Q_EXP_ANS_MISSING', 
        //         caption: 'ie_applying_auto_fix', 
        //     },
        //     // { 
        //     //     slug: 'Q_EXP_ANS_MISMATCH', 
        //     //     caption: 'Apply Auto-Fixes', 
        //     // },
        // ],
    },
    {
        auditType: 'CONTENT',
        slug: 'CLONES',
        caption: 'Clones',
        itemScope: ['ITEMS_SCORED'],
        isCustomResult: true, // I think the only reason this is custom is because a different pattern was used in this case
        autoFixes:             { // N_CLONES_ISSUES
            slug:'CLONES_IS_AUTOFIXING',
            caption: '',
        }, 
    },
    {
        auditType: 'CONTENT',
        slug: 'ENTRY_ID',
        caption: 'Entry IDs',
        itemScope: ['SCREENS_NON_PASSAGES'],
        commonCheckProps:{
            informativeOnly: true
        },
        checks: [
            {id:'UNTRACKED_ENTRIES', caption: 'contain untracked entries'}
        ]

    },
    {
        auditType: 'CONTENT',
        slug: 'VOICEOVER',
        caption: 'Voice-Over',
        itemScope: ['SCREENS'],
        isCustomResult: true, // I think the only reason this is custom is because a different pattern was used in this case
        isLangSensitive: false,
        checks: [
            {id:'MISSING_OVERALL_VOICE', },
            {id:'MISSING_OPTION_VOICE', },
            {id:'CONTAIN_DUPLICATED_OPTION_VOICE'},
        ],
        autoFixes: { 
            slug: 'VOICEOVER_IS_AUTOFIXING', 
            caption: 'Apply Auto-Fixes', 
        },
    },
    {
        auditType: 'CONTENT',
        slug: 'IP_RIGHTS',
        caption: 'Intellectual Property',
        itemScope: ['SCREENS'],
        checks: [
            {id:'NOT_COMPLETED', caption: 'not marked as Completed', checkElType:'ASSET',},
            {id:'NOT_INCLUDED_EN', caption: 'contain assets not included in the Asset Library (en)', },
            {id:'NOT_INCLUDED_FR', caption: 'contain assets not included in the Asset Library (fr)',},
            {id:'ALLOWED_IMPRESSIONS_NUM', caption: 'with allowed impressions less than the number of test-takers', checkElType:'ASSET', },
        ],
        isDisabled: false,
    },
    {
        auditType: 'CONTENT',
        slug: 'ACCESSIBILITY',
        itemScope: ['SCREENS'],
        caption: 'Accessibility',
        checks: [
            {id:'IMAGES_MISSING_ALT_TEXT', typeCaption: 'images / hc(high Contrast) images', caption: 'are missing alt-text',},
            {id:'VIDEOS_MISSING_SUBTITLES', typeCaption: 'videos', caption: 'are missing subtitles', isDisabled: true},
            {id:'AUDIO_FILES_MISSING_TRANSCRIPTS', typeCaption: 'audio files', caption: 'are missing transcripts', isDisabled: true},
            {id:'ITEMS_USING_KEYBOARD_INACCESSIBLE_BLOCKS', typeCaption: 'questions', caption: 'using blocks which have not be marked as keyboard accessible', isDisabled:true},
        ],
    },
    {
        auditType: 'CONTENT',
        slug: 'PRINT_VERSION',
        caption: 'Print Version',
        itemScope: ['SCREENS'],
        checks: [
            {id:'PRINT_FRIENDLY', typeCaption: 'pages', caption: 'are not print-friendly'},
        ],
        isDisabled: true,
        isHidden: true,
    },
    {
        auditType: 'CONTENT',
        slug: 'MOBILE',
        caption: 'Compatibility with Mobile',
        itemScope: ['SCREENS'],
        isDisabled: true,
        isHidden: true,
    },
    {
        auditType: 'CONTENT',
        slug: 'BROWSERLOCK',
        caption: 'Compatibility with Browser Lock',
        itemScope: ['SCREENS'],
        isDisabled: true,
        isHidden: true,
    },
    {
        auditType: 'CONTENT',
        slug: 'COMMENTS',
        caption: 'Comments',
        itemScope: ['SCREENS'],
        isCustomResult: true, // this is actually custom results
        checks: [
            {id:'QS_W_OUTSTANDING_CMTS', caption: 'Items with outstanding comments ', },
            {id:'ASSETS_W_OUTSTANDING_CMTS', caption: 'Assets with outstanding comments ', checkElType:'ASSET', },
        ],
    },
    {
        auditType: 'CONTENT',
        slug: 'ASSESSMENT_KEYS',
        caption: 'Assessment Keys',
        itemScope: ['ITEMS_SCORED'],
        isDisabled: true,
        isHidden: true,
    },
    {
        auditType: 'CONTENT',
        slug: 'COMMON_ITEMS',
        caption: 'Common Items',
        itemScope: ['ITEMS_SCORED'],
        checks: [
            {id:'COMMON_ITEMS', caption: 'Common Items ', },
        ],
    },
    {
        auditType: 'CONTENT',
        slug: 'TRAX_PARAM',
        caption: 'TRAX Assessment Key',
        itemScope: ['ITEMS_SCORED'],
        isDisabled: true,
        isHidden: true,
        checks: [
            {id:'ASMT_SESS_NUM', caption: 'have a missing or invalid ASSMT_SESSION key (6 numbers)', },
            {id:'ASMT_CODE', caption: 'have a missing or invalid ASSMT_CODE key (3 letters, 2 numbers)', },
            {id:'ASMT_MARK', caption: 'have a missing or invalid MARK_VALUE key (1 or 2 digits)', },
            {id:'ASMT_SCALE', caption: 'have a missing or invalid SCALE_FACTOR key (less than 9 digits)', },
            {id:'ASMT_SESS_CHAR', caption: 'have a missing or invalid ASSMT_SECTION key (starts with A or B)', },
        ],
    },
    {
        auditType: 'DATA',
        slug: 'MSCATITEMDUP_PARAM',
        itemScope: ['ITEMS_SCORED'],
        caption: 'MsCat Check for Duplicate Items',
    },
    {
        auditType: 'CONTENT',
        slug: 'CONTENT_DIFF_AUDIT',
        itemScope: ['SCREENS'],
        caption: 'Compare question config to published config',
        isCustomResult: true,
        isContentDiff: true,
        checks: [
            {id:'CURRENT_CONTENT_DIFF_AUDIT', caption: 'Compare published question configs to current config', informativeOnly:true},
        ],
    },
    {
        auditType: 'CONTENT',
        slug: 'PUBLISHED_CONTENT_DIFF_AUDIT',
        itemScope: ['SCREENS'], 
        caption: 'Compare Published Question Configs',
        isCustomResult: true,
        isContentDiff: true,
        checks: [
            {id:'PUBLISHED_CONTENT_DIFF_AUDIT', caption: 'Compare published question configs', informativeOnly:true},
        ],
    },
    {
        auditType: 'DATA',
        slug: 'HUMAN_SCORED_AUDIT',
        itemScope: ['ITEMS_HUMAN_SCORED'],
        caption: 'Human Scored Items Audit',
        isCompleteCheck: true,
        checks: [
            {
                id:'MISSING_HUMAN_SCORED', 
                caption: 'Possible Human Scored Items missing isHumanScored prop', 
            },
            {
                id:'MISSING_SCALES', 
                caption: 'Human Scored Items missing SCALES', 
            },
            {
                id:'SCALE_MISSING_PROFILE_ID',
                caption:'Human Scored Items with scale missing Score Profile ID'
            },
            {
                id:'MISSING_INPUT_BLOCK',
                caption: 'Possible Human Scored Items missing short answer block'
            },
            {
                id:'MISCONFIGURED_SCORING_SCALE',
                caption: 'Possible Human Scored Items with misconfigured scale please reset scales'
            },
            {
                id: 'HAS_TEI_PARAMS_SET',
                caption: 'Human scored with TEI or TEI_Type set',
                checkType: 'PROPS_CONFIG_OR',
                checkConfig:[
                    {
                        targetProp: 'TEI',
                        filterMethod: 'IS_TRUTHY'
                    },
                    {
                        targetProp: 'TEI_Type',
                        filterMethod: 'IS_TRUTHY'
                    }
                ]
            }
        ],
    },
    {
        auditType: 'DATA',
        slug: 'SCORE_POINT_AUDIT',
        itemScope: ['ITEMS_SCORED_MINUS_HS'], // todo: how could we include the human scored items too?
        caption: 'Score Point Audit',
        checks: [
            {id: 'ITEMS_WITH_SCORE_POINT_COUNT', caption: 'Items with SCORE POINT PARAM', informativeOnly: true},
            {id: 'MISSING_SCORE_POINT', caption: 'Items missing SCORE POINT PARAM', },
            {id: 'INVALID_SCORE_POINT_ALIGNMENT', caption: 'SCORE POINT is not aligned with total weight of the item', },
            {id: 'INVALID_SCORE_POINT_EXCEEDING_TOTAL_WEIGHT', caption: 'SCORE POINT exceeding total weight of the item', },
            {id: 'INVALID_SCORE_POINT_BELOW_TOTAL_WEIGHT', caption: 'SCORE POINT is less then total weight of the item', },
            {id: 'ITEMS_SCORE_POINT_EXCEEDS_MAX_SCORE_EA_SUBMISSIONS', caption: 'Items with SCORE POINT exceeding max score of all submissions', },
            {id: 'ITEMS_SCORE_POINT_BELOW_MIN_SCORE_EA_SUBMISSIONS', caption: 'Items with SCORE POINT below the min score of all submissions', },
            {id: 'ITEMS_SCORE_POINT_EXCEEDS_MIN_WEIGHT_EA_SUBMISSIONS', caption: 'Items with SCORE POINT exceeding min weight of all submissions', },
            {id: 'ITEMS_SCORE_POINT_BELOW_MAX_WEIGHT_EA_SUBMISSIONS', caption: 'Items with SCORE POINT below the max weight of all submissions', },
            {id: 'SCORE_POINT_BELOW_MAXIMUM_CAPTURED_SCORE_SS', caption: 'Items with Score Point param below max possible score from simulated submission', },
        ],
    },
    //! DEPRECIATED { 
    //     count: ++count,
    //     slug: 'TEI_EA_ANSWER_AUDIT',
    //     itemScope: ['ITEMS_SCORED_MINUS_HS'],
    //     caption: 'TEI Expected Answer Audit',
    //     checks: [
    //         {id: 'MISSING_EXPECTED_ANSWER', caption: 'Items missing expected answer', },
    //         {id: 'INVALID_TEI_EA_FOR_MCQ_BLOCK', caption: 'MCQ items with TEI_EA defined (Should be null)', },
    //         {id: 'MISS_MATCH_TEI_EA', caption: 'Item\'s simulated submission doesn\'t match `TEI_EA` param or it has partial simulated score', },
    //         // {id: CheckId.MISSING_EXPECTED_ANSWER_FOR_MCQ_BLOCK, caption: 'MCQ items missing `EA` param', items: [], type: EAuditElementType.QUESTION},
    //         // {id: CheckId.INVALID_EXPECTED_ANSWER_FOR_MCQ_BLOCK, caption: 'MCQ items with invalid expected answer defined', items: [], type: EAuditElementType.QUESTION},
    //         // {id: CheckId.MISS_MATCH_EXPECTED_ANSWER_FOR_MCQ, caption: 'MCQ items configuration doesn\'t match `EA` param', items: [], type: EAuditElementType.QUESTION},
    //     ]
    // },
    {
        auditType: 'DATA',
        slug: 'QUESTIONNAIRE_ITEMS_AUDIT',
        itemScope: ['ITEMS_SURVEY'],
        caption: 'Questionnaire Items Audit',
        checks: [
            {id: 'ITEMS_MISSING_IS_QUESTIONNAIRE_CHECK', caption: 'Questionnaire Items missing isQuestionnaire Checkbox ', 
                checkType: 'ITEM_SETTINGS', 
                checkConfig:{
                targetProp: 'isQuestionnaire',
                filterMethod: 'IS_FALSEY'
            }},
            {id: 'ITEMS_WITH_SELECTION_TABLE_SPLIT_DATA_CHECKBOX', caption: 'Questionnaire Items with Selection table split data enabled', 
                checkType: 'BY_BLOCK_TYPE',
                checkConfig: {
                    relativePropPath: 'splitData',
                    filterMethod: 'IS_TRUTHY',
                    targetBlockType: ElementType.SELECT_TABLE
                },
                informativeOnly: true
             },
            {id: 'ITEMS_MISSING_SELECTION_TABLE_SPLIT_DATA_CHECKBOX', caption: 'Questionnaire Items with Selection table split data disabled', 
                checkType: 'BY_BLOCK_TYPE',
                checkConfig: {
                    relativePropPath: 'splitData',
                    filterMethod: 'IS_FALSEY',
                    targetBlockType: ElementType.SELECT_TABLE
                }
            },
            {id: 'ITEMS_WITHOUT_SELECTION_TABLE_MAX_ONE_SELECTION_PER_ROW', caption: 'Questionnaire Items without Selection table max one selection per row',
                checkType: 'BY_BLOCK_TYPE',
                checkConfig: {
                    relativePropPath: 'maxCheckedPerRow',
                    filterMethod: 'IS_FALSEY',
                    targetBlockType: ElementType.SELECT_TABLE,
                }
             },
            {id: 'ITEMS_WITH_SELECTION_TABLE_MAX_ONE_SELECTION_PER_ROW', caption: 'Questionnaire Items with Selection table max one selection per row',
                checkType: 'BY_BLOCK_TYPE',
                checkConfig: {
                    relativePropPath: 'maxCheckedPerRow',
                    filterMethod: 'EQUAL',
                    targetBlockType: ElementType.SELECT_TABLE,
                    filterValue: 1
                },
                informativeOnly: true
             },
            {id: 'ITEMS_WITH_SELECTION_TABLE_MULTIPLE_SELECTION_PER_ROW', caption: 'Questionnaire Items Selection table Multiple selection per row', 
                checkType: 'BY_BLOCK_TYPE',
                checkConfig: {
                    relativePropPath: 'maxCheckedPerRow',
                    filterMethod: 'GREATER_THAN',
                    targetBlockType: ElementType.SELECT_TABLE,
                    filterValue: 1
                }
            },
        ],
    },
    {
        auditType: 'DATA',
        slug: 'ALTERNATIVE_LINEAR_TEST_AUDIT',
        itemScope: ['ITEMS_SCORED'],
        caption: 'Alternative Linear test Audit',
        commonCheckProps: {
            checkType: 'PROP_VAL',
            checkConfig: {
                filterMethod: 'IS_FALSEY',
            }
        },
        checks: [
            {
                id: 'ITEMS_MISSING_LIN', 
                caption: 'Items For Alternative linear test missing LIN param', 
                checkConfig: {
                    targetProp: 'LIN',
                }
            },
            {
                id: 'ITEMS_MISSING_PAPER_ONLY_CHECKED', 
                caption: 'Items For Alternative linear test missing PO param', 
                checkConfig: {
                    targetProp: 'PO',
                }
            },
            {
                id: 'ITEMS_MISSING_REGULAR_QUESTION_ID_CHECKED', 
                caption: 'Items For Alternative linear test missing question_id_reg param', 
                checkConfig: {
                    targetProp: 'question_id_reg',
                }
            },
            {
                id: 'ITEMS_MISSING_REGULAR_QUESTION_LABEL_CHECKED', 
                caption: 'Items For Alternative linear test missing question_label_reg param', 
                checkConfig: {
                    targetProp: 'question_label_reg',
                }
            },
            
        ],
    },
    {
        auditType: 'DATA',
        slug: 'REJECTED_ITEMS_AUDIT',
        itemScope: ['SCREENS'], // todo: this was coded to grab all screens before, why?
        caption: 'Rejected Items Audit',
        isCompleteCheck: true,
        checks: [
            {
                id: 'REJECTED_ITEMS_INCLUDED_IN_TEST_DESIGNS', 
                caption: 'Rejected Items are included in Test designs / panels ',
                checkType: 'PROP_VAL',
                checkConfig: {
                    targetProp: 'REJ',
                    filterMethod: 'IS_TRUTHY',
                }
            },        
        ],
    },
    {
        auditType: 'DATA',
        slug: 'FIELD_TRIAL_ITEMS_AUDIT',
        itemScope: ['SCREENS'], // todo: shouldnt it be ITEMS_SCORED FT? previously was running on all screens
        caption: 'Field Trial Items Audit',
        commonCheckProps: {
            excludedItems: ['QUESTIONNAIRE']
        },
        checks: [
            {
                id: 'FIELD_ITEMS_INCLUDED_IN_TEST_DESIGNS', 
                caption: 'Field Trial Items are included in Test designs / panels',
                checkType: 'PROP_VAL',
                checkConfig: {
                    targetProp: 'FT', // todo: this prop is not actually designated as a universal embedded field trial flag, but it is sort of being used as if it is, this might be a good thing to store in the style profile
                    filterMethod: 'IS_TRUTHY',
                }
            },
            {
                id: "FIELD_ITEMS_EXCLUDED_IN_TEST_DESIGNS",
                caption: 'Field Trial Items that are not included in Test designs / panels',
                informativeOnly: true
            },       
            {
                id: "FIELD_ITEMS_IN_FT_TESTLETS_IN_NON_FT_TESTLET",
                caption: 'Items from Field Trial testlets duplicated in non Field Trial testlets',
                informativeOnly: true
            }       
        ],
    },
    {
        auditType: 'DATA',
        slug: 'POSSIBLE_EXPECTED_ANSWER_AUDIT',
        itemScope: ['ITEMS_SCORED_MINUS_HS'],
        caption: 'Possible Expected Answer Audit',
        checks: [
            {id: 'MISSING_POSSIBLE_EXPECTED_ANSWER', caption: 'Items missing possible expected answers', },
            {id: 'MULTI_POSSIBLE_EXPECTED_ANSWER', caption: 'Items contains multiple possible expected answers', },
            {id: 'CONTAINS_ONE_POSSIBLE_EXPECTED_ANSWER', caption: 'Items contains only one possible expected answer', },
            {id: 'COUNT_MULTI_SELECT_MCQ_WITH_POSSIBLE_MISSING_EA', caption: 'Multi Select MCQ items with possibly missing expected answers for full / partial marks', },
            {id: 'ITEM_MISSING_CORRECT_ANSWER', caption: 'MCQ Items missing the correct answer', },
            {id: 'SINGLE_MCQ_MISSING_POSSIBLE_CORRECT_ANSWER', caption: 'Single MCQ question missing correct simulated response', },
            {id: 'FORMATTED_RESPONSE_WITH_MULTIPLE_SCORES', caption: 'Contains formatted responses with multiple scores', },
            // {id: CheckId.SCORE_POINT_EXCEEDS_MAXIMUM_CAPTURED_SCORE_SS, caption: 'Items with Score Point param exceeds max possible score from simulated submission', items: [], type: EAuditElementType.QUESTION},
        ],
    },
    {
        auditType: 'CONTENT',
        slug: 'VIDEO_ITEM_AUDIT',
        itemScope: ['SCREENS', 'ITEM_BANK'],
        caption: 'Video Items Audit',
        isCompleteCheck: true,
        commonCheckProps: {
            checkElType: 'ASSET',
            checkType: 'BY_BLOCK_TYPE',
            checkConfig: {
                targetBlockType: ElementType.VIDEO, // todo:db: convert before going into DB (but maintain type controls in there)
                relativePropPath: 'urls',
                transforms: [{type:'URL_TO_TYPES'}],
            }
        },
        checks: [
            { 
                id: 'MISSING_URLS', 
                caption: 'Items missing URLS for video src', 
                checkConfig: {
                    isMissing: true,
                }
            },
            { 
                id: 'MISSING_WEBM', 
                caption: 'Items missing WEBM format video source in URLs', 
                checkConfig: {
                    setMissing: ['webm']
                }
            },
            { 
                id: 'MISSING_MP4', 
                caption: 'Items missing MP4 format video source in URLs', 
                checkConfig: {
                    setMissing: ['mp4']
                }
            },
            { 
                id: 'PASSED_AUDIT', 
                caption: 'Items have all the require video formats (passed)',
                checkConfig: {
                    setHas: ['mp4', 'webm']
                },
                informativeOnly: true
            }
            // { id: CheckId.SRC_UNAVAIL, caption: 'have one of the sources unavailable', items: [], type: EAuditElementType.ASSET },
        ]
    },
    {
        auditType: 'CONTENT',
        slug: 'BLANK_INVISIBLE_ENTRIES_ITEMS_AUDIT',
        itemScope: ['SCREENS'], // 'SCREENS'
        isCompleteCheck: true,
        caption: 'Check for Blank/Invisible Entries Items Audit',
        commonCheckProps: {
            checkElType: 'ASSET',
            checkType: 'BY_BLOCK_TYPE',
            checkConfig: {
                filterMethod: 'IS_ARR_EMPTY',
            }
        },
        checks: [
            {
                id: 'MCQ_MISSING_OPTIONS', 
                caption: 'Items that contain an MCQ with no options to select', 
                checkConfig: {
                    targetBlockType: ElementType.MCQ, // todo:db: 
                    relativePropPath: 'options',
                },
            },
            {
                id: 'SELECTION_TABLE_MISSING_ROWS', 
                caption: 'Items that contain a selection table with no rows', 
                checkConfig: {
                    targetBlockType: ElementType.SELECT_TABLE, // todo:db: 
                    relativePropPath: 'topRow',
                },
            },
            {
                id: 'SELECTION_TABLE_MISSING_COLS', 
                caption: 'Items that contain a selection table with no columns', 
                checkConfig: {
                    targetBlockType: ElementType.SELECT_TABLE, // todo:db: 
                    relativePropPath: 'leftCol',
                },
            },
            {
                id: 'DRAG_DROP_MISSING_DRAGGABLES_DND', 
                caption: 'dnd items missing draggables', 
                checkConfig: {
                    targetBlockType: ElementType.MOVEABLE_DND, // todo:db: 
                    relativePropPath: 'draggables',
                },
            },
            {
                id: 'DRAG_DROP_MISSING_TARGETS_DND', 
                caption: 'dnd items missing targets', 
                checkConfig: {
                    targetBlockType: ElementType.MOVEABLE_DND, // todo:db: 
                    relativePropPath: 'targets',
                },
            },
            {
                id: 'DRAG_DROP_MISSING_DRAGGABLES_GROUPING', 
                caption: 'grouping items missing draggables', 
                checkConfig: {
                    targetBlockType: ElementType.GROUPING, // todo:db: 
                    relativePropPath: 'draggables',
                },
            },
            {
                id: 'DRAG_DROP_MISSING_TARGETS_GROUPING', 
                caption: 'grouping items missing targets', 
                checkConfig: {
                    targetBlockType: ElementType.GROUPING, // todo:db: 
                    relativePropPath: 'targets',
                },
            },
            {
                id: 'DRAG_DROP_MISSING_DRAGGABLES_SORTING', 
                caption: 'sorting items missing draggables', 
                checkConfig: {
                    targetBlockType: ElementType.ORDER, // todo:db: 
                    relativePropPath: 'options',
                },
            },
            {
                id: 'DRAG_DROP_MISSING_DRAGGABLES_INSERTION', 
                caption: 'insertion items missing draggables', 
                checkConfig: {
                    targetBlockType: ElementType.INSERTION, // todo:db: 
                    relativePropPath: 'draggables',
                },
            },
            {
                id: 'DRAG_DROP_MISSING_TARGETS_INSERTION', 
                caption: 'insertion items missing targets',
            },
        ]
    },
    {
        auditType: 'CONTENT',
        slug: 'DRAG_DROP_AUDIT_MISSING_KEYS',
        itemScope: ['SCREENS'],
        caption: 'Drag Drop Items Audit (missing keys)',
        isCompleteCheck: true,
        commonCheckProps: {
            checkType: 'BY_BLOCK_TYPE',
            checkConfig: { }
        },
        checks: [
            {
                id: 'MISSING_KEY_ID', 
                caption: 'Items that contain a Drag-Drop missing key ID(s)', 
                checkConfig: {
                    dndKeys: ['hasMissingKeyIds'],
                },
            },
            {
                id: 'ITEMS_WITH_DUPLICATE_KEY_ID', 
                caption: 'Items that contain a Drag-Drop has duplicate key ID(s)', 
                checkConfig: {
                    dndKeys: ['hasDuplicateKeyIds'],
                },
            },

            {
                id: 'MISSING_HOME_TARGET_ID', 
                caption: 'Items that contain a Drag-Drop missing home target Ids', 
                checkConfig: {
                    dndKeys: ['hasMissingHomeTargetIds'],
                },
            },
            {
                id: 'ITEMS_WITH_DUPLICATE_HOME_TARGET_ID', 
                caption: 'Items that contain a Drag-Drop have duplicate home target Ids', 
                checkConfig: {
                    dndKeys: ['hasDuplicateHomeTargetIds'],
                },
            }
            //  INVALID_NUMBER_OF_DRAGGABLES_TARGETS
        ],
    },
    {
        auditType: 'CONTENT',
        slug: 'DRAG_DROP_AUDIT_MISSING_DIMENSIONS',
        itemScope: ['ITEMS_SCORED'],
        caption: 'Drag Drop Items Audit (Missing Dimensions)',
        isCompleteCheck: true,
        commonCheckProps: {
            checkType: 'BY_BLOCK_TYPE',
            checkConfig: {
                targetBlockType: ElementType.MOVEABLE_DND,
                filterMethod: 'IS_NAN',
             }
        },
        checks: [

            {
                id: 'MISSING_HEIGHT', 
                caption: 'Item(s) that contain a Drag\'n\' drop that doesn\'t have a height set', 
                checkConfig: {
                    relativePropPath: 'height',

                },
            }
        ],
    },

    {
        auditType: 'DATA',
        slug: 'ASSOCIATED_READING_PASSAGE_AUDIT',
        itemScope: ['PASSAGE_ITEMS'], // based on the check, we are not looking at the passages themselves (SCREENS_PASSAGES), but checking if they should have one
        caption: 'Associated reading passage audit',
        checks: [
            {id: 'MISSING_READING_PASSAGE', caption: 'Items missing associated texts'},
            {id: 'MISALIGNED_READING_PASSAGE', caption: 'Items associated texts doesn\'t match the items label'},
            {id: 'INVALID_READING_PASSAGE', caption: 'Items have an associated text that doesn\'t exist'},
            {id: 'TRAILING_SPACE_IN_READING_PASSAGE', caption: 'Items have an associated text with trailing spaces'},
            {id: 'MISSING_BOOKMARK_ID', caption: 'Items missing bookmark id'},
            {id: 'MISSING_TARGET_ITEM', caption: 'Items missing target item'},
            {id: 'INVALID_TARGET_ITEM', caption: 'Items with an invalid target item'},
            {id: 'TRAILING_SPACE_IN_TARGET_ITEM', caption: 'Items with a target item that has trailing spaces'},
            {id: 'INVALID_BOOKMARK_ID', caption: 'Items with an invalid bookmark id'},
            {id: 'TRAILING_SPACE_IN_BOOKMARK_ID', caption: 'Items with a bookmark id that has trailing spaces'},
        ]
    },
    {
        auditType: 'CONTENT',
        slug: 'ITEMS_MISSING_COMPLETION_MARK_CONDITION_AUDIT',
        itemScope: ['ITEMS_SCORED'],
        caption: 'Items missing completion condition audit',
        commonCheckProps: {
            checkType:'BY_BLOCK_TYPE', checkConfig:{
                filterMethod: 'IS_NAN'
            }
        },
        isCompleteCheck: true,
        checks: [
            {
                id: 'MISSING_COMPLETION_GROUPING', caption: 'Grouping Item(s) missing Mark as Completed in item Config', checkConfig:{
                targetBlockType: ElementType.GROUPING,
                relativePropPath: 'reqMinimumPlacedTarget',
                }
            },
            {
                id: 'LESS_DRAGGABLES_THAN_MARK_COMPLETED_GROUPING', caption: 'Grouping Item(s) have fewer draggables than the Mark as Completed value'
            },
            {
                id: 'MORE_DRAGGABLES_THAN_MARK_COMPLETED_GROUPING', caption: 'Grouping Item(s) have more draggables than the Mark as Completed value (informative only)',
                informativeOnly: true
            },
            {
                id: 'LESS_DRAGGABLES_THAN_TARGETS_GROUPING', caption: 'Grouping Item(s) have fewer draggables than targets and do not have the options reusable box checked off'
            },
            {
                id: 'MORE_DRAGGABLES_THAN_TARGETS_GROUPING', caption: 'Grouping Item(s) have more draggables than targets (informative only)',
                informativeOnly: true
            },
            {
                id: 'MISSING_COMPLETION_DND', caption: 'Drag\`n\`Drop Item(s) missing Mark as Completed in item Config', checkConfig:{
                targetBlockType: ElementType.MOVEABLE_DND,
                relativePropPath: 'howManyToFill',
                }
            },
            {
                id: 'LESS_DRAGGABLES_THAN_MARK_COMPLETED_DND', caption: 'Drag\'n\'Drop Item(s) have fewer draggables than the Mark as Completed value'
            },
            {
                id: 'MORE_DRAGGABLES_THAN_MARK_COMPLETED_DND', caption: 'Drag\'n\'Drop Item(s) have more draggables than the Mark as Completed value (informative only)',
                informativeOnly: true
            },
            {
                id: 'LESS_DRAGGABLES_THAN_TARGETS_DND', caption: 'Drag\'n\'Drop Item(s) have fewer draggables than targets and do not have the options reusable box checked off'
            },
            {
                id: 'MORE_DRAGGABLES_THAN_TARGETS_DND', caption: 'Drag\'n\'Drop Item(s) have more draggables than targets (informative only)',
                informativeOnly: true
            },
            {
                id: 'MORE_DRAGGABLES_THAN_TARGETS_INSERTION', caption: 'Insertion Item(s) have more draggables than blanks (informative only)',
                informativeOnly: true

            },
            {
                id: 'MORE_TARGETS_THAN_FILLABLE_INSERTION', caption: 'Insertion Item(s) have more targets than can be filled',
            },
        ]
    },
    {
        auditType: 'CONTENT',
        slug: 'FILE_EXTENTION_ITEM_AUDIT',
        caption: 'File Extension audit',
        itemScope: ['SCREENS', 'ITEM_BANK'],
        isCompleteCheck: true,
        commonCheckProps: {
            checkType:'BY_BLOCK_TYPE',
            checkElType: 'ASSET',
            checkConfig:{
                targetBlockType: ElementType.IMAGE,
                transforms: [{type:'URL_TO_TYPES'}],
                setHas: ['svg']
            },
            informativeOnly: true
        },
        checks: [
            {
                id: 'DEFAULT_CONTAINS_SVG', caption: 'Items contain default images that are SVGs', checkConfig:{
                    relativePropPath: 'images.default.image.url',
                }
            },
            {
                id: 'HIGH_CONTRAST_CONTAINS_SVG', caption: 'Items contain high contrast images that are SVGs', checkConfig:{
                    relativePropPath: 'images.default.image.hiContrastImg.url',
                }
            }
        ]
    },
    {
        auditType: 'DATA',
        slug: 'IS_RESPONDABLE_ITEM_AUDIT',
        caption: 'Is Respondable audit',
        itemScope: ['SCREENS', 'ITEM_BANK'],
        isCompleteCheck: true,
        commonCheckProps: {
            checkType: 'PROPS_CONFIG',
        },
        checks: [
            {
                id: 'FT_BUT_NOT_IS_RESPONDABLE', caption: 'Items that have FT checked but not Is_Respondable', checkConfig: [
                    {
                        targetProp: 'FT',
                        filterMethod: 'IS_TRUTHY' 
                    },
                    {
                        targetProp: 'is_respondable',
                        filterMethod: 'IS_FALSEY'
                    }  
                ],
                excludedItems: ['READING_SELECTIONS']
            },
            {
                id: 'OPERATIONS_ISTATUS_NOT_IS_RESPONDABLE', caption: 'Items that have Operational as iStatus but not Is_Respondable', checkConfig: [
                    {
                        targetProp: 'is_respondable',
                        filterMethod: 'IS_FALSEY',
                    },
                    {
                        targetProp: 'IStatus',
                        filterMethod: 'EQUAL',
                        filterValue: 'Operational'

                    }
                ],
                excludedItems: ['READING_SELECTIONS']
            },
            {
                id: 'READING_SEL_OR_QUESTIONNAIRE_AND_IS_RESPONDABLE', caption: 'Items that have reading selection/questionnaire and is_respondable checked off'
            },
        ]
    },
    {
        auditType: 'DATA',
        slug: 'OVERALL_EXPECTATION_AUDIT',
        caption: 'Overall expectation audit',
        itemScope: ['ITEMS_SCORED'],
        isCompleteCheck: true,
        commonCheckProps: {
            checkType: 'PROPS_CONFIG',
        },
        checks:[
            {
                id: 'OE_NOE_NOT_FILLED', caption: 'Items do not have OE or NOE filled in', checkConfig: [
                    {targetProp: 'OE', filterMethod: 'IS_NULL'},
                    {targetProp: 'NOE', filterMethod: 'IS_NULL'}
                ]
            },
            {
                id: '2023_OE_NOT_FILLED', caption: 'Items do not have 2023_OE filled in', checkConfig: [
                    {targetProp: '2023_OE', filterMethod: 'IS_NULL'},
                ]
            },
        ]
    },
    {
        auditType: 'DATA',
        slug: 'SPECIFIC_EXPECTATION_AUDIT',
        caption: 'Specific expectation audit',
        itemScope: ['ITEMS_SCORED'],
        isCompleteCheck: true,
        commonCheckProps: {
            checkType: 'PROPS_CONFIG',
        },
        checks:[
            {
                id: 'SE_NOT_FILLED_IN', caption: 'Items do not have one of SE, SER, SEW or NSE filled in', checkConfig: [
                    {targetProp: 'SE', filterMethod: 'IS_NULL'},
                    {targetProp: 'SER', filterMethod: 'IS_NULL'},
                    {targetProp: 'SEW', filterMethod: 'IS_NULL'},
                    {targetProp: 'NSE', filterMethod: 'IS_NULL'},
                ]
            },
            {
                id: 'TWENTY_THREE_SER_NOT_FILLED_IN', caption: 'Items that do not have 2023_SER filled in', checkConfig: [
                    {targetProp: '2023_SER', filterMethod: 'IS_NULL'},
                ]
            },
            {
                id: 'TWENTY_THREE_SEW_NOT_FILLED_IN', caption: 'Items that do not have 2023_SEW filled in', checkConfig: [
                    {targetProp: '2023_SEW', filterMethod: 'IS_NULL'},
                ]
            },
        ]
    },
    {
        auditType: 'DATA',
        slug: 'PASSAGE_AUDIT',
        caption: 'Passage Block audit',
        itemScope: ['SCREENS','ITEM_BANK'],
        isCompleteCheck: true,
        checks:[
            {
                id: 'CONTAINS_PASSAGE_AUDIT', caption: 'Items that contain a passage block in test designs', checkType: 'BY_BLOCK_TYPE', checkConfig:{
                    targetBlockType: ElementType.PASSAGE,
                    relativePropPath: 'elementType',
                    filterMethod: 'EQUAL',
                    filterValue: ElementType.PASSAGE

                },
                excludedItems: ['QUESTIONNAIRE'],
                informativeOnly: true,
            },
        ]
    },
    {
        auditType: 'DATA',
        slug: 'PJ_SCAN_AUDIT',
        caption: 'PJ Scan Audit',
        itemScope: ['SCREENS', 'SCREENS_INCLUDE_DISABLED'],
        commonCheckProps: {
            excludedItems: ['QUESTIONNAIRE', 'READING_SELECTIONS']
        },
        checks: [
            {id: 'MISSING_SCAN_SLUG', caption: 'Items that have scan slug missing'},
            {id: 'INCORRECTLY_MAPPED_SCAN_SLUG', caption: 'Items that have scan slug incorrectly mapped'},
            {id: 'MISSING_PAPER_TEXT', caption: 'Items that have paper text or voiceover missing'},
            {id: 'SCAN_ID_PRESERVED_PARAMS', caption: 'Assessment framework has no configured SCAN_ID in preserved params'},
          ]
    },
    {
        auditType: 'DATA',
        slug: 'SKILL',
        caption: 'Skill',
        itemScope: ["ITEMS_SCORED", 'ITEM_BANK_SCORED'],
        isCompleteCheck: true,
        commonCheckProps : {
            checkType:'PROP_VAL',
            checkConfig: {
                filterMethod: 'IS_NULL'
            }
        },
        checks: [
            {
                id: 'MISSING_SK_PARAMETER', caption: 'Items that do not contain a SK parameter', checkConfig:{
                    targetProp: 'SK'
                }
            },
            {
                id: 'MISSING_SK_2023_PARAMETER', caption: 'Items that do not contain a 2023_SK parameter', checkConfig:{
                    targetProp: '2023_SK'
                }
            },
            {
                id: 'MISSING_SKDetail_PARAMETER', caption: 'Items that do not contain a SKDetail parameter', checkConfig:{
                    targetProp: 'SKDetail'
                }
            },
            {
                id: 'MISSING_SC_PARAMETER', caption: 'Items that do not contain a SC parameter', checkConfig:{
                    targetProp: 'SC'
                }
            }
        ]
    },
    {
        auditType: 'DATA',
        slug: 'PT',
        caption: 'PT',
        itemScope: ["ITEMS_SCORED", 'ITEM_BANK_SCORED'],
        isCompleteCheck: true,
        checks: [
            {
                id: 'MISSING_PT_PARAMETER', caption: 'Items that do not contain a PT parameter', checkType: 'PROP_VAL', checkConfig:{
                    targetProp: 'PT',
                    filterMethod: 'IS_NULL'
                },
                informativeOnly: true
            }
        ]
    },
    {
        auditType: 'DATA',
        slug: 'PASSAGE',
        caption: 'Passage',
        itemScope: ['SCREENS_PASSAGES', 'ITEM_BANK_PASSAGES'],
        isCompleteCheck: true,
        commonCheckProps : {
            checkType:'PROP_VAL',
            checkConfig: {
                filterMethod: 'IS_NULL'
            }
        },
        checks: [
            {id: 'MISSING_PARAM_FLAG', caption: 'Passage param is not checked', checkType:'PROP_VAL', checkConfig:{
                targetProp: 'PSG',
            }},
            {
                id: 'MISSING_PASSAGEID_PARAMETER', caption: 'Items that do not contain a passageID parameter', checkConfig:{
                    targetProp: 'passageID',
                }
            },
            {
                id: 'MISSING_UNIQUE_PASSAGE_ID', caption: 'Items that do not contain a Unique_PassageID parameter', checkConfig:{
                    targetProp: 'Unique_PassageID',
                },
                informativeOnly: true
            },
            {
                id: 'MISSING_PTYP_PARAMETER', caption: 'Items that do not contain a PTYP parameter', checkConfig:{
                    targetProp: 'PTYP',
                }
            },
            {
                id: 'MISSING_P_TITLE_PARAMETER', caption: 'Items that do not contain a P_Title parameter', checkConfig:{
                    targetProp: 'P_Title',
                }
            },

        ]
    },
    {
        auditType: 'DATA',
        slug: 'STRAND',
        caption: 'Strand',
        itemScope: ['ITEMS_SCORED', 'ITEM_BANK_SCORED'],
        isCompleteCheck: true,
        commonCheckProps : {
            checkType:'PROP_VAL',
            checkConfig: {
                filterMethod: 'IS_NULL'
            }
        },
        checks: [
            {
                id: 'MISSING_STRAND_PARAMETER', caption: 'Items that do not contain a Strand parameter', checkConfig:{
                    targetProp: 'Strand',
                }
            },
            {
                id: 'MISSING_EQAO_COMPONENT_PARAM', caption: 'Items that do not contain a EQAO_Component parameter', checkConfig:{
                    targetProp: 'EQAO_Component',
                }
            },
        ]
    },
    {
        auditType: 'DATA',
        slug: 'REPORTING_SUBJECT',
        caption: 'Reporting Subject',
        itemScope: ['SCREENS', 'ITEM_BANK'],
        isCompleteCheck: true,
        checks: [
            {
                id: 'MISSING_REPORTING_SUBJECT', caption: 'Items that do not contain a Reporting_Subject parameter', checkType: 'PROP_VAL',
                checkConfig: {
                    targetProp: 'Reporting_Subject',
                    filterMethod: 'IS_NULL'
                },
                excludedItems: ["QUESTIONNAIRE"]
            }
        ]
    },
    {
        auditType: 'DATA',
        slug: 'ITEM_MATRIX_AUDIT',
        itemScope: ['ITEMS_SCORED_MINUS_HS'],
        caption: 'TEI score Matrix audit',
        checks: [
            {id: 'UNCONFIRMED', caption: 'Items that contain unconfirmed matrixes'},
            {id: 'OUTDATED', caption: 'Items that contain outdated matrixes'},
            {id: 'REUSABLE_DRAGGABLES', caption: 'Items that contain reusable options', informativeOnly: true},
            {id: 'MULTI_COMBINATION', caption: 'Items that accept multiple combinations', informativeOnly: true},
            {id: 'MULTI_TARGET', caption: 'Items that accept multiple correct targets', informativeOnly: true},
            {id: 'CUSTOM_VALIDATION', caption: 'Items that use custom validation', informativeOnly: true},
            {id: 'NON_SCORING_MATRIX', caption: 'Items that have an input element that does not utilize the scoring matrix'},
            {id: 'FLAGGED_ITEMS', caption: 'Items that have a flag in the Expected Answers table'},
            {id:'Q_EXP_ANS_MULTI_ACC', caption: `contain(s) multiple specified correct responses `, excludedItems: ['QUESTIONNAIRE', 'READING_SELECTIONS']},
        ]
    },
    {
        auditType: 'DATA',
        slug: 'ITEM_TYPE_AUDIT',
        itemScope: ['ITEMS_SCORED', 'ITEM_BANK_SCORED'],
        caption: 'item type audit',
        checks: [
            {id: 'ITEM_TYPE_MISSING', caption: 'Items that dont have item type set'},
            {id: 'CLK_NO_MCQS', caption: 'CLK items that do not contain a single MCQ block'},
            {id: 'DP_NO_MCQS', caption: 'DP items that do not contain a single dropdown MCQ block'},
            {id: 'DDP_NO_TWO_MCQS', caption: 'DDP items that do not contain at least 2 dropdown MCQ blocks'},
            {id: 'DG_NO_VALID_BLOCKS', caption: 'DG items that do not contain a DnD, Ordering, Grouping, or Insertion block'},
            {id: 'CH_NO_SELECTION_TABLE', caption: 'CH items that do not contain a Selection table block'},
            {id: 'SW_NO_SHORT_ANSWER', caption: 'SW items that do not contain a short answer keyboard input'},
            {id: 'LW_NO_SHORT_ANSWER', caption: 'LW items that do not contain a short answer keyboard input'},
            {id: 'OR_NO_SHORT_ANSWER', caption: 'OR items that do not contain a short answer keyboard input'},
        ]
    },
    {
        auditType: 'CONTENT',
        slug: 'ITEM_MAP_MODULE_META_AUDIT',
        itemScope: ['SCREENS_PASSAGES'],
        caption: 'Map Module Meta Audit',
        checks: [
            // are being generated programatically
            {id: 'MISSING_RESERVED_META', caption: 'Items missing preserved param'}
        ]
    },
    {
        auditType: 'DATA',
        slug: 'ITEM_CATEGORY_AUDIT',
        itemScope: ['SCREENS', 'ITEM_BANK'],
        caption: 'item catgeory audit (MPT)',
        commonCheckProps: {
            checkType: 'PROPS_CONFIG'
        },
        checks: [
            { id: 'ITEM_CATEGORY_MISSING', caption: 'Items that don\'t have item category set', checkConfig: [{
                targetProp: 'Item_Category',
                filterMethod: 'IS_NULL'
                }],
                excludedItems: ['READING_SELECTIONS']
        
            },
            { id: 'ITEM_CATEGORY_MATH_MISSMATCH', caption: 'Items with Math category and an incorrect D1 param', checkConfig: [
                {
                    targetProp: 'Item_Category',
                    filterMethod: 'EQUAL',
                    filterValue: 'Math',
                },
                {
                    targetProp: 'D1',
                    filterMethod: 'DOES_NOT_INCLUDE',
                    filterValue: ['NS', 'M', 'PR']
                }
                ],
                excludedItems: ['QUESTIONNAIRE', 'READING_SELECTIONS']
        
            },
            { id: 'ITEM_CATEGORY_PEDAGOGY_MISSMATCH', caption: 'Items with Pedagogy category and an incorrect D1 param', checkConfig: [
                {
                    targetProp: 'Item_Category',
                    filterMethod: 'EQUAL',
                    filterValue: 'Pedagogy'
                },
                {
                targetProp: 'D1',
                filterMethod: 'DOES_NOT_INCLUDE',
                filterValue: ['P1', 'P2', 'P3']
                }
                ],
                excludedItems: ['QUESTIONNAIRE', 'READING_SELECTIONS']
            },
            { id: 'ITEM_CATEGORY_QUESTIONNAIRE_MISSMATCH', caption: 'Items with Questionnaire category and an incorrect D1 param or isn\'t marked as a questionnaire', excludedItems: ['READING_SELECTIONS']}
        ]
    },
    {
        auditType: 'CONTENT',
        slug: 'SAMPLE_TEST_AUDIT',
        itemScope: ['SCREENS'],
        caption: 'Sample Test Audit (PT)',
        commonCheckProps: {
            checkType: 'PROP_VAL',
        },
        isCompleteCheck: true,
        checks: [
            { id: 'SAMPLE_ITEMS_WITHOUT_PT', caption: 'Items with PT param set to false', checkConfig: {
                targetProp: 'PT',
                filterMethod: 'IS_FALSEY'
                },
                informativeOnly: 'NOT_SAMPLE_TEST'
            },
            { id: 'REAL_ITEMS_WITH_PT', caption: 'Items with PT param set to true', checkConfig: {
                targetProp: 'PT',
                filterMethod: 'IS_TRUTHY'
                },
                informativeOnly: 'SAMPLE_TEST'
            },
        ]
    },
    {
        auditType: 'DATA',
        slug: 'RESOURCE_AUDIT',
        itemScope: ['SCREENS'],
        caption: 'Resource Audit',
        checks: [
            { id: 'RESOURCE_ITEMS_WITH_RESPONDABLE', caption: 'Items with respondables and have reading selection set to true'},
            { id: 'ITEMS_WITHOUT_RESPONDABLE_AND_NOT_READING_SELECTION', caption: 'Items without respondables that don\'t have reading selection set to true'},
            { id: 'ITEMS_WITH_IS_RESPONDABLE_AND_READING_SELECTION', caption: 'Items with is_respondable and reading selection set to true'}
        ]
    },
    {
        auditType: 'DATA',
        slug: 'CHANGE_SCALE_AUDIT',
        itemScope: ['ITEMS_SCORED'],
        caption: 'Change Scale Audit',
        isCompleteCheck: true,
        checks: [
            { id: 'IS_RESPONDABLE_MISSING_CHANGE_SCALES', caption: 'Items with is respondable param, that don\'t have a change scale ', checkType: 'PROPS_CONFIG',
                checkConfig:[
                    {
                        targetProp: 'is_respondable',
                        filterMethod: 'IS_TRUTHY'
                    },
                    {
                        targetProp: 'change_scale',
                        filterMethod: 'IS_NULL'
                    }
                ]
            },
        ]
    },
    {
        auditType: 'DATA',
        auditTarget: AuditTarget.MSCAT_PANELS,
        slug: 'MSCAT_PANEL_MODULE_AUDIT',
        itemScope: ['SCREENS'],
        caption: 'MSCAT Panel Audit',
        isCompleteCheck: true,
        checks: [
            { id: 'MISALIGNED_ITEM_COUNT_2_3', caption: 'Misaligned item count between modules 2 and 3' },
            { id: 'MISALIGNED_ITEM_COUNT_5_6', caption: 'Misaligned item count between modules 5 and 6' },
            { id: 'MISALIGNED_SP_SUM_2_3', caption: 'Misaligned SP sum between modules 2 and 3' },
            { id: 'MISALIGNED_SP_SUM_5_6', caption: 'Misaligned SP sum between modules 5 and 6' }
        ]
        
    },
]

