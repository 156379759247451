import * as _ from 'lodash';

import { IContentElementDndDraggable } from "src/app/ui-testrunner/element-render-dnd/model";
import { ElementType, IContentElement, IQuestionConfig, IScoredResponse, getElementWeight } from "../../../ui-testrunner/models";
import { ItemBankCtrl } from "../../item-set-editor/controllers/item-bank";
import { identifyQuestionResponseEntries } from "../../item-set-editor/models/expected-answer";
import { BlockPropValTransformTypes, FilterMethods, ITransformConfig } from '../data/audits';
import { IContentElementTemplate } from 'src/app/ui-testrunner/element-render-template/model';
import { IContentElementMcq } from 'src/app/ui-testrunner/element-render-mcq/model';
import { deepFind } from '../../services/util';
interface IContext {
  itemBankCtrl: ItemBankCtrl, // todo: highly stateful
}

export const getQuestionContent = (question: IQuestionConfig, lang: string = 'en'): IContentElement[] | IContentElementTemplate[] | IContentElementMcq[] => {
  let content: IContentElement[] = []
  if (lang === 'en'){
    content = question.content;
  } 
  else {
    content = question.langLink ? question.langLink.content : []; 
  }  
  return content;
}

export const getQuestionDeepElements = (question: IQuestionConfig, lang?: string): IContentElement[]  => {
  const content = getQuestionContent(question, lang);
  return [...collectElements(content)];
}

const collectElements = (content: IContentElement[]): IContentElement[] => {
  const elements: IContentElement[] = [];

  const collect = (contentArray: IContentElement[]) => {
    contentArray.forEach(element => {
      elements.push(element);
      if (element.content && element.content.length > 0) {
        collect(element.content);
      }
      if (element.advancedList && element.advancedList.length > 0){
        collect(element.advancedList)
      }
    });
  };

  collect(content);

  return elements
}

/**
 * Counts occurrences of unique values associated with a specific key in an object,
 * including nested objects, with an optional limit on the depth of recursion.
 * 
 * @param obj - The object you want to look through.
 * @param key - The key for which you want to count the occurrences of its values.
 * @param maxDepth - The maximum depth to search through (default is 4).
 * 
 * @returns A record where the keys are unique values found for the specified key,
 *          and the values are the counts of how many times each unique value occurs.
 */
export const countKeyValues = (obj: any, key: string, maxDepth: number = 4): Record<string, number> => {
  const result: Record<string, number> = {};

  const countValues = (currentObj: any, currentDepth: number) => {
      if (currentDepth > maxDepth) return;

      if (Array.isArray(currentObj)) {
          for (const item of currentObj) {
              countValues(item, currentDepth);
          }
      } else if (typeof currentObj === 'object' && currentObj !== null) {
          if (key in currentObj) {
              const value = currentObj[key];
              result[value] = (result[value] || 0) + 1;
          }
          for (const k in currentObj) {
              if (currentObj[k] !== undefined && currentObj[k] !== null) {
                  countValues(currentObj[k], currentDepth + 1);
              }
          }
      }
  };

  countValues(obj, 1);
  return result;
};



export const getQuestionPoints = (elements: IContentElement[]) => {
  const entryElements = <Partial<IScoredResponse>[]> identifyQuestionResponseEntries(elements, [], true);
  let pointsTotal = 0;
  entryElements.forEach(entryElement => {
    pointsTotal += +getElementWeight(entryElement)
  });
  return pointsTotal;
}

export const applyPropTransform = (propVal:any, transformType:BlockPropValTransformTypes, transformConfig:ITransformConfig) => {
  switch(transformType){
    case 'URL_TO_TYPES': return applyPropTransformUrlToType(propVal);
    case 'ARR_FILTER': return applyPropTransformArrFilter(propVal, transformConfig);


  }
}

const applyPropTransformArrFilter = (arr:any[], config:{conditions:{propPath:string, val:any, method:FilterMethods}[]}) => {
  return arr?.filter(el => {
    let isAnyConditionFailed = false;
    for (let condition of config.conditions){
      const {propPath, val, method} = condition;
      const propVal = _.get(el, propPath);
      const isCondPassed = checkPropCondVal(propVal, method, val);
      if (!isCondPassed){
        isAnyConditionFailed = true;
      }
    }
    return !isAnyConditionFailed;
  })
}

export const isNullOrEmptyOrUndefined = (value) => {
  return value === null || value === undefined || value === '';
}

/**
 * 
 * @param value 
 * @returns If true if value is negative or it's not a number
 */
export const isNegative = (value) => {
  const numberValue = parseFloat(value);
  return Number.isNaN(numberValue) || numberValue < 0;
}
export const applyPropTransformUrlToType = (urls:string[] | string) => {
  const types = new Set();
  urls = Array.isArray(urls) ? urls : [urls];
  urls?.forEach((url:string) => {
    types.add(url?.split('.').pop());
  })
  return types;
}

export const checkPropCondVal = (propVal:string, method:FilterMethods, val?:any) => {
  switch(method){
    case 'IS_TRUTHY': return (!!propVal);
    case 'IS_FALSEY': return (!propVal);
    case 'IS_ARR_EMPTY': return (!propVal || !propVal.length);
    case 'NOT_EQUAL': return (propVal != val);
    case 'EQUAL': return (propVal == val);
    case 'GREATER_THAN': return (propVal > val);
    case 'IS_NULL': return isNullOrEmptyOrUndefined(propVal);
    case 'IS_NAN': return Number.isNaN(+propVal) || isNullOrEmptyOrUndefined(propVal);
    case 'IS_NEGATIVE': return isNegative(propVal)
    case 'DOES_NOT_INCLUDE': return !val.includes(propVal)
  }
  return false;
}

/**
 * Function used to retrieve the respondable elements from a question
 * @param question 
 * @returns an array of question elements that are respondable
 */
export const getQuestionRespondableDeep = (question:IQuestionConfig) => {
  const elements = [];
  const item = getQuestionContent(question);
  const entryOrder = question?.entryOrder;
  for (let i = 0; i < entryOrder.length; i++) {
    const entryId = entryOrder[i];
    const targetElem = deepFind(item, 'entryId', entryId);
    elements.push(targetElem)
  }
  return JSON.parse(JSON.stringify(elements));
}