import { IAssessmentFrameworkDimensionDetail } from "../item-set-editor/models/assessment-framework";
import { IAssessmentParameter, IAssessmentParameterInfo, LangTypes } from "../view-im-parameters/view-im-parameters.component";

export const indexOf = (arr:any[], t:any) => {
    let i = -1;
    arr.forEach((_t, _i) => {
      if (_t === t){
        i = _i;
      }
    });
    return i;
  }
  

export const mapToJson = (map) =>  {
    return JSON.stringify([...map]);
}

/**
 * Inside the input object, find a sub-object with some key-value pair
 * @param obj A neted object
 * @param targetKey The key some sub-object should have, e.g. `entryId`
 * @param targetVal The value of the jey some sub-object should have, e.g. `3`
 * @returns The sub-object that fits the criteria, i.e. has entryId = 3
 */
export const deepFind = (obj, targetKey, targetVal) => {
  if (!obj || typeof obj !== 'object') return null;
  if (obj[targetKey] === targetVal) return obj;
  return Object.values(obj).reduce((acc, val) => acc || deepFind(val, targetKey, targetVal), null);
}

/**
 * Recursively collects values of matching keys within an object up to a specified depth.
 * @param obj The object to search through.
 * @param keyToFind The key name to look for in the object.
 * @param depth The maximum depth to search within the object (default is Infinity).
 * @param unique Whether to return only unique values (default is false).
 * @param currentDepth The current depth of recursion (internal use, default is 0).
 * @returns A one-dimensional array of values where the key matches the specified key name.
 */
export const deepCollect = (
  obj: Record<string, any>,
  keyToFind: string,
  unique: boolean = false,
  depth: number = Infinity,
  currentDepth: number = 0
): any[] => {
  let results: any[] = [];

  if (currentDepth > depth) return results;

  for (const key in obj) {
    if (key === keyToFind) {
      results.push(obj[key]);
    }

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      results.push(
        ...deepCollect(obj[key], keyToFind, unique, depth, currentDepth + 1)
      );
    }
  }

  if (unique) {
    results = uniqueValues(results);
  }

  return results;
}

/**
 * Removes duplicates from an array of objects by performing a deep comparison.
 * @param array The array to process.
 * @returns A new array with unique values based on deep comparison.
 */
const uniqueValues = (array: any[]): any[] => {
  const seenValues: Set<string> = new Set();
  return array.filter(item => {
    const itemString = JSON.stringify(item);
    return seenValues.has(itemString) ? false : (seenValues.add(itemString), true);
  });
}


/**
 * 
 * @param o1 1st object to compare
 * @param o2 2nd object to compare
 * @returns whether the two objects are the same, regardless of property order.
 */
export const objectsEqual = (o1, o2) => {
  if (o1 === o2) return true; // Handles primitive values and reference equality

  if (typeof o1 !== 'object' || o1 === null || typeof o2 !== 'object' || o2 === null) {
    return false; // Different types or one is null
  }

  const keys1 = Object.keys(o1);
  const keys2 = Object.keys(o2);

  if (keys1.length !== keys2.length) return false;

  return keys1.every(key => objectsEqual(o1[key], o2[key]));
};
      
/**
 * 
 * @param parameters an array of assessment parameters in JSON string format
 * @param lang the language to pull from the assessment parameter
 * @returns a parsed array of combined parameters
 */
export const combineParameters = (parameters: IAssessmentParameter[], lang: string) => {
  
  // Initialize an empty array to hold the combined parameters
  let combinedObjects: IAssessmentFrameworkDimensionDetail[] = [];

  // Iterate over the array of JSON strings
  parameters.forEach(param => {
      // Parse the JSON string into an object
      const parsedObj: IAssessmentParameterInfo = JSON.parse(param.parameters);

      // Extract the array and concatenate it with combinedObjects
      if (param.lang_type == LangTypes.REGULAR && parsedObj[lang] && Array.isArray(parsedObj[lang])) {
          combinedObjects = combinedObjects.concat(parsedObj[lang]);
      }
      // If assessment parameter is bilingual, default to english
      if (param.lang_type == LangTypes.BILINGUAL && parsedObj['en'] && Array.isArray(parsedObj['en'])) {
        combinedObjects = combinedObjects.concat(parsedObj['en']);
    }
  });

  return combinedObjects;
}

/**
 * 
 * @param assessmentParameters the array of assessment parameters to search.
 * @param assessment_slug the assessment slug to search.
 * @returns all assessment parameters associated with the specified slug.
 */
export const findRelatedObjects = (assessmentParameters: IAssessmentParameter[], assessment_slug: string): IAssessmentParameter[] => {
  // Create a map for quick lookup by ID
  const idMap = {};
  assessmentParameters.forEach(obj => {
      idMap[obj.id] = obj;
  });

  // To store the result and visited IDs
  const result: IAssessmentParameter[] = [];
  const visited = new Set();

  // Start with the object that has the given assessment_slug
  let current = assessmentParameters.find(obj => obj.assessment_slug === assessment_slug);
  while (current && !visited.has(current.id)) {
      // Add current object to the result and mark it as visited
      result.push(current);
      visited.add(current.id);

      // If extend_id is null or has already been visited, break the loop
      if (!current.extend_id || visited.has(current.extend_id)) {
          break;
      }

      // Move to the object referenced by extend_id
      current = idMap[current.extend_id];
  }

  return result;
}

/**
 * 
 * Removes parameter duplicates between primary, secondary, and standard parameters. Prioritizes standard parameters.
 */
export const removeParameterDuplicates = (
  primaryDimensions: IAssessmentFrameworkDimensionDetail[],
  secondaryDimensions: IAssessmentFrameworkDimensionDetail[],
  standardParameters: IAssessmentFrameworkDimensionDetail[]
) => {
  if (!standardParameters || standardParameters.length === 0) {
    return;
  }

  const standardCodesSet = new Set(standardParameters.map(param => param.code));

  for(let i=primaryDimensions.length-1; i>=0; i--) {
    if (standardCodesSet.has(primaryDimensions[i].code)) {
      primaryDimensions.splice(i, 1);
    }
  }

  for(let i=secondaryDimensions.length-1; i>=0; i--) {
    if (standardCodesSet.has(secondaryDimensions[i].code)) {
      secondaryDimensions.splice(i, 1);
    }
  }
}