import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LangService } from "../../core/lang.service";

@Component({
  selector: 'sa-widget-date-entry',
  templateUrl: './sa-widget-date-entry.component.html',
  styleUrls: ['./sa-widget-date-entry.component.scss']
})
export class SaWidgetDateEntryComponent implements OnInit, OnChanges {

  @Input() fc:FormControl; 
  @Input() disabled:boolean; 
  @Input() nullDbPlaceholder:string; 

  constructor(
    public lang: LangService,
  ) { }

  year = new FormControl();
  month = new FormControl();
  day = new FormControl();
  internalFcs = [this.year, this.month, this.day];
  isValid:boolean;

  subscriptions:Subscription[] = [];

  ngOnInit(): void {
    this.readFc();
    this.initSubscriptions();
  }

  initSubscriptions(){
    this.subscriptions.forEach(sub => sub.unsubscribe() );
    this.subscriptions = []
    this.subscriptions = this.internalFcs.map(internalFc => {
      return internalFc.valueChanges.subscribe(val => {
        const valNormalized = (''+val).replace(/\D/g,''); // only allow numeric characters
        if (valNormalized !== val){
          internalFc.setValue(valNormalized);
        }
        this.writeFc()
      });
    });
    // this.subscriptions.push(this.fc.valueChanges.subscribe(this.readFc));
  }

  lastDate:string;
  readFc = () => {
    const date = this.fc.value;
    // console.log('readFc', date, this.nullDbPlaceholder, date === this.nullDbPlaceholder)
    if (date === this.nullDbPlaceholder){
      this.isValid = false;
      this.year.setValue('');
      this.month.setValue('');
      this.day.setValue('');
    }
    else if (date && this.lastDate !== date){
      this.isValid = true;
      this.lastDate = date;
      this.year.setValue(date.slice(0,4));
      this.month.setValue(date.slice(4,6));
      this.day.setValue(date.slice(6,8));
    }
  }

  writeFc = () => {
    let date = this.internalFcs.map(ifc => ifc.value).join('').trim();
    /*
    if (date.length < 8){
      this.fc.setValue(this.nullDbPlaceholder);
      this.isValid = false;
    }
    else{
      this.fc.setValue(date);
      this.isValid = true;
    }
    */
    this.fc.setValue(date);
    this.isValid = true;
    this.fc.markAsDirty();
  }

  ngOnChanges(changes:SimpleChanges){
    this.readFc();
    this.initSubscriptions();
  }

}
