<div class="page-body">
  <div>
    <header
    [breadcrumbPath]="breadcrumb"
    ></header>
    <div class="page-content is-fullpage">

      <div  class="dashboard-cards-container" [class.is-full-width]="isFullWidth">

        <div *ngIf="!this.isLoaded && !this.isLoadFailed">
          <!-- Loading -->
        </div>
        <div *ngIf="this.isLoadFailed">
          You do not have the required roles to access this page.
        </div>



        <div >
          <!-- *ngSwitchCase="TCView.EQAO_G9" -->
          <div >

            <div class="notification is-warning" *ngIf="IS_COUNTS_WARNING">
              This page is under maintenance and should not be used for reporting purposes.
            </div>
            <div class="notification is-danger" *ngIf="IS_COUNTS_DISABLED || IS_COUNTS_DISABLED">
              Some functionality on this page has been disabled while it undergoes maintenance.
            </div>

              <panel-test-window-table 
                class="card dashboard-card"
                *ngIf="!IS_COUNTS_DISABLED" 
                [isTwSelectDisabled]="IS_TW_SELECT_DISABLED"
                (onTwSelect)="selectTestWindowFocus($event)"
                (onVisibleTestWindowsChange)="onVisibleTestWindowsChange($event)"
              >
                <div style="margin-top: 2em;">
          
                  <div style="margin-bottom: 2em;">
                    <button
                      class="button is-info is-small"
                      (click)="createTestWindow()"
                    >
                      Create New Administration Window
                    </button>
                  </div>
          
                  <button class="button is-info" style="margin-bottom: 1em;" (click)="loadAllWinSummary()" [disabled]="isLoadingAllWinSummary">
                    Load Administration Summary for Visible Windows
                  </button>
                  <div *ngIf="isLoadingAllWinSummary" class="notification is-warning">
                    Loading all window summary...
                  </div>
                  <div *ngIf="allWinSummaryRecords">
                    <ag-grid-angular
                      class="ag-theme-alpine ag-grid-fullpage"
                      style="max-height: 30em;"
                      [rowData]="allWinSummaryRecords"
                      [gridOptions]="allWinGridOptions"
                      [enableCellTextSelection]="true"
                    ></ag-grid-angular>
                    <div style="margin-top:1em;">
                      <button class="button" (click)="downloadAllWinSummary()">
                        Export CSV
                      </button>
                    </div>
                  </div>
                </div>
              </panel-test-window-table>

            <ng-container *ngIf="isWindowSelected()">

              <div class="card dashboard-card" *ngIf="isShowSummaries() && displaySummaryTableContents('full')">
                <div class="image-holder">
                  <img src="https://eqao.vretta.com/authoring/user_uploads/2329038/authoring/account-icon/1666377712300/account-icon.png">
                </div>
                <div class="table-holder">


                  <div class="lead">
                    <div>Summaries</div>
                    <div class="buttons">
                      <!-- <button class="button is-small">Export to Excel</button> -->
                    </div>
                  </div>
                  <div>
                    <!-- Click on the link to the boards and schools to further filter the d -->
                  </div>
                  <ng-container *ngIf="displaySummaryTableContents('summary')">
                    <table>
                      <tr>
                        <th>Account Type</th>
                        <ng-container *ngIf="!IS_COUNTS_DISABLED">
                          <th *ngFor="let col of userGroupSummaryColumns">
                            <tra [slug]="col.caption"></tra>
                          </th>
                        </ng-container>
                      </tr>
                      <tr *ngFor="let userGroupType of userGroupTypes">
                        <td [ngSwitch]="userGroupType.caption" class="is-data-label">
                          <a *ngSwitchDefault [href]="getRawLink(userGroupType)" target="_blank">
                            <tra [slug]="userGroupType.caption"></tra>
                          </a>
                          <a *ngSwitchCase="'Schools'" routerLink="/en/test-ctrl/school">
                            <tra [slug]="userGroupType.caption"></tra>
                          </a>
                          <a *ngSwitchCase="'Boards'" routerLink="/en/test-ctrl/school-boards">
                            <tra [slug]="userGroupType.caption"></tra>
                          </a>
                          <a *ngSwitchCase="'Classes'" routerLink="/en/test-ctrl/school-classes">
                            <tra [slug]="userGroupType.caption"></tra>
                          </a>
                          <a *ngSwitchCase="'Teachers'" routerLink="/en/test-ctrl/school-teachers">
                            <tra [slug]="userGroupType.caption"></tra>
                          </a>
                          <a *ngSwitchCase="'Students'" routerLink="/en/test-ctrl/school-students">
                            <tra [slug]="userGroupType.caption"></tra>
                          </a>
                        </td>
                        <ng-container *ngIf="!IS_COUNTS_DISABLED">
                          <td *ngFor="let col of userGroupSummaryColumns" class="has-data">
                            <!-- {{getColValue(userGroupType.id, col.id)}} -->
                            <a [routerLink]="getColLink(userGroupType.id)" [queryParams]="{ order: col.id }">
                              {{getColValue(userGroupType.id, col.id)}}
                            </a>
                          </td>
                        </ng-container>
                      </tr>
                    </table>

                    <!-- <div  style="margin-top:2em;">
                      <strong>Technical Readiness Tracker</strong>
                    </div>
                    <table >
                      <tr>
                        <th>Tally</th>
                        <th>SchName</th>
                        <th>SchMident</th>
                        <th>PrincipalFirstName</th>
                        <th>PrincipalEmail</th>
                      </tr>
                      <tr *ngFor="let school of g9Summary.tech_readi" [class.is-strong]="school.tally==10">
                        <td>{{school.tally}}/10</td>
                        <td>{{school.SchName}}</td>
                        <td>{{school.SchMident}}</td>
                        <td>{{school.PrincipalFirstName}}</td>
                        <td>{{school.PrincipalEmail}}</td>
                      </tr>
                    </table> -->
                    <div class="summary-timestamp">{{renderTimestampAsUIString()}}</div>
                  </ng-container>

                  <div *ngIf="displaySummaryTableContents('twFiles')">
                    <hr>
                    <h4>Administration Window Extracts</h4>
                    <table>
                      <tr *ngFor="let file of twFiles">
                        <td>{{file.slug}}</td>
                        <td>
                          <a [href]="file.url" target="_blank">
                            {{file.file_name}}
                          </a>
                        </td>
                        <td>{{file.created_on}}</td>
                      </tr>
                    </table>
                  </div>
                  
                </div>
              </div>

              <div *ngIf="isShowDataDownloads()" class="card dashboard-card" >
                <div class="image-holder">
                  <img src="https://eqao.vretta.com/authoring/user_uploads/21/authoring/excel/1606094586078/excel.png">
                </div>
                <div class="table-holder">
                  <div class="lead">Data Downloads</div>
                  <div>
                    The links below can be used to download the relevant data. Data mapping is consistent with the codebook. The only data frames that are currently avaialble are the ones marked with the spreadsheet icon (<i style="color: #15aabf;" class="fas fa-file-excel"></i>).
                  </div>
                  <div>
                    <strong>Filter is set to the active test window.</strong>
                  </div>
                  <table>
                    <ng-container *ngFor="let section of codebookTable.sections">
                      <tr class="lead-row">
                        <td>{{section.id}}</td>
                        <td [ngSwitch]="!!section.link" class="lead-caption">
                          <span *ngSwitchCase="false">{{section.caption}}</span>
                          <a *ngSwitchCase="true" target="_blank" [href]="section.link">{{section.caption}}</a>
                        </td>
                        <td></td>
                      </tr>
                      <tr *ngFor="let dataFrame of section.dataFrames">
                        <td>{{section.id}}.{{dataFrame.id}}</td>
                        <td [ngSwitch]="!!dataFrame.isEnabled">
                          <div (click)="downloadTransferTable(dataFrame, section.id)" *ngSwitchCase="true" target="_blank" style="cursor:pointer; text-decoration:underline;">
                            <i style="color: #15aabf; margin-right:0.5em;" class="fas fa-file-excel"></i>
                            {{dataFrame.caption}}
                          </div>
                          <span *ngSwitchCase="false">
                            {{dataFrame.caption}}
                          </span>
                        </td>
                        <td>
                          <div [ngClass]="{ 'td-line-breaks': dataFrame.id == 9.4 }">
                            {{dataFrame.description}}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3"></td>
                      </tr>
                    </ng-container>
                  </table>
                </div>
              </div>

            </ng-container>


            <div class="card dashboard-card" *ngIf="myCtrlOrg.hasRole(UserRoles.TEST_CTRL_NTF_CTRL)">
              <a [routerLink]="'/en/test-ctrl/notifications'">
                <i class="fas fa-envelope"></i>
                Message Centre
              </a>
            </div>

            <div class="card dashboard-card" *ngIf="!IS_COUNTS_DISABLED">
              <div class="table-holder" >
                <div class="lead"><tra slug="lbl_tctrl_bulk_load"></tra></div>   
                <div class="space-between">
                  <div>
                    <tra-md slug="tctrl_bulk_load_desc"></tra-md>
                  </div> 
                </div>
                <br>
                <div style="display: flex; justify-content: flex-end;">
                  <div style = " margin-right:  0.5em;">
                    <mat-slide-toggle [(ngModel)]="dryRun" >
                      <tra slug="lbl_tctrl_bulk_load_admin_dry_run"></tra>? ({{ getDryRunStatus() }})   
                    </mat-slide-toggle>
                  </div>
                  <div style = " margin-right:  0.5em;">
                    <mat-slide-toggle [(ngModel)]="revokeExistingAdmin" > 
                      <tra slug="lbl_tctrl_bulk_load_admin_revoke_admin"></tra>? ({{ getRevokeExistingAdminStatus() }})   
                    </mat-slide-toggle>
                  </div> 
                  <div class="file template">
                    <label class="file-label">
                        <span class="file-cta" (click)="bulkLoadTemplate()">
                            <span class="icon"><i class="fas fa-table"></i></span>
                            <span><tra slug="sa_import_template"></tra></span>  
                        </span>
                    </label>
                  </div>
                  <div class="file">
                    <label class="file-label">
                        <input class="file-input" type="file" (change)="bulkLoad($event.target.files)">
                        <span class="file-cta">
                            <span class="icon"><i class="fas fa-table"></i></span>
                            <span><tra slug="lbl_tctrl_bulk_load_admin"></tra></span>     
                        </span>
                    </label>
                  </div>
                </div>
                <br>
                <div style="overflow-y:scroll; height:400px;">
                  <table>
                    <tr>
                      <th><tra slug="lbl_tctrl_bulk_load_admin_id"></tra></th>   
                      <th><tra slug="lbl_tctrl_bulk_load_admin_load_date"></tra></th>   
                      <th><tra slug="lbl_tctrl_bulk_load_admin_loader_uid"></tra></th> 
                      <th><tra slug="lbl_tctrl_bulk_load_admin_revoked_admin"></tra></th>   
                      <th><tra slug="lbl_tctrl_bulk_load_admin_input_account"></tra></th>  
                      <th><tra slug="lbl_tctrl_bulk_load_admin_success_account"></tra></th>   
                      <th><tra slug="lbl_tctrl_bulk_load_admin_fail_account"></tra></th>   
                      <th><tra slug="lbl_tctrl_bulk_load_admin_revoked_account"></tra></th>   
                      <th><tra slug="lbl_tctrl_bulk_load_admin_not_included_account"></tra></th>  
                      <th><tra slug="lbl_tctrl_bulk_load_admin_idle_account"></tra></th>   
                    </tr>
                    <ng-container *ngFor="let bulkLoadRecord of bulkLoadRecords">
                      <tr>
                        <td>{{bulkLoadRecord.id}}</td>
                        <td>{{bulkLoadRecord.date}}</td>
                        <td>{{bulkLoadRecord.loader_uid}}</td>
                        <td><i *ngIf="bulkLoadRecord.revoke_existing_admin === 1" class="fa fa-check"></i></td>
                        <td><a *ngIf ="bulkLoadRecord.input_accounts" href="{{bulkLoadRecord.input_accounts}}"><tra slug="lbl_tctrl_download"></tra></a></td> 
                        <td><a *ngIf ="bulkLoadRecord.sucess_accounts" href="{{bulkLoadRecord.sucess_accounts}}"><tra slug="lbl_tctrl_download"></tra></a></td>
                        <td><a *ngIf ="bulkLoadRecord.fail_accounts" href="{{bulkLoadRecord.fail_accounts}}"><tra slug="lbl_tctrl_download"></tra></a></td>
                        <td><a *ngIf ="bulkLoadRecord.revoked_accounts" href="{{bulkLoadRecord.revoked_accounts}}"><tra slug="lbl_tctrl_download"></tra></a></td>
                        <td><a *ngIf ="bulkLoadRecord.not_included_accounts" href="{{bulkLoadRecord.not_included_accounts}}"><tra slug="lbl_tctrl_download"></tra></a></td>
                        <td><a *ngIf ="bulkLoadRecord.idle_accounts" href="{{bulkLoadRecord.idle_accounts}}"><tra slug="lbl_tctrl_download"></tra></a></td>
                      </tr>
                    </ng-container>
                  </table>
                </div>  
              </div>
            </div>


            <!-- MISALLOCATED ASSESSMENT STUDENT LIST SECTION -->

            <ng-container *ngIf="isWindowSelected()">
              <div class="card dashboard-card">
                <div class="table-holder" >

                  <div class="lead">
                    <div>Misallocated Assessment Type Student List</div>
                  </div>

                  <div *ngIf="islinearMisallocRecordsLoading">
                    <br>
                    <div class="notification is-warning">
                      Loading records...
                    </div>
                  </div>

                  <div *ngIf="islinearMisallocRecordsLoadFailed">
                    <br>
                    <div class="notification is-danger">
                      Records failed to load
                    </div>
                  </div>

                  <div *ngIf="islinearMisallocRecordsLoaded && linearMisallocRecords.length == 0">
                    <br>
                    <div class="notification is-dark">
                      No records found
                    </div>
                  </div>

                  <div *ngIf="islinearMisallocRecordsLoaded && linearMisallocRecords.length > 0">
                    <ag-grid-angular
                      class="ag-theme-alpine ag-grid-fullpage"
                      style="max-height: 400px; margin-top:1em"
                      [rowData]="linearMisallocRecords"
                      [gridOptions]="linearMisallocGridOptions"
                      [enableCellTextSelection]="true"
                    ></ag-grid-angular>
                    <div style="margin-top:1em;">
                      <button class="button" (click)="downloadLinearMisalloc()">
                        Export CSV
                      </button>
                    </div>
                  </div> 

                </div>
              </div>
            </ng-container>

            <!-- END: MISALLOCATED ASSESSMENT STUDENT LIST SECTION -->



            <div class="card dashboard-card" *ngIf="!IS_COUNTS_DISABLED">
              <div class="table-holder" >
                <div class="lead">Daily Report Setting</div>
                <br>
                <div>Daily Reporting Status: ({{isActivateDailyReporting ? 'ON' : 'OFF'}})</div>
                <br>
                <button class="button" (click)="promptReportingPassword()">Edit Daily Reporting</button>
              </div>
            </div>
            
            <div class = "payment-control-views"> <!-- School Admin Agreement-->
              <div class="pre-table-strip">
                <div>
                  <b><tra slug ='lbl_pc_schl_admin_agreements'></tra></b>
                </div>
                <div>
                  <button class="button is-small has-icon" (click)="exportSchoolAdminAgreements()">
                      <span class="icon"><i class="fas fa-table"></i></span>
                      <span>Export</span>
                  </button>
                </div> 
              </div>  
              <div class="space-between align-top">
                <ag-grid-angular
                  class="ag-theme-alpine ag-grid-fullpage"
                  style="flex-grow: 1;  height: 50vh;"
                  [rowData]="agreements"
                  [gridOptions]="agreementsGridOptions">
                </ag-grid-angular>
              </div> 
            </div>
          </div>

        <div class="card dashboard-card"> <!-- Manage List  -->
          <div>
            <strong>Manage Lists: </strong>
            <a routerLink="/en/test-ctrl/test-ctrl-roles"> Test Controller Roles</a>
          </div>
        </div>

      </div>

    </div>
  </div>
  <footer [hasLinks]="true"></footer>
</div>

<div class="custom-modal" *ngIf="cModal()">
  <div [ngSwitch]="cModal().type" class="modal-contents" style="width:42em;">
    <div>
      <div *ngSwitchCase="TestControllerModal.DAILY_REPORTING_PWD">
        <div class="close-button-container">
          <i (click)="pageModal.closeModal()" class="fa fa-times fa-2x close-button" aria-hidden="true"></i>
        </div>
        <div class="daily-reporting-container">
          <label>
            Password:
            <input type="password" [(ngModel)]="inputDailyReportPwd"/>
          </label>
          <button class="button is-small" (click)="checkPwd()">Submit</button>
          <div *ngIf="isWrongPasswordPrompted" class="warning-msg">Wrong password</div>
        </div>
      </div>
      <div *ngSwitchCase="TestControllerModal.DAILY_REPORTING">
        <div class="close-button-container">
          <i (click)="pageModal.closeModal()" class="fa fa-times fa-2x close-button" aria-hidden="true"></i>
        </div>
        <div class="daily-reporting-container-setting">
          <mat-slide-toggle [(ngModel)]="isActivateDailyReporting" (change)="toggleDailyReporting()" >
            Activate Daily Reporting? ({{isActivateDailyReporting ? 'Yes' : 'No'}})
          </mat-slide-toggle>
          <br>
          <br>
          <button class="button is-small" [disabled]="!isActivateDailyReporting" (click)="runDailyReporting()">
            Instant Run
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
