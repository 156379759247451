import { testAuthPanels } from '../../core/main-nav/panels/test-auth';
import * as moment from 'moment-timezone';
import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BreadcrumbsService } from 'src/app/core/breadcrumbs.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/api/auth.service';
import { LangService } from 'src/app/core/lang.service';
import { AuthScopeSetting, AuthScopeSettingsService } from '../auth-scope-settings.service';
import { IItemAuthNote } from '../element-notes/element-notes.component';
import { ItemMakerService } from 'src/app/ui-item-maker/item-maker.service';
import { AuthRolesService } from 'src/app/ui-item-maker/auth-roles.service';
import { identity } from 'angular';
import { IMenuTabConfig } from 'src/app/ui-partial/menu-bar/menu-bar.component';
import { IActiveRecords } from 'src/app/ui-trans/ui-trans-db/ui-trans-db.component';
import { Time } from '@angular/common';
import { SidepanelService } from 'src/app/core/sidepanel.service';
import { RoutesService } from 'src/app/api/routes.service';
import { ItemBankUtilCtrl } from '../item-set-editor/controllers/util';
import { IAssessmentFrameworkDimensionDetail } from '../item-set-editor/models/assessment-framework';
import { PARAM_SPECIAL_FLAGS } from '../framework-dimension-editor/model';
import { combineParameters, findRelatedObjects, objectsEqual } from '../services/util';

enum IssueTab {
  UNRESOLVED = 'UNRESOLVED',
  RESOLVED = 'RESOLVED'
}

enum Langs {
  EN = 'en',
  FR = 'fr',
  ALL = 'all'
}

export interface IAssessmentParameter {
  id: number, type_slug: string, assessment_slug: string, parameters: string, extend_id: number, lang_type: string
}


export interface IAssessmentParameterLocal {
  id: number, type_slug: string, assessment_slug: string, parameters: IAssessmentParameterInfo, extend_id: number, lang_type: string
}

export interface IAssessmentParameterInfo {
  en: IAssessmentFrameworkDimensionDetail[],
  fr: IAssessmentFrameworkDimensionDetail[]
}

export enum PARAMETER_LOCATIONS {
  ITEM = 'ITEM',
  TEST_DESIGN = 'TEST_DESIGN'
}

interface IFilterSettings {
  currentPage: number;
  pageLength: number;
  config: {
    is_resolved,
    id,
    text,
    qs_name,
    question_label
  };
}

enum EIssueCol {
  ID = 'ID',
  ISSUE = 'ISSUE',
  ITEM_BANK = 'ITEM_BANK',
  ITEM = 'ITEM',
  CREATED = 'CREATED',
  LAST_UPDATED = 'LAST_UPDATED',
  ASSIGNED_TO = 'ASSIGNED_TO'
}

export enum LangTypes {
  BILINGUAL = 'bilingual',
  REGULAR = 'regular'
}

interface IssueHeader {
  id: EIssueCol,
  caption: string,
  prop?: string
  //allowOrFilter?: boolean #REDO
}

@Component({
  selector: 'view-im-parameters',
  templateUrl: './view-im-parameters.component.html',
  styleUrls: ['./view-im-parameters.component.scss']
})

export class ViewImParameters implements OnInit, OnDestroy {

  util = new ItemBankUtilCtrl();
  breadcrumb = [];
  commonParameters = [];
  paramSaveStateMap: Map<string, {saving: boolean, success: boolean, error: boolean, removing: boolean, changes: boolean}> = new Map();
  activeGroupId: number;

  constructor(private breadcrumbsService: BreadcrumbsService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private lang: LangService,
    public itemMaker: ItemMakerService,
    private sidePanel: SidepanelService,
    private authRoles: AuthRolesService,
    private authScopeSettings: AuthScopeSettingsService,
    private routes: RoutesService,
    ) { }

  ngOnInit(): void {    
    this.sidePanel.activate(testAuthPanels)
    this.breadcrumb = [
      this.breadcrumbsService.TESTAUTH_DASHBOARD(),
      this.breadcrumbsService._CURRENT('parameters', this.router.url),
    ];
    
    this.initSaveState();
    this.initAssessmentParameters();

    if(!this.itemMaker.myGroups) {
      this.itemMaker.loadMyAuthoringGroups(true).then(() => {
        this.initGroupFilter();
        this.itemMaker.refreshGroupsAsSuper();
      });
    }
  }

  isReadOnly() {
    return !this.itemMaker.hasGroupsAsSuper;
  }

  ngOnDestroy() {
  }

  initGroupFilter() {
    this.activeGroupId = this.itemMaker.getAuthoringGroups()[0].group_id;
  }
  initSaveState() {
    this.paramSaveStateMap.set('common', {saving: false, success: false, error: false, removing: false, changes: false});
    this.paramSaveStateMap.set('paramInfo', {saving: false, success: false, error: false, removing: false, changes: false});
  }

  toggleGroupFilter(groupId:number){
    this.activeGroupId = groupId;
  }

  isGroupToggled(groupId:number){
    return this.activeGroupId == groupId;
  }

  parameterGroupMap: Map<number, any[]> = new Map();
  paramExpansionMap: Map<{}, boolean> = new Map();
  configExpansionMap: Map<string, boolean> = new Map();
  parameterGroupSub:BehaviorSubject<any> = new BehaviorSubject<any>({});

  getParameters(group_id: number) {
    return this.parameterGroupMap.get(group_id);
  }

  expandParam(param: any, isExpand=true){
    this.paramExpansionMap.set(param, isExpand);
  }
  isParamExpanded(param: any){
    return this.paramExpansionMap.get(param);
  }

  expandConfig(code: string, isExpand=true) {
    this.configExpansionMap.set(code, isExpand);
  }

  isConfigExpanded(code: string){
    return this.configExpansionMap.get(code);
  }


  defineNewParameter(arr: IAssessmentFrameworkDimensionDetail[]) {
    this.util.defineNewParameter(arr, true);
    this.onChanges();
  }

  getStorageLocationSlug(location: PARAMETER_LOCATIONS) {
    if(location == PARAMETER_LOCATIONS.ITEM) {
      return 'Item';
    } else if(location == PARAMETER_LOCATIONS.TEST_DESIGN) {
      return 'Test Design';
    }

    return 'Item';
  }

  removeParameter(parameters: any, param: any) {
    this.util.removeArrEl(parameters, param)
    this.onChanges();
  }

  hasChanges = false;
  onChanges() {
    this.hasChanges = true;
  }

  saveCommonParamChanges() {
    try{
      if(!this.activeAssessmentParameter) {
        return;
      }
      let parameters: IAssessmentParameterInfo = this.activeAssessmentParameter.parameters
      if(this.showJSONView) {
        parameters[this.currentLang] = JSON.parse(this.activeAssessmentParameterJSON);
      }
      
      const config = JSON.stringify(parameters);
      const data = {
        qsp_id: this.activeAssessmentParameter.id,
        config
      }
  
      this.paramSaveStateMap.get('common').saving = true;
      this.paramSaveStateMap.get('common').error = false;
      this.auth.apiCreate(this.routes.TEST_AUTH_QUESTION_SET_PARAMETERS_VERSIONS, data).then(() => {
        this.paramSaveStateMap.get('common').saving = false;
        this.paramSaveStateMap.get('common').success = true;
        // Mutate parameter array to save
        this.saveParameterChangeToLocal(parameters);
        this.hasChanges = false;
      }).catch(() => {
        this.paramSaveStateMap.get('common').success = false;
        this.paramSaveStateMap.get('common').error = true;
      });
    } catch {
      this.paramSaveStateMap.get('common').error = true;
    }
  }

  /**
   * Saves the new parameter information to the local parameter array
   * @param parameters the new parameter information
   * @returns the newly saved parameter information
   */
  saveParameterChangeToLocal(parameters: IAssessmentParameterInfo) {
    const paramIndex = this.assessmentParameters.findIndex((assessment) => assessment.id == this.activeAssessmentParameter.id);
    this.assessmentParameters[paramIndex].parameters = JSON.stringify(parameters);

    return this.assessmentParameters[paramIndex].parameters;
  }

    /**
   * Saves the new parameter information to the local parameter array
   * @param parameters the new parameter information
   * @returns the newly saved parameter information
   */
    saveParameterInfoChangeToLocal(info: IAssessmentParameterLocal) {
      const paramIndex = this.assessmentParameters.findIndex((assessment) => assessment.id == this.activeAssessmentParameter.id);
      this.assessmentParameters[paramIndex].assessment_slug = info.assessment_slug;
      this.assessmentParameters[paramIndex].type_slug = info.type_slug;
      this.assessmentParameters[paramIndex].extend_id = info.extend_id;
      this.assessmentParameters[paramIndex].lang_type = info.lang_type;
  
      return this.assessmentParameters[paramIndex].parameters;
    }

  assessmentParameters: IAssessmentParameter[];
  initAssessmentParameters() {
    this.auth.apiFind(this.routes.TEST_AUTH_QUESTION_SET_PARAMETERS).then((res) => {
      this.assessmentParameters = res;
    });
  }

  currentGrade: string;
  currentLang: string = 'en';
  activeAssessmentParameterId: number;
  activeAssessmentParameter: IAssessmentParameterLocal;

  LANGUAGE_TYPES = [
    {
      id: LangTypes.REGULAR,
      slug: 'Regular'
    },
    {
    id: LangTypes.BILINGUAL,
    slug: 'Bilingual'
    }
  ];
  
  getLanguages() {
    return this.lang.getSupportedLanguages();
  }
  /**
   * 
   * @returns all unique type slugs (grades)
   */
  getGrades() {
    if(!this.assessmentParameters || !this.assessmentParameters.length) {
      return;
    }

    const typeSlugs = this.assessmentParameters.map((assessment) => assessment.type_slug).filter((type_slug => type_slug !== null));
    return typeSlugs.filter(function(item, pos) { // Remove duplicates
      return typeSlugs.indexOf(item) == pos;
    });
  }

  /**
   * 
   * @param assessmentParameterId the id of the assessment.
   * @returns the assessment information
   */
  getAssessmentParameter(assessmentParameterId: number) {
    return this.assessmentParameters.find((param) => param.id == assessmentParameterId);
  }

  /**
   * 
   * @param assessmentParameterId the id of the assessment.
   * @returns if the assessment is currently selected.
   */
  isAssessmentParameterToggled(assessmentParameterId:number){
    return this.activeAssessmentParameterId == assessmentParameterId;
  }

  /**
   * 
   * @param assessmentParameterId the id of the assessment.
   * sets the current active assessment
   */
  toggleAssessmentParameter(assessmentParameterId: number) {
    if(this.activeAssessmentParameterId == assessmentParameterId) {
      return;
    }
    this.resetSelection();

    this.activeAssessmentParameterId = assessmentParameterId;
    this.activeAssessmentParameter = {...this.getAssessmentParameter(assessmentParameterId), parameters: this.getParameterData(assessmentParameterId)};
  }

  /**
   * 
   * @returns the list of assessments according to the selected grade, assessments that do not belong to a grade are included.
   */
  getAssessmentParameterList() {
    if(!this.assessmentParameters) {
      return [];
    }
    const baseAssessments = this.assessmentParameters.filter((assessment) => !assessment.type_slug);

    if(!this.currentGrade) {
      return baseAssessments;
    }
    
    const assessments = this.assessmentParameters.filter((assessment) => assessment.type_slug == this.currentGrade);
    return [...baseAssessments, ...assessments]
  }

  /**
   * 
   * @param assessmentParameterId the id of the assessment.
   * @returns the parsed parameter data of the assignment based on the current language.
   */
  getParameterData(assessmentParameterId: number): IAssessmentParameterInfo {
    try {
      return JSON.parse(this.getAssessmentParameter(assessmentParameterId).parameters);
    } catch(err) {
      console.error(err);
    }
  }

  /**
   * Resets the current selections for grade and assessment.
   */
  resetSelection() {
    this.activeAssessmentParameter = undefined;
    this.activeAssessmentParameterId = undefined;
    this.paramExpansionMap = new Map();
    this.configExpansionMap = new Map();
    this.initSaveState();
    this.hasChanges = false;
    this.showJSONView = false;
    this.activeAssessmentParameterJSON = null;
    this.currentLang = 'en';
  }

  isNewParamSaving: boolean = false;
  newParamAsmtSlug: string;
  newParamTypeSlug: string;
  newParamExtends: number;
  newParamLangType: string;

  resetFields() {
    this.isNewParamSaving = false;
    this.newParamAsmtSlug = null;
    this.newParamTypeSlug = null;
    this.newParamExtends = null;
    this.newParamLangType = null;
  }

  showParamCreation = false;
  createNewAsmtParam(newParamAsmtSlug: string, newParamTypeSlug: string, newParamExtends: number, newParamLangType: string) {
    const config = {
      en: [],
      fr: []
    }

    const data = {
      assessment_slug: newParamAsmtSlug,
      type_slug: newParamTypeSlug,
      config: JSON.stringify(config),
      extend_id: newParamExtends,
      lang_type: newParamLangType
    }
    this.isNewParamSaving = true;
    this.auth.apiCreate(this.routes.TEST_AUTH_QUESTION_SET_PARAMETERS, data).then(() => {
      this.resetFields();
      this.initAssessmentParameters();
    })
  }

  revokeAsmtParam(asmtParamId: number) {

    this.paramSaveStateMap.get('common').removing = true;
    this.paramSaveStateMap.get('common').error = false;
    this.auth.apiRemove(this.routes.TEST_AUTH_QUESTION_SET_PARAMETERS, asmtParamId).then((res) => {
      this.paramSaveStateMap.get('common').removing = false;
      this.resetSelection();
      this.initAssessmentParameters();
    }).catch(() => {
      this.paramSaveStateMap.get('common').error = true;
    });
  }

  showJSONView = false;
  activeAssessmentParameterJSON: string;
  /**
   * Attempts to sync the parameter JSON and table views upon view toggle.
   * @param isShowJSON the new value of showJSONView
   */
  syncParamViews(isShowJSON: any) {
    if(isShowJSON.checked) { // If showJSON is toggled on, assign the JSON field to the stringified parameter list.
      const paramText = JSON.stringify(this.activeAssessmentParameter.parameters[this.currentLang]);
      this.activeAssessmentParameterJSON = paramText;
      return;
    }
    // If showJSON is toggled off, attempt to parse the JSON into the current parameter language.
    try {
      const paramObj = JSON.parse(this.activeAssessmentParameterJSON);
      // flatten array
      this.activeAssessmentParameter.parameters[this.currentLang] = this.validateAndFlatten(paramObj);
    } catch {
      // If JSON parse fails 
      alert('the current JSON configuration is invalid. Changes will not persist.');
    }
  }

  validateAndFlatten(parameters: IAssessmentFrameworkDimensionDetail[]) {
    // Check if the input is an array
    if (!Array.isArray(parameters)) {
        throw new Error("Input is not an array");
    }

    const flattenedParams: IAssessmentFrameworkDimensionDetail[] = [];

    // Flatten the array if it contains nested arrays and check keys
    parameters.forEach((param) => {
      if(Array.isArray(param)) {
        return flattenedParams.push(...param);
      }
      return flattenedParams.push(param);
    })

    // Check keys for each parameter.
    const requiredKeys = ['code', 'name'];
    for(let i=0; i<flattenedParams.length; i++) {
      const param = flattenedParams[i];
      const itemKeys = Object.keys(param);
      if (!requiredKeys.every(key => itemKeys.includes(key))) {
        throw new Error("Array contains objects with missing or extra properties");
      }
    }

    return flattenedParams;
  }

  saveParameterInfo() {
    this.paramSaveStateMap.get('paramInfo').saving = true;

    const data = {
      type_slug: this.activeAssessmentParameter.type_slug,
      assessment_slug: this.activeAssessmentParameter.assessment_slug,
      extend_id: this.activeAssessmentParameter.extend_id,
      lang_type: this.activeAssessmentParameter.lang_type
    }

    this.auth.apiPatch(this.routes.TEST_AUTH_QUESTION_SET_PARAMETERS, this.activeAssessmentParameter.id, data).then(() => {
      this.paramSaveStateMap.get('paramInfo').changes = false;
      this.saveParameterInfoChangeToLocal(this.activeAssessmentParameter);
    }).finally(() => {
      this.paramSaveStateMap.get('paramInfo').saving = false;
    });
  }

  onParameterInfoChange() {
    this.paramSaveStateMap.get('paramInfo').changes = true;
  }

  /**
   * 
   * @returns true if cached assessment parameter and the current assessment parameter are different
   */
  hasAssessmentParameterChanges() {
    if(!this.activeAssessmentParameter || !this.assessmentParameters || !this.assessmentParameters.length) {
      return false;
    }
    try{
      const parameterStored = this.getParameterData(this.activeAssessmentParameter.id);

      let newParameter: IAssessmentParameterInfo = JSON.parse(JSON.stringify(this.activeAssessmentParameter.parameters));
      
      if(this.showJSONView) {
        newParameter[this.currentLang] = JSON.parse(this.activeAssessmentParameterJSON);
      }

      const equalMap = []
      Object.keys(parameterStored).forEach((lang) => {
        parameterStored[lang].map((param, i) => {
          if(!newParameter[lang] || !newParameter[lang][i]) {
            equalMap.push(false);
          }
          equalMap.push(objectsEqual(param, newParameter[lang][i]));
        })
      })

      return parameterStored.en.length != newParameter.en.length || parameterStored.fr.length != newParameter.fr.length || equalMap.includes(false);
    } catch(err) {
      console.error(err)
      return false;
    }
  }

  /**
   * 
   * @returns true if the related assessments have duplicate parameter codes.
   */
  hasDuplicates() {
    try {
      // Splice a deep copy of assessmentParameters with the current user input
      const tempAsmtParameters: IAssessmentParameter[] = JSON.parse(JSON.stringify(this.assessmentParameters)); // Create deep copy
      const currentIndex = tempAsmtParameters.findIndex((asmt) => asmt.id == this.activeAssessmentParameter.id)
      let newParameterLocal: IAssessmentParameterInfo = JSON.parse(JSON.stringify(this.activeAssessmentParameter.parameters));
      if(this.showJSONView) {
        newParameterLocal[this.currentLang] = JSON.parse(this.activeAssessmentParameterJSON)
      }

      tempAsmtParameters[currentIndex].parameters = JSON.stringify(newParameterLocal);

      const relatedAssessments = findRelatedObjects(tempAsmtParameters, this.activeAssessmentParameter.assessment_slug);
      const combinedParameters: IAssessmentFrameworkDimensionDetail[] = combineParameters(relatedAssessments.reverse(), this.currentLang);
      const elementCount = new Map();

      for (let i = 0; i < combinedParameters.length; i++) {
          if (elementCount.has(combinedParameters[i].code.toUpperCase())) {
              return combinedParameters[i].code;
          }
          elementCount.set(combinedParameters[i].code.toUpperCase(), combinedParameters[i].name);
      }
      return null;
    } catch(err) {
      console.error(err);
    }
  }

  isObjectEmpty(obj: any) {
    return Object.keys(obj).length == 0;
  }

  isArray(obj: any) {
    return Array.isArray(obj);
  }

  onCurrentLangChange($event) {
    if(this.showJSONView) {
      this.syncParamViews({checked: false});
      this.showJSONView = false;
    }

    this.currentLang = $event;
  }

  isBilingual() {
    return this.activeAssessmentParameter.lang_type == LangTypes.BILINGUAL;
  }

  isOrdering: boolean = false;
}
